<template>
    <div class="vld-parent">
        <Loading 
            :color="'#26a69a'"
            :loader="'spinner'"
            :active.sync="isLoading"
            :is-full-page="false">
        </Loading>
        <div class="row">
            <div class="col-md-8">
                <div class="card card-body">
                    <div class="row">
                        <div class="col-md-6 border-right">
                            <div class="media">
                                <div class="mr-3 align-self-center">
                                    <i class="icon-store icon-3x"></i>
                                </div>

                                <div v-if="onLoad" class="media-body text-left">
                                    <div class="alpha-slate p-2 mb-2 col-6"></div>
                                    <div class="alpha-slate p-1 col-3"></div>
                                </div>
                                <div v-if="reseller" class="media-body text-left">
                                    <h5 class="font-weight-semibold mb-0">{{reseller.nama_seller}}</h5>
                                    <span class="text-uppercase font-size-sm text-muted">nama sellerK</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="media">
                                <div class="mr-3 align-self-center">
                                    <i class="icon-file-spreadsheet icon-3x text-info-400"></i>
                                </div>
                                <div v-if="onLoad" class="media-body text-left">
                                    <div class="alpha-slate p-2 mb-2 col-6"></div>
                                    <div class="alpha-slate p-1 col-3"></div>
                                </div>
                                <div v-if="reseller" class="media-body text-left">
                                    <h4 class="font-weight-semibold mb-0">INV-{{reseller.id}}</h4>
                                    <span class="text-uppercase font-size-sm text-muted">TANGGAL {{reseller.created_at}} </span> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-body bg-danger has-bg-image">
                    <div class="media">
                        <div class="mr-3 align-self-center">
                            <i class="icon-calculator2 icon-3x opacity-75"></i>
                        </div>
                        <div class="media-body text-right">
                            <h4 class="font-weight-semibold mb-0">{{Intl.NumberFormat('en-US').format(total)}}</h4>
                            <span class="text-uppercase font-size-sm">PEMBAYARAN</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <div class="form-group row mb-0">
                    <div class="col-md-2">
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <span class="input-group-text">AR</span>
                            </span>
                            <input ref="sku" v-model="sku" @input="getSKU" type="text" class="form-control" placeholder="Kode Barang" maxlength="4">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <input v-model="barang.nama_barang" type="text" class="form-control" disabled placeholder="Nama Barang">
                    </div>
                    <div class="col-md-2">
                        <autonumeric 
                            ref="harga"
                            v-model="new_harga"
                            class="form-control text-right" 
                            :placeholder="Intl.NumberFormat('en-US').format(barang.harga)"
                            :options="{modifyValueOnWheel: false, decimalPlaces: 0}"
                            @keydown.enter.prevent.native="onEnterHarga"
                            >
                        </autonumeric>
                    </div>
                    <div class="col-md-2">
                        <autonumeric 
                            ref="qty"
                            v-model="qty"
                            class="form-control text-right" 
                            :placeholder="'qty'"
                            :options="{modifyValueOnWheel: false, decimalPlaces: 0}"
                            @keydown.enter.prevent.native="onEnterQty"
                            >
                        </autonumeric>
                    </div>
                </div>
            </div>
            <table class="table">
                <thead class="bg-info">
                    <tr>
                        <th style="width: 20%">KODE BARANG</th>
                        <th style="width: 30%">NAMA BARANG</th>
                        <th class="text-right" style="width: 15%">HARGA</th>
                        <th class="text-right" style="width: 10%">QTY</th>
                        <th class="text-right" style="width: 15%">TOTAL</th>
                        <th class="text-center" style="width: 10%">AKSI</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in details.slice().reverse()" :key="i" :class="[i == 0 && fadeIn ? 'animated fadeIn':'']">
                        <td>{{item.kode_barang}}</td>
                        <td>{{item.nama_barang}}</td>
                        <td class="text-right">{{Intl.NumberFormat('en-US').format(item.harga)}}</td>
                        <td class="text-right">{{item.qty}}</td>
                        <td class="text-right">{{Intl.NumberFormat('en-US').format(item.total)}}</td>
                        <td class="text-center">
                            <a @click.prevent="onDelete(item.index)" href="#"><i class="icon-trash text-danger"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="card-footer">
                <div class="text-right">
                    <button @click="onBatal" class="btn btn-light">BATAL</button>
                    <button @click="onSimpan" class="btn btn-primary ml-1" :disabled="details.length == 0 || isLoading && details.length >= 1">SIMPAN</button>
                </div>
            </div>
        </div>

        <div v-if="toko" id="printMe" class="container">
            <h3 class="text-center">{{toko.name}}</h3>
            <div class="text-center">{{toko.address}}</div>
            <table class="row table">
                <tbody>
                    <tr>
                        <td>Kepada</td>
                        <td class="text-right">{{reseller.nama_seller}}</td>
                    </tr>
                    <tr>
                        <td>ID NOTA</td>
                        <td class="text-right">INV-{{reseller.id}}</td>
                    </tr>
                    <tr>
                        <td>Tanggal</td>
                        <td class="text-right">{{reseller.created_at}}</td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <table class="table">
                <tbody>
                    <tr v-for="(item, i) in details" :key="i">
                        <td>{{item.nama_barang}}</td>
                        <td class="text-right">{{item.qty}}x{{Intl.NumberFormat('en-US').format(item.harga)}}</td>
                        <td class="text-right">{{Intl.NumberFormat('en-US').format(item.total)}}</td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <hr>
            <table class="table">
                <tbody>
                    <tr>
                        <td><span class="font-size">TOTAL</span></td>
                        <td class="font-size text-right">{{Intl.NumberFormat('en-US').format(total)}}</td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <table class="table">
                <tbody>
                    <tr>
                        <td><b>Instagram</b></td>
                        <td class="text-right"><b>@zhr_leather</b></td>
                    </tr>
                    <tr>
                        <td><b>Whatsapp</b></td>
                        <td class="text-right"><b>{{toko.phone}}</b></td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <div class="text-center">Menerima Pesanan/Menjual</div>
            <div class="text-center">-Jaket Kulit -Dompet -Sabuk -sepatu -reparasi -cat kulit</div>
            <hr>
            <div class="text-center">Terima Kasih</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import autonumeric from '../../../components/AutoNumeric'
import swalInit from '../../../sweetalert'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    components: {
        autonumeric,
        Loading
    },
    data () {
        return {
            status: false,
            onLoad: false,
            isLoading: false,
            reseller: '',
            fadeIn: false,
            sku: '',
            new_harga: '',
            qty: '',
            barang: {
                barang_id: '',
                kode_barang: '',
                nama_barang: '',
                harga: '',
            },
            details: [],
        }
    },
    mounted() {
        let id = this.$route.params.id
        this.inv = id
        this.getData(id)
    },
    computed: {
        ...mapGetters({
            toko: 'auth/toko'
        }),
        total () {
            let sum = 0
            this.details.forEach(el => {
                sum += el.total
            });
            return sum
        }
    },
    methods: {
        getData (id) {
            this.onLoad = true
            axios.get('/reseller/entry/'+id)
            .then((response) => {
                this.onLoad = false
                this.reseller = response.data
                this.$refs.sku.focus()
            })
        },
        getSKU () {
            this.barang = {
                barang_id: '',
                kode_barang: '',
                nama_barang: '',
                harga: 0
            }
            this.new_harga = ''
            this.qty = ''
            this.fadeIn = false
            if(this.sku.length==4) {
                axios.get('/barang/search/AR'+this.sku)
                .then((response) => {
                    if(response.data.error) {
                        swalInit.fire({
                            title: 'AR'+this.sku,
                            text: response.data.error,
                            type: 'error',
                            confirmButtonClass: 'btn btn-primary',
                            buttonsStyling: false,
                        })
                        .then(()=> {
                            this.$refs.sku.focus()
                        }) 
                    } else {
                        this.barang.barang_id = response.data.id
                        this.barang.nama_barang = response.data.nama_barang
                        this.barang.kode_barang = response.data.kode_barang
                        this.barang.harga = response.data.harga_net
                        this.$refs.harga.$el.focus()
                    }
                })
            }
        },
        onEnterHarga () {
            if(this.new_harga >= this.barang.harga) {
                this.$refs.qty.$el.focus()
            } 
            if(this.new_harga < this.barang.harga) {
                swalInit.fire({
                    title: 'Harga Rendah',
                    text: 'Harga yang anda masukan terlalu rendah',
                    type: 'warning',
                    confirmButtonClass: 'btn btn-primary',
                    buttonsStyling: false,
                })
            }
        },
        onEnterQty () {
            let app = this
            if(this.new_harga < this.barang.harga) {
                app.$refs.qty.$el.blur()
                swalInit.fire({
                    title: 'Harga Rendah',
                    text: 'Harga yang anda masukan terlalu rendah',
                    type: 'warning',
                    confirmButtonClass: 'btn btn-primary',
                    buttonsStyling: false,
                })
                .then(() => {                    
                    app.$refs.harga.$el.focus()
                })
            }

            if(this.qty <= 0 ) {
                swalInit.fire({
                    title: 'Qty Belum diisi',
                    text: 'Qty Minimum 1',
                    type: 'error',
                    confirmButtonClass: 'btn btn-primary',
                    buttonsStyling: false,
                })
            }

            if(this.qty > 0 && this.new_harga >= this.barang.harga) {
                this.fadeIn = true
                let i = this.details.length
                this.details.push({
                    index: i+1,
                    barang_id: this.barang.barang_id,
                    kode_barang: this.barang.kode_barang, 
                    nama_barang: this.barang.nama_barang, 
                    harga: this.new_harga,
                    qty: this.qty,
                    total: parseInt(this.new_harga * this.qty)
                })
                this.$nextTick(() => {
                    this.barang = {
                        id: '',
                        kode_barang: '',
                        nama_barang: '',
                        harga: 0
                    }
                    this.sku = ''
                    this.new_harga =''
                    this.qty = ''
                    this.$refs.sku.focus()
                })
            }
        },
        onDelete (index) {
            let i = this.details.map(item => item.index).indexOf(index)
            this.details.splice(i, 1)
        },
        onSimpan () {
            let app = this
            swalInit.fire({
                title: 'Simpan & Print',
                text: "Apakah anda ingin menyimpan dan mencetaknya?",
                type: 'question',
                showCancelButton: true,
                confirmButtonText: 'Ya, Simpan & Print',
                cancelButtonText: 'tidak, Simpan saja',
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-lght',
                buttonsStyling: false
            }).then(function(result) {
                if(result.value) {
                    app.print()
                }
                else if(result.dismiss === swalInit.DismissReason.cancel) {
                    app.postRseller()
                }
            });
        },
        postRseller () {
            this.isLoading = true
            axios.post('/reseller/save',{
                id: this.reseller.id,
                details: this.details,
                total: this.total
            })
            .then(() => {
                this.status = true
                this.isLoading = false
                this.$router.push('/penjualan/reseller')
            })
        },
        print() {
            this.$htmlToPaper('printMe', null, () => {
                this.postRseller()
            })
        },
        onBatal () {
            let app = this
            let id = this.$route.params.id
            swalInit.fire({
                title: 'Membatalkan',
                text: "Anda yakin ingin membatalkanya?",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya, Batalkan!',
                cancelButtonText: 'tidak',
                showLoaderOnConfirm: true,
                preConfirm: function () {
                    return axios.post('/reseller/cancel', {
                        id: id
                    })
                    .then(() => {
                        app.status = true
                        app.$router.push('/penjualan/reseller')
                    })
                }
            })
        },
    },
    beforeRouteLeave(to, from, next) {
        if(this.status) {
            next()
        } else {
            swalInit.fire({
                title: 'DATA BELUM DI SIMPAN',
                text: 'Apakah anda ingin menyipan data ini ?',
                type:'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya Simpan!',
                cancelButtonText: 'Tidak',
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                buttonsStyling: false,
            })
            .then((result)=>{
                if(result.value) {
                    this.onSimpan()
                } else {
                   axios.post('/reseller/cancel', {
                        id: this.$route.params.id
                    })
                    .then(() => {
                        this.status = true
                        this.$router.push('/penjualan/reseller')
                    })
                }
            })
        }
    } 
}
</script>
<style scoped>
    #printMe {
        display: none;
    }
</style>