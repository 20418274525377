<template>
    <div>
        <div v-if="toko" class="row">
            <div class="col-md-8">
                <div class="card card-body">
                    <div class="row">
                        <div class="col-md-6 border-right">
                            <div class="media">
                                <div class="mr-3 align-self-center">
                                    <i class="icon-store icon-3x"></i>
                                </div>

                                <div class="media-body text-left">
                                    <h5 class="font-weight-semibold mb-0">{{toko.name}}</h5>
                                    <span class="font-size-sm text-muted">{{toko.address}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="media">
                                <div class="mr-3 align-self-center">
                                    <i class="icon-calendar2 icon-3x text-info-400"></i>
                                </div>

                                <div class="media-body text-left">
                                    <h4 class="font-weight-semibold mb-0">{{timestamp}}</h4>
                                    <span class="text-uppercase font-size-sm text-muted">TANGGAL SEKARANG</span> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-body bg-warning has-bg-image">
                    <div class="media">
                        <div class="mr-3 align-self-center">
                            <i class="icon-calculator2 icon-3x opacity-75"></i>
                        </div>

                        <div class="media-body text-right">
                            <h4 class="font-weight-semibold mb-0">{{Intl.NumberFormat('en-US').format(total)}}</h4>
                            <span class="text-uppercase font-size-sm">TOTAL PENGELUARAN</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

         <div class="card">
            <div class="card-body">
                <div class="form-group row mb-0">                    
                    <div class="col-md-8">
                        <label>Nama Item / Keterangan</label>
                        <input ref="keterangan" @keydown.enter="onEnterKeterangan" @keydown="error.keterangan=''" v-model="form.keterangan" type="text" class="form-control" :class="[error.keterangan ? 'border-danger':'']" placeholder="Nama Barang / keterangan pengeluaran">
                    </div>
                    <div class="col-md-2">
                        <label>Harga / Sebesar</label>
                        <autonumeric 
                            ref="harga"
                            v-model="form.harga"
                            class="form-control text-right" 
                            :class="[error.harga ? 'border-danger':'']"
                            :placeholder="'harga sebesar'"
                            :options="{modifyValueOnWheel: false, decimalPlaces: 0}"
                            @keydown.enter.native="onEnterHarga"
                            @keydown.native="error.harga=''"
                            >
                        </autonumeric>
                    </div>
                    <div class="col-md-2">
                        <label>Banyaknya / QTY</label>
                        <autonumeric 
                            ref="qty"
                            v-model="form.qty"
                            class="form-control text-right" 
                            :class="[error.qty ? 'border-danger':'']"
                            :placeholder="'qty'"
                            :options="{modifyValueOnWheel: false, decimalPlaces: 0}"
                            @keydown.enter.native="onEnterQty"
                            @keydown.native="error.qty=''"
                            :disabled="disabled"
                            >
                        </autonumeric>
                    </div>
                </div>
            </div>
            <table class="table">
                <thead class="bg-warning">
                    <tr>
                        <th style="width:5%">NO</th>
                        <th style="width:40%">ITEM/KETERANGAN</th>
                        <th class="text-right" style="width:15%">HARGA/JUMLAH</th>
                        <th class="text-right" style="width:15%">BANYAKNYA/QTY</th>
                        <th class="text-right" style="width:15%">TOTAL</th>
                        <th class="text-center" style="width:10%">Aksi</th>
                    </tr>
                </thead>
                <tbody v-if="onLoad">
                    <tr v-for="p in 10" :key="p">
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td>
                            <div class="d-flex justify-content-center">
                                <div class="alpha-slate p-2 col-2 ph-item"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr v-for="(item, i) in pengeluaran.slice().reverse()" :key="i" :class="[i == 0 & fadeIn ? 'animated fadeIn':'']">
                        <td>{{i+1}}</td>
                        <td>{{item.keterangan}}</td>
                        <td class="text-right">{{Intl.NumberFormat('en-US').format(item.harga)}}</td>
                        <td class="text-right">{{item.qty}}</td>
                        <td class="text-right">{{Intl.NumberFormat('en-US').format(item.total)}}</td>
                        <td class="text-center">
                            <a @click.prevent="onDelete(item.id)" href=""><i class="icon-trash text-danger"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="pengeluaran.length == 0" class="card-body">
                <div class="text-center">
                    <h3 class="text-muted mb-0">BELUM ADA DATA PENGELURAN HARI INI</h3>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import autonumeric from '../../components/AutoNumeric'
import swalInit from '../../sweetalert'
export default {
    components: {
        autonumeric
    },
    data () {
        return {
            onLoad: false,
            timestamp: '',
            fadeIn: false,
            disabled: false,
            form: {
              keterangan: '',
              harga: '',
              qty: '',  
            },
            error: {
               keterangan: '',
              harga: '',
              qty: '',   
            },
            pengeluaran: [],
        }
    },
    mounted () {
        this.getNow()
        this.getData()
        this.$refs.keterangan.focus()
    },
    computed: {
        ...mapGetters({
           toko: 'auth/toko' 
        }),
        total () {
            let sum = 0
            this.pengeluaran.forEach(el => {
                sum += el.total
            });
            return sum
        }
    },
    methods: {
        getData () {
            this.pengeluaran = []
            this.onLoad = true
            axios.get('/pengeluaran')
            .then((response) => {
                this.onLoad = false
                this.pengeluaran = response.data
            })
        },
        getNow () {
            const today = new Date();
            const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date +' '+ time;
            this.timestamp = dateTime;
        },
        onEnterKeterangan () {
           if(this.form.keterangan.length > 0) {
               this.$refs.harga.$el.focus()
           }
        },
        onEnterHarga () {
            if(this.form.harga > 0) {
                this.$refs.qty.$el.focus()
            }
        },
        onEnterQty () {
            if(!this.form.keterangan) {
                this.error.keterangan = 'Keterangan Belum diisi' 
            }
            if(!this.form.harga) {
                this.error.harga = 'Keterangan Belum diisi' 
            }
            if(!this.form.qty) {
                this.error.qty = 'Keterangan Belum diisi' 
            }
            if(this.form.keterangan && this.form.harga && this.form.qty > 0) {
                this.disabled = true
                this.fadeIn = false
                axios.post('/pengeluaran/save',{
                    toko_id: this.toko.id,
                    keterangan: this.form.keterangan,
                    harga: this.form.harga,
                    qty: this.form.qty,
                    total: parseInt(this.form.harga * this.form.qty)
                })
                .then((response) => {
                    this.fadeIn = true
                    this.form = {
                        keterangan: '',
                        harga: '',
                        qty: '',  
                    },
                    this.disabled = false
                    this.$refs.keterangan.focus()
                    this.pengeluaran.push(response.data)
                })
            }
        },
        onDelete(i) {
            let id = i
            let app = this
            swalInit.fire({
                title: 'Hapus',
                text: "Anda yakin ingin menghapusnya?",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya, Hapus!',
                cancelButtonText: 'tidak',
                showLoaderOnConfirm: true,
                preConfirm: function () {
                    return axios.post('/pengeluaran/delete', {
                        id: id
                    })
                    .then(() => {
                        app.getData()
                    })
                }
            })
        }
    }
}
</script>