<template>
    <div class="card-body pt-0">
        <h5>Shipping Origin</h5>
        <form action="">
            <div class="form-group row">
                <div class="col-md-6">
                    <label>District</label>
                    <div class="form-group-feedback form-group-feedback-left">
                        <input v-model="origin.name" type="text" class="form-control" placeholder="Type 'District'" id="ac-district">
                        <div class="form-control-feedback">
                            <i class="icon-pin-alt font-size-base"></i>
                        </div>
                        <span v-if="error_district" class="form-text text-danger">{{error_district}}</span>
                    </div>
                </div>
            </div>
        </form>
        <legend></legend>
        <div class="d-flex mt-4 mb-3">
            <div>
                <h5 class="mb-0">Shipping Services</h5>
                <span class="text-muted">Choose Shipping Service for your Store</span>
            </div>
            <div class="ml-auto">
                <button @click="onAdd" type="button" class="btn bg-teal-400 btn-labeled btn-labeled-left" data-toggle="modal" data-target="#modal"><b><i class="icon-plus-circle2"></i></b> Add Shipping Service</button>
            </div>
        </div>
        <div v-if="onLoading" class="row">
            <div v-for="i in 9" :key="i" class="col-md-6 col-lg-4 ph-item">
                <div class="card card-body">
                    <div class="d-flex justify-content-between align-items-center" style="height:48px">
                        <div>
                            <div class="alpha-slate p-2"></div>
                        </div>
                        <div>
                            <div class="d-flex align-items-center">
                                <div class="alpha-slate p-3 mr-2"></div>
                                <div>
                                    <div class="alpha-slate p-2 mb-2" style="width: 70px;"></div>
                                    <div class="alpha-slate p-1" style="width:100px"></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="alpha-slate p-2 rounded-round"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="shippings" class="row">
            <div v-for="(shipping, i) in shippings" :key="i" class="col-md-6 col-lg-4">
                <div class="card card-body">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <checkbox v-model="shipping.status" @input="checkShipping($event, i)"></checkbox>
                        </div>
                        <div class="">
                            <a href="#" class="d-flex align-items-center dropdown-toggle text-default" :id="i" data-toggle="dropdown">
                                <div>
                                    <img :src="'http://api.zhrleather.com/assets/images/shippings/'+shipping.logo" alt="" width="80px">
                                </div>                                
                                <div>
                                    <h5 class="mb-0 font-weight-semibold">{{shipping.name}}</h5>
                                    <span>Select service</span>
                                </div>                       
                            </a>

                            <div class="dropdown-menu">
                                <div v-for="(service, a) in shipping.services" :key="a" class="form-check ml-2">
                                    <label class="form-check-label">
                                        <checkbox v-model="service.status" @input="checkService($event, i, service.id)"></checkbox>
                                        {{ service.service }}
                                    </label>
                                </div>     
                            </div>
                        </div>
                        <div>
                            <a @click.prevent="onEdit(i)" data-toggle="modal" data-target="#modal" href="#" class="text-teal"><i class="icon-cog3"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <form @submit.prevent="onSubmit" class="modal fade right" id="modal">
            <div class="modal-dialog">
                <div class="modal-content vld-parent">
                    <Loading 
                        :color="'#26a69a'"
                        :active.sync="isLoading"
                        :z-index="10"
                        :is-full-page="false">
                    </Loading>
                    <div class="modal-header pb-3">
                        <h5 class="modal-title">{{modalTitle}}</h5>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="">Shipping Name</label>
                            <input type="text" v-model="form.name" class="form-control" placeholder="Shipping name" @input="errors.name=''" :class="[errors.name ? 'border-danger':'']">
                            <span v-if="errors.name" class="form-text text-danger">{{ errors.name }}</span>
                        </div>
                        <div v-for="(service, i) in form.services" :key="i"  class="form-group">
                            <label v-if="i==0">Service</label>
                            <div class="input-group">
                                <input type="text" class="form-control" v-model="service.service" @input="errors.services[i].service=''"  :class="[errors.services[i].service ? 'border-danger':'']" placeholder="Service">
                                
                                <span class="input-group-append">
                                    <button v-if="i==0" @click="addService" class="btn btn-light btn-icon"  type="button" :disabled="form.services.length == 4" :class="[errors.services[i].service ? 'border-danger':'']"><i class="icon-plus-circle2 text-success"></i></button>
                                    <button v-if="i > 0" @click="deleteService(i)" class="btn btn-light btn-icon" type="button" :class="[errors.services[i].service ? 'border-danger':'']" :disabled="modalMode == 'edit' && service.id"><i class="icon-minus-circle2 text-danger"></i></button>
                                </span>
                            </div>
                            <span v-if="errors.services[i].service" class="form-text text-danger">{{errors.services[i].service}}</span>
                        </div>
                        <div class="form-group">
                            <label>Shipping Logo</label>
                            <input type="file" id="file" ref="file" v-on:change="onChangeFileUpload()" class="form-control-uniform-custom">
                        </div>
                    </div>
                    <div class="modal-footer pt-2">
                        <button type="button" class="btn btn-link" data-dismiss="modal">CANCEL</button>
                        <button type="submit" class="btn bg-teal">
                                <i v-if="isLoading" class="icon-spinner4 spinner mr-2"></i>
                                SAVE
                            </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import $ from 'jquery'
import checkbox from '@/components/CheckBox'
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    components: {
        checkbox,
        Loading
    },
    data () {
        return {
            onLoading: false,
            isLoading: false,
            origin: '',
            shippings: null,
            modalMode: 'new',
            modalTitle: '',
            shipping_id: '',
            form: {
                name: '',
                services: [
                    {service: ''}
                ],
                file: '',
            },
            errors: {
                name: '',
                services: [
                    {service:''}
                ],
                file: '',
            },
            error_district: '',
        }
    },
    mounted () {
        this.getData()
        let app = this
       
        $('.form-control-uniform-custom').uniform({
            fileButtonClass: 'action btn bg-blue',
            selectClass: 'uniform-select bg-pink-400 border-pink-400'
        });
        $('#modal').on('hide.bs.modal', () => {
            $('#file').val('')
            $('.filename').html('No file selected')
            app.form = {
                name: '',
                services: [
                    {service: ''}
                ],
                file: '',
            }

            app.errors = {
                name: '',
                services: [
                    {service:''}
                ],
                file: '',
            }
        })

        $("#ac-district").bind("keydown", function(event) {
            if (event.keyCode === $.ui.keyCode.TAB && $(this).autocomplete("instance").menu.active) {
                event.preventDefault();
            }
            
        })
        .autocomplete({
            minLength: 3,
            source: function(request, response) {
                axios.get('/districts/'+request.term)
                .then((data) => {
                    response($.map(data.data, function (n) {
                                return n;
                        }));
                })
            },
            search: function() {
                $(this).parent().removeClass('ui-autocomplete-error');
                $(this).parent().addClass('ui-autocomplete-processing');
            },
            response: function(event, ui) {
                $(this).parent().removeClass('ui-autocomplete-processing');
                if(ui.content.length == 0) {
                    $(this).parent().addClass('ui-autocomplete-error');
                }
            },
            focus: function( event, ui ) {
                $("#ac-district").val(ui.item.name);
                return false;
            },
            select: function( event, ui ) {
                $("#ac-district").val(ui.item.name);
                axios.post('/shipping/origin',{
                    district_id: ui.item.id,
                    name: ui.item.name
                })            
                return false;
            }
        })
        .autocomplete("instance")._renderItem = function(ul, item) {
            return $("<li>").append('<div class="text-semibold">'+ item.name+' -&nbsp<span class="text-info font-weight-semibold">'+item.regency+'</span></div>').appendTo(ul);
        }
    },
    methods: {
        getData () {
            this.shippings = null
            this.onLoading = true
            axios.get('/shipping')
            .then((response) => {
                this.onLoading = false
                this.shippings = response.data.shippings
                this.origin = response.data.origin
            })
        },
        checkShipping (e, i) {
            $('#'+i).dropdown('toggle')
            for (let a = 0; a < this.shippings[i].services.length; a++) {
               this.shippings[i].services[a].status = e                
            }

            axios.post('/shipping/status',{
                id: this.shippings[i].id,
                status: e,
            })
        },
        checkService (e, i, id) {
            if(e == true) {
                this.shippings[i].status = e
                axios.post('/shipping/service_status',{
                    id: id,
                    status: e
                })
            } else {
                axios.post('/shipping/service_status', {
                    id: id,
                    status: e
                })
                if(this.shippings[i].services.every(x => x.status == false ) == true ){
                    this.shippings[i].status = e
                    axios.post('/shipping/status',{
                        id: this.shippings[i].id,
                        status : e
                    })
                }

            }
        },
        addService() {
            this.form.services.push({service:''})
            this.errors.services.push({service:'' })
        },
        deleteService(i) {
            this.form.services.splice(i, 1)
            this.errors.services.splice(i, 1)
        },
        onSubmit () {
            if(!this.form.name) {
                this.errors.name = 'This field shipping name is required'
            }

            for (let i = 0; i < this.form.services.length; i++) {
                if(!this.form.services[i].service) {
                    this.errors.services[i].service = 'This field service is required'
                }                
            }

            if(this.form.name && this.form.services.every(x =>x.service)) {
                if(this.modalMode == 'new') {
                    this.postShipping()
                } 
                if(this.modalMode == 'edit') {
                    this.updateShipping()
                }
            }
        },
        postShipping () {
            this.isLoading = true
            let formData = new FormData()
            formData.append('file', this.form.file)
            formData.append('name', this.form.name)
            formData.append('services', JSON.stringify(this.form.services))
            axios.post('/shipping/store',
                formData,
                {
                    headers: {
                       'Content-Type': 'multipart/form-data' 
                }                
            })
            .then(() => {
                this.isLoading = false
                this.getData()
                $('#modal').modal('hide');
            })
            .catch(error => {
                this.isLoading = false
                this.errors.name = error.response.data.errors.name[0]
            })
        },
        updateShipping () {
            this.isLoading = true
            let formData = new FormData()
            formData.append('file', this.form.file)
            formData.append('name', this.form.name)
            formData.append('id', this.shipping_id)
            formData.append('services', JSON.stringify(this.form.services))
            axios.post('/shipping/update',
                formData,
                {
                    headers: {
                       'Content-Type': 'multipart/form-data' 
                }                
            })
            .then(() => {
                this.isLoading = false
                this.getData()
                $('#modal').modal('hide');
            })
            .catch(error => {
                this.isLoading = false
                this.errors.name = error.response.data.errors.name[0]
            })
        },
        onAdd () {
            this.modalMode = 'new'
            this.modalTitle = 'ADD NEW SHIPPING'
        },
        onEdit (i) {
            this.form.services = []
            this.errors.services = []
            this.modalMode = 'edit'
            this.modalTitle = 'EDIT SHIPPING SERVICE'
            this.shipping_id = this.shippings[i].id
            this.form.name = this.shippings[i].name
            
            if(this.shippings[i].services.length == 0) {
                this.form.services.push({service: ''})
                this.errors.services.push({service: ''})
            }            
            for (let a = 0; a < this.shippings[i].services.length; a++) {
                this.form.services.push({id: this.shippings[i].services[a].id, service: this.shippings[i].services[a].service})                
                this.errors.services.push({service: ''})                
            }
        },
        onChangeFileUpload(){
            this.form.file = this.$refs.file.files[0];
        },
    }
}
</script>