<template>
    <div class="card">
        <div class="card-header bg-white header-elements-inline p-2">
            <h6 class="card-title ml-2">PENJUALAN HARI INI</h6>
            <div class="header-elements">
                <button @click="onCreate" type="button" class="btn bg-teal-400 btn-labeled btn-labeled-left mr-1"><b><i class="icon-plus-circle2"></i></b>Penjualan Baru</button>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-4 border-right">
                    <div class="media">
                        <div class="mr-3 align-self-center">
                            <i class="icon-store icon-2x opacity-75"></i>
                        </div>
                        <div v-if="toko" class="media-body text-left">
                            <h6 class="font-weight-semibold mb-0">{{toko.name}}</h6>
                            <span class="font-size-sm text-muted">{{toko.address}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 border-right">
                    <div class="text-center">
                        <h6 class="font-weight-semibold mb-0">{{Intl.NumberFormat('en-US').format(order)}} Order</h6>
                        <span class="font-size-sm text-muted">ORDER HARI INI</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="text-right">
                        <h6 class="font-weight-semibold mb-0">Rp. {{Intl.NumberFormat('en-US').format(total)}}</h6>
                        <span class="font-size-sm text-muted">TOTAL PENJUALAN HARI INI</span>
                    </div>
                </div>
            </div>
        </div>
        <table class="table">
            <thead class="bg-info">
                <tr>
                    <th style="width: 5%">NO</th>
                    <th style="width: 15%">NOTA</th>
                    <th style="width: 15%">TANGGAL</th>
                    <th class="text-right" style="width: 10%">TOTAL</th>
                    <th style="width: 35%">CARA BAYAR</th>
                    <th class="text-center" style="width: 20%">AKSI</th>
                </tr>
            </thead>
            <tbody v-if="onLoad">
                <tr v-for="p in 10" :key="p">
                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                    <td>
                        <div class="d-flex justify-content-center">
                            <div class="alpha-slate ph-item" style="height: 33px; width: 33px;"></div>
                            <div class="alpha-info ml-1 ph-item" style="height: 33px; width: 33px;"></div>
                            <div class="alpha-primary ml-1 ph-item" style="height: 33px; width: 33px;"></div>
                            <div class="alpha-danger ml-1 ph-item" style="height: 33px; width: 33px;"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr v-for="(item, i) in sellings" :key="i">
                    <td>{{i+1}}</td>
                    <td>{{item.nota}}</td>
                    <td>{{item.date}}</td>
                    <td class="text-right">{{Intl.NumberFormat('en-US').format(item.total)}}</td>
                    <td>{{item.cara_bayar}} {{item.bank}}</td>
                    <td class="text-center">
                            <button @click="onPrint(i)" class="btn btn-light btn-sm btn-icon" data-popup="tooltip" title="Print"><i class="icon-printer"></i></button>
                            <button @click="onDetail(i)" class="btn btn-info btn-sm btn-icon ml-1" data-popup="tooltip" title="Detail" data-toggle="modal" data-target="#modal"><i class="icon-file-eye"></i></button>
                            <button @click="onEdit(i)" class="btn btn-primary btn-sm btn-icon ml-1" data-popup="tooltip" title="Edit"><i class="icon-pencil7"></i></button>
                            <button @click="onDelete(i)" class="btn btn-danger btn-sm btn-icon ml-1" data-popup="tooltip" title="Hapus"><i class="icon-trash"></i></button>
                        </td>
                </tr>
            </tbody>
        </table>
        <div class="card-footer d-flex p-3 ">
            <div class="align-self-center">
                <span class="text-muted">Total Penjualan Hari ini: {{Intl.NumberFormat('en-US').format(page.total)}}</span>
            </div>
            <div class="ml-auto">
                <Paginate :pages="page" @get-page="getData($event)" />
            </div>
        </div>

        <div class="modal fade" id="modal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">PENJUALAN DETAIL</h5>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-4 border-right">
                                <div class="media">
                                    <div class="mr-3 align-self-center">
                                        <i class="icon-store icon-3x opacity-75"></i>
                                    </div>
                                    <div class="media-body text-left">
                                        <h5 class="font-weight-semibold mb-0">{{detail.nota}}</h5>
                                        <span class="text-uppercase font-size-sm text-muted">INVOICE</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 border-right d-flex justify-content-center">
                                <div class="media">
                                    <div class="mr-3 align-self-center">
                                        <i class="icon-calendar2 text-info icon-3x opacity-75"></i>
                                    </div>
                                    <div class="media-body text-left">
                                        <h5 class="font-weight-semibold mb-0">{{detail.date}}</h5>
                                        <span class="text-uppercase font-size-sm text-muted">tanggal</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 d-flex justify-content-end">
                                <div class="media">
                                    <div class="mr-3 align-self-center">
                                        <i class="icon-calculator text-danger icon-3x opacity-75"></i>
                                    </div>
                                    <div class="media-body text-right">
                                        <h5 class="font-weight-semibold mb-0">Rp. {{Intl.NumberFormat('en-US').format(detail.total)}}</h5>
                                        <span class="text-uppercase font-size-sm text-muted">TOTAL PENJUALAN</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tabel-responsive table-scrollable mb-3">
                        <table class="table">
                            <thead class="bg-success">
                                <tr>
                                    <th style="width: 20%">KODE BARANG</th>
                                    <th style="width: 35%">NAMA BARANG</th>
                                    <th class="text-right" style="width: 15%">HARGA</th>
                                    <th class="text-right" style="width: 15%">QTY</th>
                                    <th class="text-right" style="width: 15%">TOTAL</th>
                                </tr>
                            </thead>
                            <tbody v-if="onLoadDetail">
                                <tr v-for="p in 5" :key="p">
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                </tr>
                            </tbody>
                            <tbody v-if="detail.details">
                                <tr v-for="(item, i) in detail.details.slice().reverse()" :key="i">
                                    <td>{{item.kode_barang}}</td>
                                    <td>{{item.nama_barang}}</td>
                                    <td class="text-right">{{Intl.NumberFormat('en-US').format(item.harga_jual)}}</td>
                                    <td class="text-right">{{item.qty}}</td>
                                    <td class="text-right">{{Intl.NumberFormat('en-US').format(item.total)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">TUTUP</button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="toko" id="printMe" class="container">
            <h3 class="text-center">{{toko.name}}</h3>
            <div class="text-center">{{toko.address}}</div>
            <table class="row table">
                <tbody>
                    <tr>
                        <td>{{detail.nota}}</td>
                        <td class="text-right">{{detail.date}}</td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <table class="table">
                <tbody>
                    <tr v-for="(item, i) in detail.details" :key="i">
                        <td>{{item.nama_barang}}</td>
                        <td class="text-right">{{item.qty}}x{{Intl.NumberFormat('en-US').format(item.harga_jual)}}</td>
                        <td class="text-right">{{Intl.NumberFormat('en-US').format(item.total)}}</td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <hr>
            <table class="table">
                <tbody>
                    <tr>
                        <td><span class="font-size">TOTAL</span></td>
                        <td class="font-size text-right">{{Intl.NumberFormat('en-US').format(detail.total)}}</td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <table class="table">
                <tbody>
                    <tr>
                        <td><b>Instagram</b></td>
                        <td class="text-right"><b>@zhr_leather</b></td>
                    </tr>
                    <tr>
                        <td><b>Whatsapp</b></td>
                        <td class="text-right"><b>{{toko.phone}}</b></td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <div class="text-center">Menerima Pesanan/Menjual</div>
            <div class="text-center">-Jaket Kulit -Dompet -Sabuk -sepatu -reparasi -cat kulit</div>
            <hr>
            <div class="text-center">Terima Kasih</div>
        </div>

        <div class="modal fade" id="printed">
            <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content vld-parent">
                    <Loading 
                        :color="'#26a69a'"
                        :loader="'spinner'"
                        :active.sync="isPrinted"
                        :is-full-page="false">
                    </Loading>
                    <div class="modal-body p-5">
                        <div class="text-center">
                            <h5 class="mb-0">PRINTING...</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
import swalInit from '../../../sweetalert'
import axios from 'axios'
import Paginate from '../../../components/Paginate'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    components: {
        Paginate,
        Loading
    },
    data () {
        return {
            isPrinted: false,
            onLoad: false,
            onLoadDetail: false,
            sellings: '',
            page: '',
            order: '',
            total: '',
            detail: {
                nota: '',
                date: '',
                total: '',
                details: [],
            },
        }
    },
    mounted () {
        this.getData()
    },
    computed: {
        ...mapGetters({
            toko: 'auth/toko'
        })
    },
    methods: {
        getData (e) {
            this.onLoad = true
            $("html, body").animate({ scrollTop: "0" })
            axios.get('/selling', {
                params: {
                    page: e
                }
            })
            .then((response) => {
                this.onLoad = false
                this.page = response.data.data
                this.order = response.data.data.total
                this.sellings = response.data.data.data
                this.total = response.data.total
                this.$nextTick(() => {
                    $('[data-popup="tooltip"]').tooltip({ trigger: 'hover' })
                })
            })
        },
        onCreate () {
            this.$router.push('/penjualan/entry')
        },
        onDetail (i) {
            this.onLoadDetail = true
            this.detail = {
                nota: this.sellings[i].nota,
                total: this.sellings[i].total,
                date: this.sellings[i].created_at,
                details: []
            }

            axios.get('/selling/edit/'+this.sellings[i].id)
            .then((response) => {
                this.onLoadDetail = false
                this.detail.details = response.data
            })
        },
        onDelete(i) {
            let id = this.sellings[i].id
            let app = this
            swalInit.fire({
                title: 'Hapus Penjualan',
                text: "Anda yaking ingin menghapus penjualan ini?",
                type: 'question',
                showCancelButton: true,
                confirmButtonText: 'Ya hapus!',
                cancelButtonText: 'Tidak',
                showLoaderOnConfirm: true,
                preConfirm: function () {
                    return axios.post('/selling/delete', {
                        id: id
                    })
                    .then(() => {
                        app.getData()
                    })
                }
            })
        },
        onEdit(i) {
            $('[data-popup="tooltip"]').tooltip('dispose')
            this.$router.push('/penjualan/edit/'+this.sellings[i].id)
        },
        onPrint(i) {
            //$('#printed').modal('show')
            this.isPrinted = true
            this.detail = {
                toko: this.toko.name,
                address: this.toko.address,
                nota: this.sellings[i].nota,
                total: this.sellings[i].total,
                date: this.sellings[i].created_at,
                details: []
            }
            axios.get('/selling/edit/'+this.sellings[i].id)
            .then((response) => {
                $('#printed').modal('hide')
                this.isPrinted = false
                this.detail.details = response.data
                this.$nextTick(() => {
                    this.printed(this.detail)
                })
            })
        },
        printed() {
            function pc_print(data){
                window.location.href= data
            }		
            function android_print(data){
                window.location.href = data;  
            }
            axios.post('/print', this.detail)
            .then((data) => {
                console.log(data.data);
                var ua = navigator.userAgent.toLowerCase();
                var isAndroid = ua.indexOf("android") > -1; 
                if(isAndroid) {
                    android_print(data.data);
                }else{
                    pc_print(data.data);
                }
            });
            // this.$htmlToPaper('printMe', null)
        },
    }
}
</script>
<style scoped>
    #printMe {
        display: none;
    }
</style>