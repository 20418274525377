const banks = [
    {
        name: 'CASH',
        logo: 'cash.png'
    },{
        name: 'BCA (PT.BCA) / BANK CENTRAL ASIA',
        logo: 'bca.png'
    },{
        name: 'BNI (PT. BANK NEGARA INDONESIA)',
        logo: 'bni.png'
    },{
        name: 'BRI (PT. BANK RAKYAT INDONESIA)',
        logo: 'bri.png'
    },{
        name: 'MANDIRI (PT. BANK MANDIRI)',
        logo: 'bri.png'
    },{
        name: 'CITIBANK NA',
        logo: 'citibank.png'
    }
]

export default banks