<template>
    <div>
        <div class="card-body pt-0">
            <div class="row">
                <div class="col-md-6">
                    <h5>Daftar Toko</h5>
                </div>
                <div class="col-md-6 text-right">
                    <button @click="onAdd" type="button" class="btn bg-teal-400 btn-labeled btn-labeled-left" data-toggle="modal" data-target="#modal"><b><i class="icon-plus-circle2"></i></b> Tambah Toko</button>
                </div>
            </div>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th style="width: 10%">No</th>
                    <th style="width: 20%">Nama Toko</th>
                    <th style="width: 40%">Alamat</th>
                    <th style="width: 20%">No Hp</th>
                    <th class="text-center" style="widt: 10%;">Aksi</th>
                </tr>
            </thead>
            <tbody v-if="placeholder">
                <tr v-for="i in 3" :key="i">
                    <td class="ph-item">
                        <div class="alpha-slate p-1 col-1"></div>
                    </td>
                    <td class="ph-item">
                        <div class="alpha-slate p-1 col-6"></div>
                    </td>
                    <td class="ph-item">
                        <div class="alpha-slate p-1 col-6"></div>
                    </td>
                    <td class="ph-item">
                        <div class="alpha-slate p-1 col-6"></div>
                    </td>
                    <td>
                        <div class="d-flex justify-content-center ph-item">
                            <div class="alpha-slate p-2 col-1"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr v-for="(store, i) in stores" :key="i">
                    <td>{{i+1}}</td>
                    <td>{{store.name}}</td>
                    <td>{{store.address}}</td>
                    <td>{{store.phone}}</td>
                    <td class="text-center">
                        <a @click.prevent="onEdit(i)" href="" data-toggle="modal" data-target="#modal"><i class="icon-pencil5"></i></a>
                    </td>
                </tr>
            </tbody>
        </table>

        <form @submit.prevent="onSubmit" class="modal fade right " id="modal">
            <div class="modal-dialog vld-parent">
                <Loading 
                    :color="'#26a69a'"
                    :loader="'spinner'"
                    :active.sync="isLoading"
                    :is-full-page="false">
                </Loading>
                <div class="modal-content">
                    <div class="modal-header pb-2">
                        <h5>{{modal_title}}</h5>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Nama Toko</label>
                            <input v-model="form.name" type="text" @keydown="error=''" :class="[error ? 'border-danger':'']" class="form-control" placeholder="Nama Toko">
                            <span v-if="error" class="form-text text-danger font-size-sm">{{error}}</span>
                        </div>
                        <div class="form-group">
                            <label>Alamat</label>
                            <textarea v-model="form.address" class="form-control" rows="2"></textarea>
                        </div>
                        <div class="form-group">
                            <label>No HP</label>
                            <input v-model="form.phone" type="text" class="form-control" placeholder="Nama HP" maxlength="15">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">BATAL</button>
                        <button type="submit" class="btn bg-teal">SIMPAN</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios'
import $ from 'jquery'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    components: {
        Loading
    },
    data () {
        return {
            placeholder: false,
            isLoading: false,
            stores: null,
            modal_title: null,
            modal_mode: null,
            form: {
                name:'',
                address: '',
                phone: ''
            },
            error: ''
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData() {
            this.stores = null
            this.placeholder = true
            axios.get('/store')
            .then((response) => {
                this.stores = response.data
                this.placeholder = false
            })
        },
        onAdd () {
            this.modal_title = 'Tambah Toko'
            this.modal_mode = 'new'
            this.form = {
                name: '',
                address: '',
                phone: ''
            },
            this.error = ''
        },
        onEdit (i) {
            this.modal_title = 'Ubah Toko'
            this.modal_mode = 'edit'
            this.form = {
                id: this.stores[i].id,
                name: this.stores[i].name,
                address: this.stores[i].address,
                phone: this.stores[i].phone
            }
            this.error =''
        },
        onSubmit () {
            if(!this.form.name) {
                this.error = 'Nama toko harus diisi!!!'
            }
            if(this.form.name) {
                this.isLoading = true
                if(this.modal_mode=='new') {
                    axios.post('/store/create', this.form)
                    .then(() => {
                        this.getData()
                        this.isLoading = false
                        $('#modal').modal('hide')
                    })
                }
                if(this.modal_mode == 'edit') {
                    axios.post('/store/update', this.form)
                    .then(() => {
                        this.getData()
                        this.isLoading = false
                        $('#modal').modal('hide')
                    })
                }
            }
        }
    }
}
</script>