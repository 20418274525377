<template>
    <div>
        <div class="row">
            <div class="col-md-9">
                <div class="card">
                    <div class="card-body">

                        <div class="form-group row mb-0">
                            <div class="col-md-3">
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <span class="input-group-text">AR</span>
                                    </span>
                                    <input ref="sku" v-model="sku" @input="getSKU" type="text" class="form-control" placeholder="Kode Barang" maxlength="4">
                                </div>
                            </div>
                            <div class="col-md-5">
                                <input v-model="barang.nama_barang" type="text" class="form-control" disabled placeholder="Nama Barang">
                            </div>
                            <div class="col-md-2">
                                <autonumeric 
                                    ref="harga"
                                    v-model="new_harga"
                                    class="form-control text-right" 
                                    :placeholder="Intl.NumberFormat('en-US').format(barang.harga_jual)"
                                    :options="{modifyValueOnWheel: false, decimalPlaces: 0}"
                                    @keydown.enter.prevent.native="onEnterHarga"
                                    >
                                </autonumeric>
                            </div>
                            <div class="col-md-2">
                                <autonumeric 
                                    ref="qty"
                                    v-model="qty"
                                    class="form-control text-right" 
                                    :placeholder="'qty'"
                                    :options="{modifyValueOnWheel: false, decimalPlaces: 0}"
                                    @keydown.enter.prevent.native="onEnterQty"
                                    >
                                </autonumeric>
                            </div>
                        </div>
                    </div>
                    <table class="table">
                        <thead class="bg-info">
                            <tr>
                                <td>KODE BARANG</td>
                                <td>NAMA BARANG</td>
                                <td class="text-right">HARGA</td>
                                <td class="text-right">QTY</td>
                                <td class="text-right">TOTAL</td>
                                <td class="text-center">AKSI</td>
                            </tr>
                        </thead>
                        <tbody v-if="onLoad">
                            <tr v-for="p in 5" :key="p">
                                <td><div class="alpha-slate p-1 ph-item"></div></td>
                                <td><div class="alpha-slate p-1 ph-item"></div></td>
                                <td><div class="alpha-slate p-1 ph-item"></div></td>
                                <td><div class="alpha-slate p-1 ph-item"></div></td>
                                <td><div class="alpha-slate p-1 ph-item"></div></td>
                                <td>
                                    <div class="d-flex justify-content-center">
                                        <div class="alpha-slate p-2 col-1 ph-item"></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr v-for="(item, i) in details.slice().reverse()" :key="i" :class="[i == 0 && fadeIn ? 'animated fadeIn':'']">
                                <td>{{item.kode_barang}}</td>
                                <td>{{item.nama_barang}}</td>
                                <td class="text-right">{{Intl.NumberFormat('en-US').format(item.harga)}}</td>
                                <td class="text-right">{{item.qty}}</td>
                                <td class="text-right">{{Intl.NumberFormat('en-US').format(item.total)}}</td>
                                <td class="text-center">
                                    <a @click.prevent="onDelete(item.index)" href="#"><i class="icon-trash text-danger"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="col-md-3">
                <div class="card">
                    <div class="card-body bg-teal rounded-top">
                        <div class="media">
                            <div class="mr-3 align-self-center">
                                <i class="icon-calculator2 icon-3x opacity-75"></i>
                            </div>
                            <div class="media-body text-right">
                                <h4 class="font-weight-semibold mb-0">{{Intl.NumberFormat('en-US').format(total)}}</h4>
                                <span class="text-uppercase font-size-sm">TOTAL TAGIHAN</span>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="form-group">
                            <label>Dibayar:</label>
                            <autonumeric 
                                tabindex="-1"
                                v-model="dibayar"
                                class="form-control form-control-lg text-right" 
                                :placeholder="'dibayar'"
                                :options="{modifyValueOnWheel: false, decimalPlaces: 0}"
                                >
                            </autonumeric>
                        </div>
                        <div class="form-group mb-2">
                            <label>Kembali:</label>
                            <autonumeric 
                                :value="kembali"
                                class="form-control form-control-lg text-right" 
                                :placeholder="'dibayar'"
                                :options="{modifyValueOnWheel: false, decimalPlaces: 0}"
                                :disabled="true"
                                >
                            </autonumeric>
                        </div>
                    </div>
                    <div class="btn-group btn-group-justified">
                        <div class="btn-group">
                            <button @click="onProses" type="button" class="btn btn-success rounded-top-0" style="height: 50px;" :disabled="details.length == 0">Proses</button>
                        </div>

                        <div class="btn-group">
                            <button @click="onBatal" type="button" class="btn btn-danger rounded-top-0" style="height: 50px;">Batal</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <form @submit.prevent="onSubmit" class="modal fade" id="modal">
            <div class="modal-dialog vld-parent">
                 <Loading 
                    :color="'#26a69a'"
                    :loader="'spinner'"
                    :active.sync="isLoading"
                    :is-full-page="false">
                </Loading>
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title text-info"><i class="icon-credit-card mr-1"></i> PILIH CARA PEMBAYARAN</h6>
                    </div>
                    <div class="modal-body">
                        <div class="form-group text-center mb-0">
                           <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    <input v-model="bayar.cara_bayar" @input="debit('tunai')" value="TUNAI" type="radio" class="form-input-styled" name="type" data-fouc>
                                    Bayar Tunai 
                                </label>                                
                            </div>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    <input v-model="bayar.cara_bayar" value="DEBIT/KREDIT" @input="debit('debit')" type="radio" class="form-input-styled" name="type" data-fouc>
                                    Debit / Kredit
                                </label>                                
                            </div>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    <input v-model="bayar.cara_bayar" value="TRANSFER" @input="debit('transfer')" type="radio" class="form-input-styled" name="type" data-fouc>
                                    Transfer Bank
                                </label>                                
                            </div>
                        </div>
                        <div class="text-center">
                            <span v-if="errors_cara_bayar.cara_bayar" class="text-danger">Cara Pembayaran Belum di pilih</span>
                        </div>
                        <legend></legend>
                        <div class="text-center">
                            <div v-if="bayar.cara_bayar == 'TUNAI'">
                                <h5 class="mb-0 p-1 font-weight-semibold">Pembayaran Secara Tunai</h5>                                
                            </div>
                            <div v-if="bayar.cara_bayar == 'DEBIT/KREDIT'">
                                <div class="row">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6">
                                        <select2 v-model="bayar.bank" :options="banks" @input="errors_cara_bayar.bank=''" :placeholder="'Pilih Bank'" />
                                        <div v-if="errors_cara_bayar.bank" class="text-danger mt-1">{{errors_cara_bayar.bank}}</div>                                        
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                            </div>
                            <div v-if="bayar.cara_bayar == 'TRANSFER'">
                                <h5 class="mb-0 p-1 font-weight-semibold">Pembayaran Melalui Transfer Bank</h5>                               
                            </div>
                        </div>
                        <legend></legend>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">BATAL</button>
                        <button @click="onSimpan('print')" type="submit" class="btn bg-teal" :disabled="isLoading">
                                SIMPAN & PRINT
                        </button>
                        <button @click="onSimpan('simpan')" type="submit" class="btn bg-teal" :disabled="isLoading">
                                SIMPAN
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <div v-if="toko" id="printMe" class="container">
            <h3 class="text-center">{{toko.name}}</h3>
            <div class="text-center">{{toko.address}}</div>
            <table class="row table">
                <tbody>
                    <tr>
                        <td>{{print.nota}}</td>
                        <td class="text-right">{{print.created_at}}</td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <table class="table">
                <tbody>
                    <tr v-for="(item, i) in details" :key="i">
                        <td>{{item.nama_barang}}</td>
                        <td class="text-right">{{item.qty}}x{{Intl.NumberFormat('en-US').format(item.harga)}}</td>
                        <td class="text-right">{{Intl.NumberFormat('en-US').format(item.total)}}</td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <hr>
            <table class="table">
                <tbody>
                    <tr>
                        <td><span class="font-size">TOTAL</span></td>
                        <td class="font-size text-right">{{Intl.NumberFormat('en-US').format(total)}}</td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <table class="table">
                <tbody>
                    <tr>
                        <td><b>Instagram</b></td>
                        <td class="text-right"><b>@zhr_leather</b></td>
                    </tr>
                    <tr>
                        <td><b>Whatsapp</b></td>
                        <td class="text-right"><b>{{toko.phone}}</b></td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <div class="text-center">Menerima Pesanan/Menjual</div>
            <div class="text-center">-Jaket Kulit -Dompet -Sabuk -sepatu -reparasi -cat kulit</div>
            <hr>
            <div class="text-center">Terima Kasih</div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import axios from 'axios'
import swalInit from '../../../sweetalert'
import autonumeric from '../../../components/AutoNumeric'
import select2 from '../../../components/Select2'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapGetters } from 'vuex'
export default {
    components: {
        autonumeric,
        select2,
        Loading
    },
    data () {
        return {
            onLoad: false,
            status: true,
            isLoading: false,
            simpan_mode: '',
            banks: [],
            sku: '',
            new_harga: '',
            qty: '',
            print: {
                nota:'',
                created_at: '',
            },
            dibayar: '',
            barang: {
                barang_id: '',
                kode_barang: '',
                nama_barang: '',
                harga: '',
                harga_jual: '',
                harga_beli: '',
            },
            fadeIn: false,
            details: [],
            bayar: {
                cara_bayar: '',
                bank: '',
            },
            errors_cara_bayar: {
                cara_bayar: '',
                bank: '',
            },
            detail: {
                date: ''
            }
        }
    },
    mounted () {
        let id = this.$route.params.id
        this.getData(id)
        $('.form-input-styled').uniform();
        this.$refs.sku.focus()
        this.getBank()
    },
    computed: {
        ...mapGetters({
            toko: 'auth/toko'
        }),
        total () {
            let sum = 0
            this.details.forEach(el => {
                sum += el.total
            })
            return sum
        },
        kembali () {
            return parseInt(this.dibayar - this.total)
        }
    },
    methods: {
        getData(id) {
            this.onLoad = true
            let i = 0
            axios.get('/selling/edit/'+id)
            .then((response) => {
                this.onLoad = false
                response.data.forEach(el => {
                    this.details.push({
                        index: i+=1,
                        barang_id: el.barang_id,
                        kode_barang: el.kode_barang, 
                        nama_barang: el.nama_barang, 
                        harga: el.harga_jual,
                        harga_beli: el.harga_beli,
                        harga_net: el.harga_net,
                        qty: el.qty,
                        total: el.total
                    })
                });
            })
        },
        getBank () {
            this.banks = []
            axios.get('/selling/bank')
            .then((response) => {
                response.data.forEach(el => {
                    this.banks.push({
                        id:el.name,
                        text:el.name
                    })
                });
            })
        },
        onProses () {
            $('.form-input-styled').prop('checked', false).uniform('refresh')
            this.bayar= {
                cara_bayar: '',
                bank: '',
            }
            this.errors_cara_bayar ={
                cara_bayar: '',
                bank: '',
            }
            $('#modal').modal('show')
        },
        debit (val) {
            if(val  == 'tunai') {
                this.errors_cara_bayar.cara_bayar = ''
                this.errors_cara_bayar.bank =''
                this.bayar.bank =''
            }
            if(val  == 'debit') {
                this.errors_cara_bayar.cara_bayar = ''  
            }
            if(val == 'transfer') {
                this.errors_cara_bayar.cara_bayar = ''
                this.errors_cara_bayar.error_bank =''
                this.bayar.bank =''
            }
        },
        onSimpan(val) {
            this.simpan_mode = val
        },
        onBatal () {
            let app = this
            swalInit.fire({
                title: 'Membatalkan',
                text: "Anda yakin ingin membatalkanya?",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya, Batalkan!',
                cancelButtonText: 'tidak',
                showLoaderOnConfirm: true,
                preConfirm: function () {
                    app.status = true
                    app.$router.push('/penjualan')
                }
            })
        },
        onSubmit () {
            if(!this.bayar.cara_bayar) {
                this.errors_cara_bayar.cara_bayar = 'Pilih Cara Pe,bayaran'
            }
            if(this.bayar.cara_bayar) {               
                if(this.bayar.cara_bayar == 'DEBIT/KREDIT') {
                    if(!this.bayar.bank) {
                        this.errors_cara_bayar.bank = 'Bank Belum dipilih'
                    } 
                    if(this.bayar.bank) {
                        this.postSelling()
                    }
                } else {
                    this.postSelling()
                }                
            }
        },
        postSelling () {
            this.isLoading = true
            axios.post('/selling/update', {
                id: this.$route.params.id,
                total: this.total,
                cara_bayar: this.bayar.cara_bayar,
                bank: this.bayar.bank,
                details: this.details
            })
            .then((response) => {
                $('#modal').modal('hide')
                this.isLoading = false
                if(this.simpan_mode == 'simpan') {
                    this.status = true
                    this.$router.push('/penjualan')
                }
                if(this.simpan_mode == 'print') {
                    this.status = true
                    this.detail = {
                        toko: this.toko.name,
                        address: this.toko.address,
                        nota: response.data.nota,
                        total: this.total,
                        date: response.data.created_at,
                        details: this.details
                    }
                    this.$nextTick(() => {
                        this.printed(this.detail)
                    })
                }
            })
        },
        printed() {
            function pc_print(data){
                window.location.href= data
            }		
            function android_print(data){
                window.location.href = data;  
            }
            axios.post('/print/selling', this.detail)
            .then((data) => {
                console.log(data.data);
                var ua = navigator.userAgent.toLowerCase();
                var isAndroid = ua.indexOf("android") > -1; 
                if(isAndroid) {
                    android_print(data.data);
                    this.$nextTick(() => {
                        this.$router.push('/penjualan')
                    })
                }else{
                    pc_print(data.data);
                    this.$nextTick(() => {
                        this.$router.push('/penjualan')
                    })
                }
            });
        },
        getSKU () {
            this.barang = {
                barang_id: '',
                kode_barang: '',
                nama_barang: '',
                harga: '',
                harga_beli: '',
                harga_jual:''
            }
            this.new_harga = ''
            this.qty = ''
            this.fadeIn = false
            if(this.sku.length==4) {
                axios.get('/barang/search/AR'+this.sku)
                .then((response) => {
                    if(response.data.error) {
                        swalInit.fire({
                            title: 'AR'+this.sku,
                            text: response.data.error,
                            type: 'error',
                            confirmButtonClass: 'btn btn-primary',
                            buttonsStyling: false,
                        })
                        .then(()=> {
                            this.$refs.sku.focus()
                        }) 
                    } else {
                        this.barang.barang_id = response.data.id
                        this.barang.nama_barang = response.data.nama_barang
                        this.barang.kode_barang = response.data.kode_barang
                        this.barang.harga = response.data.harga_net
                        this.barang.harga_jual = response.data.harga_jual
                        this.barang.harga_beli = response.data.harga_beli
                        this.$refs.harga.$el.focus()
                    }
                })
            }
        },
        onEnterHarga () {
            if(this.new_harga >= this.barang.harga) {
                this.$refs.qty.$el.focus()
            } 
            if(this.new_harga < this.barang.harga) {
                swalInit.fire({
                    title: 'Harga Rendah',
                    text: 'Harga yang anda masukan terlalu rendah',
                    type: 'warning',
                    confirmButtonClass: 'btn btn-primary',
                    buttonsStyling: false,
                })
            }
        },
        onEnterQty () {
            let app = this
            if(this.new_harga < this.barang.harga) {
                app.$refs.qty.$el.blur()
                swalInit.fire({
                    title: 'Harga Rendah',
                    text: 'Harga yang anda masukan terlalu rendah',
                    type: 'warning',
                    confirmButtonClass: 'btn btn-primary',
                    buttonsStyling: false,
                })
                .then(() => {                    
                    app.$refs.harga.$el.focus()
                })
            }

            if(this.qty <= 0 ) {
                swalInit.fire({
                    title: 'Qty Belum diisi',
                    text: 'Qty Minimum 1',
                    type: 'error',
                    confirmButtonClass: 'btn btn-primary',
                    buttonsStyling: false,
                })
            }

            if(this.qty > 0 && this.new_harga >= this.barang.harga) {
                this.status = false
                this.fadeIn = true
                let i = this.details.length
                this.details.push({
                    index: i+1,
                    barang_id: this.barang.barang_id,
                    kode_barang: this.barang.kode_barang, 
                    nama_barang: this.barang.nama_barang, 
                    harga: this.new_harga,
                    harga_beli: this.barang.harga_beli,
                    harga_net: this.barang.harga,
                    qty: this.qty,
                    total: parseInt(this.new_harga * this.qty)
                })
                this.$nextTick(() => {
                    this.barang = {
                        id: '',
                        kode_barang: '',
                        nama_barang: '',
                        harga: '',
                        harga_beli: '',
                        harga_jual:''
                    }
                    this.sku = ''
                    this.new_harga =''
                    this.qty = ''
                    this.$refs.sku.focus()
                })
            }
        },
        onDelete (index) {
            let i = this.details.map(item => item.index).indexOf(index)
            this.details.splice(i, 1)
        },
    },
    beforeRouteLeave(to, from, next) {
        if(this.status) {
            next()
        } else {
            swalInit.fire({
                title: 'DATA BELUM DI SIMPAN',
                text: 'Apakah anda ingin menyipan data ini ?',
                type:'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya Simpan!',
                cancelButtonText: 'Tidak',
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                buttonsStyling: false,
            })
            .then((result)=>{
                if(result.value) {
                    this.onProses()
                } else {
                    this.status = true
                    this.$router.push('/penjualan')
                }
            })
        }
    } 
}
</script>
<style scoped>
    #printMe {
        display: none;
    }
</style>