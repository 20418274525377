<template>
    <div v-if="user" class="row">
        <div class="col-md-4">
            <div class="card">
                <div class="card-body bg-blue text-center card-img-top" style="background-image: url(/assets/images/backgrounds/panel_bg.png); background-size: contain;">
                    <div class="card-img-actions d-inline-block mb-3">
                        <img v-if="user.avatar" class="img-fluid rounded-circle" :src="user.avatar" width="170" height="170" alt="">
                        <img v-else class="img-fluid rounded-circle" src="@/assets/images/placeholders/placeholder.jpg" width="170" height="170" alt="">
                        <div class="card-img-actions-overlay card-img rounded-circle">
                            <a @click.prevent="onUpload" href="#" class="btn btn-outline bg-white text-white border-white border-2 btn-icon rounded-round" data-toggle="modal" data-target="#modal">
                                <i class="icon-camera"></i>
                            </a>
                        </div>
                    </div>

                    <h6 class="font-weight-semibold mb-0">{{user.name}}</h6>
                    <span class="d-block opacity-75">Head of UX</span>

                    <ul class="list-inline list-inline-condensed mt-3 mb-0">
                        <li class="list-inline-item">
                            <a href="#" class="btn btn-outline bg-white btn-icon text-white border-white border-2 rounded-round">
                                <i class="icon-google-drive"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#" class="btn btn-outline bg-white btn-icon text-white border-white border-2 rounded-round">
                                <i class="icon-twitter"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#" class="btn btn-outline bg-white btn-icon text-white border-white border-2 rounded-round">
                                <i class="icon-github"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="card-body border-top-0">
                    <div class="d-sm-flex flex-sm-wrap mb-3">
                        <div class="font-weight-semibold">Full name:</div>
                        <div class="ml-sm-auto mt-2 mt-sm-0">{{user.name}}</div>
                    </div>

                    <div class="d-sm-flex flex-sm-wrap mb-3">
                        <div class="font-weight-semibold">Mobile number:</div>
                        <div v-if="user.contact" class="ml-sm-auto mt-2 mt-sm-0">{{user.contact.phone}}</div>
                    </div>

                    <div class="d-sm-flex flex-sm-wrap">
                        <div class="font-weight-semibold">Personal Email:</div>
                        <div class="ml-sm-auto mt-2 mt-sm-0"><a href="#">{{user.email}}</a></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="card card-body">
                <ul class="nav nav-tabs nav-tabs-bottom">
                    <li class="nav-item"><router-link to="/profile" class="nav-link" exact><i class="icon-profile mr-2"></i> Edit Profile</router-link></li>
                    <li class="nav-item"><router-link to="/profile/edit-email" class="nav-link"><i class="icon-mail5 mr-2"></i> Account</router-link></li>
                    <li class="nav-item"><router-link to="/profile/change-password" class="nav-link"><i class="icon-lock2 mr-2"></i> Change Password</router-link></li>
                </ul>
                <div class="content-tab">
                    <transition name="fade" mode="out-in">
                        <router-view> </router-view>
                    </transition>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modal">
            <div class="modal-dialog">
                <div class="modal-content vld-parent">
                    <Loading 
                        :color="'#26a69a'"
                        :active.sync="isLoading"
                        :is-full-page="false">
                    </Loading>
                    <div class="modal-header">
                        <h5 class="modal-title">Change Foto Profile</h5>
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div class="modal-body">
                        <vue-croppie v-if="image"
                            ref="croppieRef" 
                            :viewport = "{ width: 250, height: 250, type: 'circle' }"
                            :boundary="{ width: 300, height: 300}"
                            :enableResize = "false">
                        </vue-croppie>
                        <div class="text-center">
                            <input ref="imageInput" type="file" class="file-input-profile" data-show-caption="false" data-show-upload="false" data-show-preview="false" accept=".jpg,.png" @change="onFileChange">
                        </div>
                    </div>
                    <div class="modal-footer bg-light">
                        <button type="button" @click="uploadAvatar"  class="btn bg-primary" :disabled="isDisabled">Save changes</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import $ from 'jquery'
import axios from 'axios'
require('bootstrap-fileinput')
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    components: {
        Loading
    },
    data () {
        return {
            isLoading: false,
            isDisabled: true,
            image: '',
            cropped: '',
        }
    },
    mounted() {
        let app = this
        $('#modal').on('hidden.bs.modal', function(){
            $('.file-input-profile').fileinput('clear')
            app.isLoading = false
            app.image = ''
        })
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
        }),
    },
    methods: {
        onUpload () {
            $('.file-input-profile').fileinput({
                browseLabel: 'Browse file image',
                browseClass: 'btn bg-slate-700',
                browseIcon: '<i class="icon-file-plus mr-2"></i>',
                uploadIcon: '<i class="icon-file-upload2 mr-2"></i>',
                showRemove: false,
                layoutTemplates: {
                    icon: '<i class="icon-file-check"></i>',
                },
                allowedFileExtensions: ["jpg","png"],
            });
        },
        onFileChange (e) {            
            this.isDisabled = false
            const file = e.target.files['0']
            if(file) {
                this.image = URL.createObjectURL(file)
            }
            this.$nextTick(() => {
                this.$refs.croppieRef.bind({
                    url: this.image
                })
            })      
        },
        uploadAvatar () {
            
            let options = {
                format: 'png'
            }
            this.$refs.croppieRef.result(options, (output) => {
                this.cropped = output;
            });
            this.$nextTick(() => {
                this.isLoading = true
                axios.post('/profile/avatar',{
                    image: this.cropped
                })
                .then(() => {
                    this.isLoading = false
                    $('#modal').modal('hide')
                    this.$store.dispatch('auth/attempt', localStorage.getItem('token'))
                })
            })  
        } 
    }
}
</script>
