<template>
    <div>
        <div class="card">
            <div class="card-header bg-white header-elements-inline p-2">
                <h6 class="card-title ml-2">PENJUALAN RESELLER</h6>
                <div class="header-elements">
                    <button type="button" class="btn bg-teal-400 btn-labeled btn-labeled-left mr-1" data-toggle="modal" data-target="#modal"><b><i class="icon-plus-circle2"></i></b>Transaksi Baru</button>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4 align-self-center">
                        <div class="form-group-feedback form-group-feedback-left">
                            <input v-model="search"  type="search" class="form-control" placeholder="Ketik Nama Seller">
                            <div class="form-control-feedback">
                                <i class="icon-search4 font-size-base text-muted"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 border-right border-left align-self-center">
                        <ul class="nav nav-pills nav-pills-bordered nav-pills-toolbar nav-justified mb-0">
                            <li class="nav-item"><a @click.prevent="onStatus('tagihan')" href="#" class="nav-link active" data-toggle="tab">Tagihan</a></li>
                            <li class="nav-item"><a  @click.prevent="onStatus('lunas')" href="#" class="nav-link" data-toggle="tab">Lunas</a></li>
                        </ul>
                    </div>
                    <div class="col-md-2 border-right text-success">
                        <div class="text-right">
                            <h6 class="font-weight-semibold mb-0">Rp. {{Intl.NumberFormat('en-US').format(sudah_bayar)}}</h6>
                            <span class="font-size-sm text-muted">SUDAH BAYAR HARI INI</span>
                        </div>
                    </div>
                    <div class="col-md-2 text-danger">
                        <div class="text-right">
                            <h6 class="font-weight-semibold mb-0">Rp. {{Intl.NumberFormat('en-US').format(belum_bayar)}}</h6>
                            <span class="font-size-sm text-muted">TOTAL BELUM BAYAR</span>
                        </div>
                    </div>
                </div>
            </div>
            <table class="table">
                <thead class="bg-info">
                    <tr>
                        <th style="width:10%">INVOICE</th>
                        <th style="width:15%">TANGGAL</th>
                        <th style="width:15%">RESELLER</th>
                        <th class="text-right" style="width:10%">TOTAL</th>
                        <th class="text-right bg-success" style="width:13%">SUDAH BAYAR</th>
                        <th class="text-right bg-danger" style="width:12%">BELUM BAYAR</th>
                        <th class="text-center" style="width:20%">AKSI</th>
                    </tr>
                </thead>
                <tbody v-if="onLoad">
                    <tr v-for="p in 10" :key="p">
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td>
                            <div class="d-flex justify-content-center">
                                <div class="alpha-slate ph-item" style="height: 33px; width: 33px;"></div>
                                <div class="alpha-info ml-1 ph-item" style="height: 33px; width: 33px;"></div>
                                <div class="alpha-success ml-1 ph-item" style="height: 33px; width: 33px;"></div>
                                <div class="alpha-primary ml-1 ph-item" style="height: 33px; width: 33px;"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr v-for="(item, i) in reseller" :key="i">
                        <td>INV-{{item.id}}</td>
                        <td>{{item.created_at}}</td>
                        <td>{{item.nama_seller}}</td>
                        <td class="text-right">{{Intl.NumberFormat('en-US').format(item.total)}}</td>
                        <td class="text-right text-success">{{Intl.NumberFormat('en-US').format(item.bayar)}}</td>
                        <td class="text-right text-danger">{{Intl.NumberFormat('en-US').format(item.sisa)}}</td>
                        <td class="text-center">
                            <button @click="onPrint(i)" class="btn btn-light btn-sm btn-icon" data-popup="tooltip" title="Print"><i class="icon-printer"></i></button>
                            <button @click="onDetail(i)" class="btn btn-info btn-sm btn-icon ml-1" data-popup="tooltip" title="Detail" data-toggle="modal" data-target="#modal_detail"><i class="icon-file-eye"></i></button>
                            <button @click="onBayar(i)" class="btn btn-success btn-sm btn-icon ml-1" data-popup="tooltip" title="Bayar" data-toggle="modal" data-target="#modal_bayar" :disabled="item.total == item.bayar"><i class="icon-credit-card"></i></button>
                            <button @click="onEdit(i)" class="btn btn-primary btn-sm btn-icon ml-1" data-popup="tooltip" title="Edit" :disabled="item.total == item.bayar"><i class="icon-pencil7"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="card-footer d-flex p-3 ">
                <div class="align-self-center">
                    <span class="text-muted">Total Ngoles Barang: {{Intl.NumberFormat('en-US').format(page.total)}}</span>
                </div>
                <div class="ml-auto">
                    <Paginate :pages="page" @get-page="getData($event)" />
                </div>
            </div>
        </div>
        
        <form @submit.prevent="onSubmit" class="modal fade" id="modal">
            <div class="modal-dialog modal-sm vld-parent">
                <Loading 
                    :color="'#26a69a'"
                    :loader="'spinner'"
                    :active.sync="isLoading"
                    :is-full-page="false">
                </Loading>
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Pilih Seller</h5>
                    </div>
                    <div class="modal-body">
                        <select2 v-model="form.seller_id" @input="error.seller_id=''" :options="sellers" :placeholder="'Pilih Seller'" />
                        <span v-if="error.seller_id" class="form-text text-danger">{{error.seller_id}}</span>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">BATAL</button>
                        <button type="submit" class="btn bg-teal" :disabled="isLoading">BERIKUTNYA</button>
                    </div>
                </div>
            </div>
        </form>

         <form @submit.prevent="onSubmitBayar" data-backdrop="static" class="modal fade" id="modal_bayar">
            <div class="modal-dialog vld-parent">
                <Loading 
                    :color="'#26a69a'"
                    :loader="'spinner'"
                    :active.sync="isLoading"
                    :is-full-page="false">
                </Loading>
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title text-info"><i class="icon-credit-card mr-1"></i> PILIH CARA PEMBAYARAN</h6>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-4 text-center border-right">
                                <h6 class="forn-weight-semibold mb-0"><i class="icon-user mr-2"></i> {{bayar.nama_seller}}</h6>
                                <span class="text-muted">NAMA SELLER</span>
                            </div>
                             <div class="col-md-4 text-center border-right">
                                <h6 class="forn-weight-semibold mb-0 text-danger"><i class="icon-alarm mr-2"></i> {{Intl.NumberFormat().format(bayar.sisa)}}</h6>
                                <span class="text-muted">BELUM BAYAR</span>
                            </div>
                            <div class="col-md-4">
                                <autonumeric 
                                    ref="bayar"
                                    class="form-control text-right" 
                                    :class="[errors_cara_bayar.bayar ? 'border-danger':'']"
                                    :placeholder="'Jumlah Bayar'"
                                    :options="{modifyValueOnWheel: false, decimalPlaces: 0}"
                                    v-model="bayar.bayar"
                                    v-on:keydown.native="errors_cara_bayar.bayar=''"
                                    >
                                </autonumeric>
                            </div>
                        </div>
                        <div class="form-group text-center mt-4 mb-0">
                           <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    <input v-model="bayar.cara_bayar" @input="debit('tunai')" value="TUNAI" type="radio" class="form-input-styled" name="type" data-fouc>
                                    Bayar Tunai 
                                </label>                                
                            </div>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    <input v-model="bayar.cara_bayar" value="DEBIT/KREDIT" @input="debit('debit')" type="radio" class="form-input-styled" name="type" data-fouc>
                                    Debit / Kredit
                                </label>                                
                            </div>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    <input v-model="bayar.cara_bayar" value="TRANSFER" @input="debit('transfer')" type="radio" class="form-input-styled" name="type" data-fouc>
                                    Transfer Bank
                                </label>                                
                            </div>
                        </div>
                        <div class="text-center">
                            <span v-if="errors_cara_bayar.cara_bayar" class="text-danger">Cara Pembayaran Belum di pilih</span>
                        </div>
                        <legend></legend>
                        <div class="text-center">
                            <div v-if="bayar.cara_bayar == 'TUNAI'">
                                <h5 class="mb-0 p-1 font-weight-semibold">Pembayaran Secara Tunai</h5>                                
                            </div>
                            <div v-if="bayar.cara_bayar == 'DEBIT/KREDIT'">
                                <div class="row">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6">
                                        <select2 v-model="bayar.bank" :options="banks" @input="errors_cara_bayar.bank=''" :placeholder="'Pilih Bank'" />
                                        <div v-if="errors_cara_bayar.bank" class="text-danger mt-1">{{errors_cara_bayar.bank}}</div>                                        
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                            </div>
                            <div v-if="bayar.cara_bayar == 'TRANSFER'">
                                <h5 class="mb-0 p-1 font-weight-semibold">Pembayaran Melalui Transfer Bank</h5>                               
                            </div>
                        </div>
                        <legend></legend>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">BATAL</button>
                        <button type="submit" class="btn bg-teal" :disabled="isLoading">
                                <i v-if="isLoading" class="icon-spinner4 spinner mr-2"></i>
                                SIMPAN
                            </button>
                    </div>
                </div>
            </div>
        </form>

        <div class="modal fade" id="modal_detail">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">DETAIL TRANSAKSI</h5>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-3 text-center border-right">
                                <h6 class="mb-0 font-weight-semibold"><i class="icon-user mr-2"></i>{{detail.nama_seller}}</h6>
                                <span class="text-muted">NAMA SELLER</span>
                            </div>
                            <div class="col-md-3 text-center border-right">
                                <h6 class="mb-0 font-weight-semibold"><i class="icon-alarm-add mr-2"></i>{{Intl.NumberFormat().format(detail.total)}}</h6>
                                <span class="text-muted">TOTAL</span>
                            </div>
                            <div class="col-md-3 text-center border-right">
                                <h6 class="mb-0 font-weight-semibold text-success"><i class="icon-alarm-check mr-2"></i>{{Intl.NumberFormat().format(detail.bayar)}}</h6>
                                <span class="text-muted">SUDAH BAYAR</span>
                            </div>
                            <div class="col-md-3 text-center">
                                <h6 class="mb-0 font-weight-semibold text-danger"><i class="icon-alarm mr-2"></i>{{Intl.NumberFormat().format(detail.sisa)}}</h6>
                                <span class="text-muted">BELUM BAYAR</span>
                            </div>
                        </div>
                        <ul class="nav nav-tabs nav-tabs-bottom nav-justified mt-3">
                            <li class="nav-item"><a href="#bottom-justified-tab1" class="nav-link active" data-toggle="tab">Detail Barang</a></li>
                            <li class="nav-item"><a href="#bottom-justified-tab2" class="nav-link" data-toggle="tab">Detail Pembayaran</a></li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane fade show active" id="bottom-justified-tab1">
                                <div class="table-responsive table-scrollable">
                                    <table class="table table-sm">
                                        <thead class="bg-info">
                                            <tr>
                                                <th>No</th>
                                                <th>Kode Barang</th>
                                                <th>Nama Barang</th>
                                                <th class="text-right">Harga</th>
                                                <th class="text-right">Qty</th>
                                                <th class="text-right">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, i) in detail.detail_barang" :key="i">
                                                <td>{{i+1}}</td>
                                                <td>{{item.kode_barang}}</td>
                                                <td>{{item.nama_barang}}</td>
                                                <td class="text-right">{{Intl.NumberFormat().format(item.harga)}}</td>
                                                <td class="text-right">{{item.qty}}</td>
                                                <td class="text-right">{{Intl.NumberFormat().format(item.total)}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="bottom-justified-tab2">
                                <div class="table-responsive table-scrollable">
                                    <table class="table table-sm">
                                        <thead class="bg-success">
                                            <tr>
                                                <th>No</th>
                                                <th>Tanggal</th>
                                                <th class="text-right">Jumlah Pembayar</th>
                                                <th class="text-right bg-danger">Sisa Tagihan</th>
                                                <th>Cara Bayar</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, i) in detail.detail_bayar" :key="i">
                                                <td>{{i + 1}}</td>
                                                <td>{{item.created_at}}</td>
                                                <td class="text-right">{{Intl.NumberFormat().format(item.bayar)}}</td>
                                                <td class="text-right text-danger">{{Intl.NumberFormat().format(item.sisa)}}</td>
                                                <td>{{item.cara_bayar}} {{item.bank}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">TUTUP</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="printed">
            <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content vld-parent">
                    <Loading 
                        :color="'#26a69a'"
                        :loader="'spinner'"
                        :active.sync="isPrinted"
                        :is-full-page="false">
                    </Loading>
                    <div class="modal-body p-5">
                        <div class="text-center">
                            <h5 class="mb-0">PRINTING...</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
 
        <div v-if="toko" id="printMe" class="container">
            <h3 class="text-center">{{toko.name}}</h3>
            <div class="text-center">{{toko.address}}</div>
            <table class="row table">
                <tbody>
                    <tr>
                        <td>Kepada</td>
                        <td class="text-right">{{print.nama_seller}}</td>
                    </tr>
                    <tr>
                        <td>ID NOTA</td>
                        <td class="text-right">INV-{{print.reseller_id}}</td>
                    </tr>
                    <tr>
                        <td>Tanggal</td>
                        <td class="text-right">{{print.created_at}}</td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <table class="table">
                <tbody>
                    <tr v-for="(item, i) in print.details" :key="i">
                        <td>{{item.nama_barang}}</td>
                        <td class="text-right">{{item.qty}}x{{Intl.NumberFormat('en-US').format(item.harga)}}</td>
                        <td class="text-right">{{Intl.NumberFormat('en-US').format(item.total)}}</td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <hr>
            <table class="table">
                <tbody>
                    <tr>
                        <td><span class="font-size">TOTAL</span></td>
                        <td class="font-size text-right">{{Intl.NumberFormat('en-US').format(total)}}</td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <table class="table">
                <tbody>
                    <tr>
                        <td><b>Instagram</b></td>
                        <td class="text-right"><b>@zhr_leather</b></td>
                    </tr>
                    <tr>
                        <td><b>Whatsapp</b></td>
                        <td class="text-right"><b>{{toko.phone}}</b></td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <div class="text-center">Menerima Pesanan/Menjual</div>
            <div class="text-center">-Jaket Kulit -Dompet -Sabuk -sepatu -reparasi -cat kulit</div>
            <hr>
            <div class="text-center">Terima Kasih</div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import axios from 'axios'
import { mapGetters } from 'vuex'
import select2 from '../../../components/Select2'
import Paginate from '../../../components/Paginate'
import swalInit from '../../../sweetalert'
import _ from 'lodash'
import autonumeric from '../../../components/AutoNumeric'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    components: {
        select2,
        Loading,
        autonumeric,
        Paginate
    },
    data () {
        return {
            status: 'tagihan',
            onLoad: false,
            isPrinted: false,
            isLoading: false,
            search: '',
            sudah_bayar: '',
            belum_bayar: '',
            sellers: [],
            reseller: '',
            page: '',
            bayar: {
                reseller_id: '',
                seller_id: '',
                nama_seller: '',
                sisa: '',
                bayar: '',
                cara_bayar: '',
                bank: '',
            },
            detail: {
                nama_seller: '',
                total: '',
                bayar: '',
                sisa: '',
                detail_barang: '',
                detail_bayar: '',
            },
            banks: [],
            errors_cara_bayar: {
                cara_bayar: '',
                bayar: '',
                bank: '',
            },
            form: {
                seller_id: ''
            },
            error: {
                seller_id: ''
            },
            print: {
                reseller_id: '',
                nama_seller: '',
                created_at: '',
                details: [],
            }
        }
    },
    mounted () {
        this.getData()
        $('.form-input-styled').uniform(); 
        $('#modal_bayar').on('shown.bs.modal', () => {
            this.$refs.bayar.$el.focus()
        })
    },
    computed: {
        ...mapGetters({
            toko: 'auth/toko'
        }),
        total () {
            let sum = 0
            this.print.details.forEach(el => {
                sum += el.total
            });

            return sum
        }
    },
    methods: {
        getData (e) {
            $("html, body").animate({ scrollTop: "0" })
            this.reseller = ''
            this.onLoad = true
            this.sellers = [{id:'',text:''}]
            this.banks = [{id:'',text:''}]
            axios.get('/reseller', {
               params: {
                   page: e,
                   search: this.search,
                   status: this.status
               }
            })
            .then((response) => {
                this.onLoad = false
                this.reseller = response.data.data.data
                this.page = response.data.data
                this.sudah_bayar = response.data.sudah_bayar
                this.belum_bayar = response.data.belum_bayar
                response.data.seller.forEach(el => {
                    this.sellers.push({
                        id:el.id,
                        text:el.nama_seller
                    })
                });
                response.data.banks.forEach(el => {
                    this.banks.push({
                        id:el.name,
                        text:el.name
                    })
                });
                this.$nextTick(() => {
                    $('[data-popup="tooltip"]').tooltip({ trigger: 'hover' })
                })
            })
        },
        onStatus (val) {
            this.status = val
            this.getData()
        },
        onPrint (i) {
            $('#printed').modal('show')
            this.isPrinted = true
            this.print= {
                reseller_id: this.reseller[i].id,
                nama_seller: this.reseller[i].nama_seller,
                created_at: this.reseller[i].created_at,
                details: [],
            }
            axios.get('/reseller/print/'+this.reseller[i].id)
            .then((response) => {
                $('#printed').modal('hide')
                this.isPrinted = false
                this.print.details = response.data
                this.$nextTick(() => {
                    this.printed()
                })
            })
        },
        printed() {
            this.$htmlToPaper('printMe', null)
        },
        onDetail (i) {
            this.detail = {
                nama_seller: this.reseller[i].nama_seller,
                total: this.reseller[i].total,
                bayar: this.reseller[i].bayar,
                sisa: this.reseller[i].sisa,
                detail_barang: '',
                detail_bayar: '',
            }
            axios.get('/reseller/detail/'+this.reseller[i].id)
            .then((response) => {
                this.detail.detail_barang = response.data.barang 
                this.detail.detail_bayar = response.data.bayar
            })
        },
        onBayar (i) {
            $('.form-input-styled').prop('checked', false).uniform('refresh')
            this.bayar = {
                reseller_id: this.reseller[i].id,
                seller_id: this.reseller[i].seller_id,
                nama_seller: this.reseller[i].nama_seller,
                sisa: this.reseller[i].sisa,
                bayar: '',
                cara_bayar: '',
                bank: '',
            }
        },
        debit (val) {
            if(val  == 'tunai') {
                this.errors_cara_bayar.cara_bayar = ''
                this.errors_cara_bayar.bank =''
                this.bayar.bank =''
            }
            if(val  == 'debit') {
                this.errors_cara_bayar.cara_bayar = ''  
            }
            if(val == 'transfer') {
                this.errors_cara_bayar.cara_bayar = ''
                this.errors_cara_bayar.error_bank =''
                this.bayar.bank =''
            }
        },
        onEdit (i) {
            $('[data-popup="tooltip"]').tooltip('dispose')
            let id = this.reseller[i].id
            this.$router.push('/penjualan/reseller/edit/'+id)
        },
        onSubmitBayar () {
            if(!this.bayar.bayar) {
                this.errors_cara_bayar.bayar = 'Jumlah pembayaran belum diisi'
            }
            if(!this.bayar.cara_bayar) {
                this.errors_cara_bayar.cara_bayar = 'Cara bayar belm dipilih'
            }
            if(this.bayar.bayar > this.bayar.sisa) {
                swalInit.fire({
                    title: 'Opssss...',
                    text: "Jumlah pemabayaran lebih besar dari tagihan",
                    type: 'error',
                    confirmButtonText: 'Ok',
                })
                .then(() => {
                    this.errors_cara_bayar.bayar = true
                })
            }
            if(this.bayar.bayar && this.bayar.cara_bayar && this.bayar.bayar <= this.bayar.sisa) {
                if(this.bayar.cara_bayar == 'DEBIT/KREDIT') {
                    if(!this.bayar.bank) {
                        this.errors_cara_bayar.bank = 'Bank Belum dipilih'
                    } 
                    if(this.bayar.bank) {
                        this.postBayar()
                    }
                } else {
                    this.postBayar()
                }
            }
        },
        postBayar () {
            this.isLoading = true
            axios.post('/reseller/bayar', {
                reseller_id: this.bayar.reseller_id,
                seller_id: this.bayar.seller_id,
                cara_bayar: this.bayar.cara_bayar,
                bank: this.bayar.bank,
                bayar: this.bayar.bayar,
                sisa: parseInt(this.bayar.sisa - this.bayar.bayar)
            })
            .then(() => {
                $('#modal_bayar').modal('hide')
                this.isLoading = false
                this.getData()
                swalInit.fire({
                    text: 'Pembayaran Berhasil disimpan',
                    type: 'success',
                    toast: true,
                    showConfirmButton: false,
                    position: 'top'
                })
            })
        },
        onSubmit () {
            if(!this.form.seller_id) {
                this.error.seller_id = 'Seller belum dipilih'
            }
            if(this.form.seller_id) {
                this.isLoading = true
                axios.post('/reseller/create', this.form)
                .then((response) => {
                    this.isLoading = false
                    $('#modal').modal('hide')
                    this.$router.push('/penjualan/reseller/'+response.data.id)
                })
            }
        },
        searchData: _.debounce( function() {
            this.getData()
        }, 500)
    },
    watch: {
        search () {
            this.searchData()
        }
    }
}
</script>
<style scoped>
    #printMe {
        display: none;
    }
</style>