<template>
    <div class="card">
        <div class="card-header bg-white pb-0 pt-sm-0 pr-sm-0 header-elements-sm-inline">                
            <ul class="nav nav-tabs nav-tabs-bottom card-header-tabs mx-0 ml-0">
                <li class="nav-item font-weight-bold"><a @click.prevent="getAll" href="#" class="nav-link active" data-toggle="tab">Semua produk ({{Intl.NumberFormat('en-US').format(all)}})</a></li>
                <li class="nav-item font-weight-bold"><a @click.prevent="getAktif" href="#" class="nav-link" data-toggle="tab">Aktif ({{Intl.NumberFormat('en-US').format(aktif)}})</a></li>                    
                <li class="nav-item font-weight-bold"><a @click.prevent="getKosong" href="#" class="nav-link" data-toggle="tab">Stok Kosong ({{Intl.NumberFormat('en-US').format(kosong)}})</a></li>   
            </ul>    
            <div class="header-elements ml-2">
                
            </div>           
        </div>
        <div class="card-body">
            <div class="row">
                    <div class="col-md-5">
                        <form >
                            <div class="form-group mb-0 form-group-feedback form-group-feedback-right">
                                <input v-model="search" type="search" class="form-control" placeholder="Cari nama barang atau kode barang">
                                <div class="form-control-feedback">
                                    <i class="icon-search4 font-size-base text-muted"></i>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-7">
                        <div class="d-flex justify-content-between">
                            <div class="btn-group">
                                <button type="button" class="btn btn-light btn-labeled btn-labeled-left dropdown-toggle" data-toggle="dropdown"><b><i class="icon-filter3"></i></b> {{filter_title}}</button>
                                <div class="dropdown-menu dropdown-menu-right dropdown-scrollable">
                                    <a v-for="(kategori, i) in kategoris" :key="i" @click="toggleFilter(i)" href="#" class="dropdown-item" :class="[filter == kategori.nama_kategori ? 'active':'']">{{kategori.nama_kategori}} <i v-if="filter == kategori.nama_kategori" class="icon-checkmark2 ml-2"></i> </a>
                                </div>
                            </div>
                             <div>                                
                                <button @click="onAdd" type="button" class="btn bg-teal-400 btn-labeled btn-labeled-left" data-toggle="modal" data-target="#modal"><b><i class="icon-plus-circle2"></i></b>Tambah Barang</button>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <table class="table sortable">
            <thead>
                <tr>
                    <th @click="sortBy('kode_barang')" :class="[sortKey == 'kode_barang' ? 'active':'']" style="width:10%">Kode
                        <i v-if="sortKey == 'kode_barang'" :class="[sortOrder == 'asc' ? 'icon-arrow-up12':'icon-arrow-down12']"></i>
                        <i v-else class="icon-menu-open"></i>
                    </th>
                    <th @click="sortBy('nama_barang')" :class="[sortKey == 'nama_barang' ? 'active':'']" style="width:50%">Nama Barang
                        <i v-if="sortKey == 'nama_barang'" :class="[sortOrder == 'asc' ? 'icon-arrow-up12':'icon-arrow-down12']"></i>
                        <i v-else class="icon-menu-open"></i>
                    </th>
                    <th @click="sortBy('harga_jual')" class="text-right" :class="[sortKey == 'harga_jual' ? 'active':'']" style="width:15%">Harga Jual
                        <i v-if="sortKey == 'harga_jual'" :class="[sortOrder == 'asc' ? 'icon-arrow-up12':'icon-arrow-down12']"></i>
                        <i v-else class="icon-menu-open"></i>
                    </th>
                    <th @click="sortBy('stok')" class="text-right" :class="[sortKey == 'stok' ? 'active':'']" style="width:15%">Stok
                        <i v-if="sortKey == 'stok'" :class="[sortOrder == 'asc' ? 'icon-arrow-up12':'icon-arrow-down12']"></i>
                        <i v-else class="icon-menu-open"></i>
                    </th>
                    <th class="text-center not-sort" style="width:10%">Aksi</th>
                </tr>
            </thead>
            <tbody v-if="onLoad">
                <tr v-for="p in 10" :key="p">
                    <td class="ph-item">
                        <div class="alpha-slate p-1"></div>
                    </td>
                    <td class="ph-item">
                        <div class="alpha-slate p-1"></div>
                    </td>
                    <td class="ph-item">
                        <div class="alpha-slate p-1"></div>
                    </td>
                    <td class="ph-item">
                        <div class="alpha-slate p-1"></div>
                    </td>
                    <td>
                        <div class="d-flex justify-content-center ph-item">
                            <div class="alpha-slate p-2 col-1"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr v-for="(barang, i) in barangs" :key="i" :id="i" :class="[index==i && flash ? 'animated flash bg-success-400':'']">
                    <td>
                        {{barang.kode_barang}}
                    </td>
                    <td>
                        {{barang.nama_barang}}
                    </td>
                    <td class="text-right">
                        {{Intl.NumberFormat('en-US').format(barang.harga_jual)}}
                    </td>
                    <td class="text-right">
                        {{Intl.NumberFormat('en-US').format(barang.stok)}}
                    </td>
                    <td class="text-center">
                        <a @click.prevent="onEdit(i)" href="#" data-toggle="modal" data-target="#modal"><i class="icon-pencil5"></i></a>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="card-footer d-flex p-3 ">
            <div class="align-self-center">
                <span class="text-muted">Total barang: {{Intl.NumberFormat('en-US').format(page.total)}}</span>
            </div>
            <div class="ml-auto">
                <Paginate :pages="page" @get-page="getData($event)" />
            </div>
        </div>

        <form @submit.prevent="onSubmit" class="modal fade right" id="modal">
            <div class="modal-dialog vld-parent" style="width: 600px;">
                <Loading 
                    :color="'#26a69a'"
                    :loader="'dots'"
                    :active.sync="isLoading"
                    :is-full-page="false">
                </Loading>
                <div class="modal-content">
                    <div class="modal-header pb-3">
                        <h5 class="modal-title">{{modal_title}}</h5>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="">Nama Barang <span class="text-danger">*</span> </label>
                            <input v-model="form.nama_barang" @keydown="error.nama_barang=''" type="text" class="form-control" placeholder="Nama Barang">
                            <span v-if="error.nama_barang" class="form-text text-danger">{{error.nama_barang}}</span>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label>Pilih Kategori <span class="text-danger">*</span></label>
                                <select2 :options="kategori_option" v-model="form.kategori_id" @input="error.kategori_id=''" :placeholder="'Pilih Kategori'" />
                                <span v-if="error.kategori_id" class="form-text text-danger">{{error.kategori_id}}</span>
                            </div>
                            <div class="col-md-6">
                                <label>Pilih Merk <span class="text-danger">*</span></label>
                                <select2 :options="merk_options" v-model="form.merk_id" @input="error.merk_id=''" :placeholder="'Pilih Merk'" />
                                <span v-if="error.merk_id" class="form-text text-danger">{{error.merk_id}}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label>Harga Net <span class="text-danger">*</span></label>
                                <autonumeric 
                                    class="form-control text-right" 
                                    :placeholder="'Harga Net'"
                                    :options="{modifyValueOnWheel: false, decimalPlaces: 0}"
                                    v-model="form.harga_net"
                                    @keydown.native="error.harga_net=''"
                                    >
                                </autonumeric>
                                <span v-if="error.harga_net" class="form-text text-danger">{{error.harga_net}}</span>
                            </div>
                            <div class="col-md-6">
                                <label>Harga Jual <span class="text-danger">*</span></label>
                                <autonumeric 
                                    class="form-control text-right" 
                                    :placeholder="'Harga Jual'"
                                    :options="{modifyValueOnWheel: false, decimalPlaces: 0}"
                                    v-model="form.harga_jual"
                                    @keydown.native="error.harga_jual=''"
                                    >
                                </autonumeric>
                                <span v-if="error.harga_jual" class="form-text text-danger">{{error.harga_jual}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">BATAL</button>
                        <button type="submit" class="btn bg-teal" :disabled="isLoading">SIMPAN</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import $ from 'jquery'
import _ from 'lodash'
import axios from 'axios'
import Paginate from '../../components/Paginate'
import select2 from '../../components/Select2'
import autonumeric from '../../components/AutoNumeric'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    components: {
        Paginate,
        select2,
        autonumeric,
        Loading
    },
    data () {
        return {
            isLoading: false,
            price: '',
            onLoad: '',
            search:'',
            all: 0,
            aktif: 0,
            kosong: 0,
            kategoris: '',
            filter_title: 'Pilih Kategori',
            filter: '',
            find_kosong: '',
            find_aktif: '',
            page: '',
            current_page: '',
            sortKey: 'kode_barang',
            sortOrder: 'desc',
            kategori_option: [
                {id:'', text:''}
            ],
            merk_options: [
                {id:'', text:''}
            ],
            barangs: '',
            form: {
                nama_barang: '',
                kategori_id: '',
                merk_id: '',
                harga_beli: '',
                harga_net: '',
                harga_jual: ''
            },
            error: {
                nama_barang: '',
                kategori_id: '',
                merk_id: '',
                harga_net: '',
                harga_jual: ''
            },
            modal_mode: '',
            modal_title: '',
            flash: false,
            index: '',
        }
    },
    mounted () {
        this.getData()
        this.getkategoriMerk()
    },
    methods: {
        getkategoriMerk() {
            axios.get('/barang/kategorimerk')
            .then((response) => {
                this.kategoris = response.data.kategoris
                response.data.kategoris.forEach(ktg => {
                    this.kategori_option.push({id: ktg.id, text: ktg.nama_kategori})
                });
                response.data.merks.forEach(mrk => {
                    this.merk_options.push({id:mrk.id, text:mrk.nama_merk})
                });
            })
        },
        getData (e) {
            this.onLoad = true
            this.barangs=''
            $("html, body").animate({ scrollTop: "0" })
            axios.get('/barang', {
                params: {
                    page: e,
                    search: this.search,
                    sortkey: this.sortKey,
                    sortorder: this.sortOrder,
                    filter: this.filter,
                    find_kosong: this.find_kosong,
                    find_aktif: this.find_aktif
                }
            })
            .then((response) => {
                this.onLoad = false
                this.all = response.data.all
                this.aktif = response.data.aktif
                this.kosong = response.data.kosong
                this.page = response.data.data
                this.barangs = response.data.data.data
                this.current_page = response.data.data.current_page
                this.leaveFlash()
            })
        },
        onAdd () {
            this.modal_mode= 'new'
            this.modal_title = 'Tambah Barang Baru'
            this.form= {
                nama_barang: '',
                kategori_id: '',
                merk_id: '',
                harga_beli: '',
                harga_net: '',
                harga_jual: ''
            }
            this.error = {
                nama_barang: '',
                kategori_id: '',
                merk_id: '',
                harga_net: '',
                harga_jual: ''
            }
        },
        onEdit(i) {
            this.index = i
            this.flash = false
            this.modal_mode = 'edit',
            this.modal_title = 'Ubah Data Barang'
            this.form = {
                id: this.barangs[i].id,
                nama_barang: this.barangs[i].nama_barang,
                kategori_id: this.barangs[i].kategori_id,
                merk_id: this.barangs[i].merk_id,
                harga_net: this.barangs[i].harga_net,
                harga_jual: this.barangs[i].harga_jual,
            }
            this.error = {
                nama_barang: '',
                kategori_id: '',
                merk_id: '',
                harga_net: '',
                harga_jual: ''
            }

        },
        onSubmit () {
            if(!this.form.nama_barang) {
                this.error.nama_barang = 'Nama Barang Harus diisi'
            }
            if(!this.form.kategori_id) {
                this.error.kategori_id = 'Kategori Harus dipilih'
            }
            if(!this.form.merk_id) {
                this.error.merk_id = 'Merk Harus dipilih'
            }
            if(!this.form.harga_net) {
                this.error.harga_net = 'Harga net Harus diisi'
            }
            if(!this.form.harga_jual) {
                this.error.harga_jual = 'Harga jual Harus diisi'
            }
            if(this.form.nama_barang && this.form.kategori_id && this.form.merk_id && this.form.harga_net && this.form.harga_jual) {
                if(this.modal_mode=='new') {
                    this.postBarang()
                }
                if(this.modal_mode=='edit') {
                    this.updateBarang()
                }
            }
        },
        postBarang () {
            this.isLoading = true
            axios.post('/barang/create',this.form)
            .then(() => {
                $('#modal').modal('hide')
                this.isLoading = false
                this.getAll()
            })
        },
        updateBarang() {
            this.isLoading = true
            axios.post('/barang/update', this.form)
            .then(() => {
                $('#modal').modal('hide')
                this.getData(this.current_page)
                this.isLoading = false
                this.flash = true
                $("html, body").animate({ scrollTop: $('#'+this.index).offset().top - $(window).height()/2 })
            })
        },
        getAll () {
            this.sortKey = 'kode_barang'
            this.sortOrder = 'desc'
            this.filter = ''
            this.filter_title = 'Pilih Kategori'
            this.search = '',
            this.find_kosong = '',
            this.find_aktif = '',
            this.getData()
        },
        getAktif () {
            this.sortKey = 'kode_barang'
            this.sortOrder = 'desc'
            this.filter = ''
            this.filter_title = 'Pilih Kategori'
            this.search = '',
            this.find_kosong = ''
            this.find_aktif = 'ok'
            this.getData()
        },
        getKosong () {
            this.sortKey = 'kode_barang'
            this.sortOrder = 'desc'
            this.filter = ''
            this.filter_title = 'Pilih Kategori'
            this.search = '',
            this.find_kosong = 'ok'
            this.find_aktif = ''
            this.getData()
        },
        sortBy(key) {
            if(key == this.sortKey) {
                this.sortOrder = (this.sortOrder == 'desc') ? 'asc' : 'desc'
                this.getData()
            } else {
                this.sortKey = key
                this.sortOrder = 'desc'
                this.getData ()
            }
        },
        toggleFilter (i) {
            if(this.filter != this.kategoris[i].nama_kategori) {
                this.filter = this.kategoris[i].nama_kategori
                this.filter_title = this.kategoris[i].nama_kategori
            } else {
                this.filter = ''
                this.filter_title = 'Pilih Kategori'
            }
            this.getData()
        },
        searchData: _.debounce(function() {
            this.getData ()
        }, 500),
        leaveFlash: _.debounce(function() {
            this.flash = false
        }, 2000)        
    },
    watch: {
        search () {
            this.searchData()
        }
    }
}
</script>
<style  scoped>
    .sortable.table thead th:hover {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background: none;
        cursor: pointer;
        color:  #00B5AD;
    }
    .sortable.table thead th.not-sort:hover {
        cursor: context-menu;
        white-space: nowrap;
        color: rgba(0, 0, 0, 0.87);
    }
    .sortable.table th.active {
        color: #00B5AD !important;
    }
</style>