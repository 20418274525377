<template>
    <div>
        <div class="navbar navbar-expand-lg navbar-light navbar-component rounded">
            <div class="text-center d-lg-none w-100">
                <button type="button" class="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-filter">
                    <i class="icon-unfold mr-2"></i>
                    LAPORAN TOKO
                </button>
            </div>

            <div class="navbar-collapse collapse" id="navbar-filter">
                <span class="navbar-text font-weight-semibold mr-3">
                    LAPORAN TOKO
                </span>
                <ul class="navbar-nav flex-wrap">
                    <li class="nav-item">
                        <datepicker ref="datepicker" @date="onSelect" />
                    </li>
                </ul>
                <ul class="navbar-nav flex-wrap ml-auto"> 
                    <li class="nav-item ml-3">
                       <div class="btn-group">
                            <button type="button" class="btn btn-light btn-labeled btn-labeled-left dropdown-toggle" data-toggle="dropdown"><b><i class="icon-filter3"></i></b> {{filter_title}}</button>
                            <div class="dropdown-menu dropdown-menu-right dropdown-scrollable">
                                <a v-for="(toko, i) in tokos" :key="i" @click="toggleFilter(i)" href="#" class="dropdown-item" :class="[filter == toko.id ? 'active':'']">{{toko.name}} <i v-if="filter == toko.id" class="icon-checkmark2 ml-2"></i> </a>
                            </div>
                        </div>
                    </li>
                </ul>

            </div>
        </div>
        <template v-if="onLoad">
            <div class="row">
                <div class="col-sm-6 col-xl-3">
                    <div class="card card-body alpha-slate ph-item" style="height: 94px">
                    
                    </div>
                </div>
                <div class="col-sm-6 col-xl-3">
                    <div class="card card-body alpha-slate ph-item" style="height: 94px">
                    
                    </div>
                </div>
                <div class="col-sm-6 col-xl-3">
                    <div class="card card-body alpha-slate ph-item" style="height: 94px">
                    
                    </div>
                </div>
                <div class="col-sm-6 col-xl-3">
                    <div class="card card-body alpha-slate ph-item" style="height: 94px">
                    
                    </div>
                </div>
            </div>

            <div class="card card-body alpha-slate ph-item" style="height: 84px"></div>
            <div class="card card-body alpha-slate ph-item" style="height: 167px"></div>
        </template>

        <template v-if="onLoad == false">
            <div class="row">
                <div class="col-sm-6 col-xl-3">
                    <div class="card card-body bg-info-400 has-bg-image">
                        <div class="media">
                            <div class="mr-3 align-self-center">
                                <i class="icon-bag icon-3x opacity-75"></i>
                            </div>

                            <div class="media-body text-right">
                                <h3 class="mb-0">{{Intl.NumberFormat('en-US').format(pendapatanKotor)}}</h3>
                                <span class="text-uppercase font-size-xs">pendapatan kotor</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-xl-3">
                    <div class="card card-body bg-danger-400 has-bg-image">
                        <div class="media">
                            <div class="mr-3 align-self-center">
                                <i class="icon-stack-up icon-3x opacity-75"></i>
                            </div>

                            <div class="media-body text-right">
                                <h3 class="mb-0">{{Intl.NumberFormat('en-US').format(pengeluaran)}}</h3>
                                <span class="text-uppercase font-size-xs">pengeluaran</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-xl-3">
                    <div class="card card-body bg-success-400 has-bg-image">
                        <div class="media">
                            <div class="mr-3 align-self-center">
                                <i class="icon-stats-growth icon-3x opacity-75"></i>
                            </div>

                            <div class="media-body text-right">
                                <h3 class="mb-0">{{Intl.NumberFormat('en-US').format(pendapatanBersih)}}</h3>
                                <span class="text-uppercase font-size-xs">Pedapatan Bersih</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-xl-3">
                    <div class="card card-body bg-orange-400 has-bg-image">
                        <div class="media">
                            <div class="mr-3 align-self-center">
                                <i class="icon-stack3 icon-3x opacity-75"></i>
                            </div>

                            <div class="media-body text-right">
                                <h3 class="mb-0">{{Intl.NumberFormat('en-US').format(purchase)}}</h3>
                                <span class="text-uppercase font-size-xs">BARANG MASUK</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card card-body">
                <div class="row">
                    <div class="col-md-2 border-right text-center">
                        <h6 class="mb-0">CARA PEMBAYARAN</h6>
                        <span class="text-muted">
                            Penjualan dan Reseller
                        </span>
                    </div>
                    <div class="col-md-10">
                        <div class="d-flex justify-content-between align-items-center mr-1 ml-1">
                            <div class="text-right">
                                <h6 class="mb-0">TUNAI</h6>
                                <span class="text-muted">
                                    {{Intl.NumberFormat('en-US').format(tunai)}}
                                </span>
                            </div>
                            <div class="text-right">
                                <h6 class="mb-0">BCA</h6>
                                <span class="text-muted">
                                    {{Intl.NumberFormat('en-US').format(bca)}}
                                </span>
                            </div>
                            <div class="text-right">
                                <h6 class="mb-0">BNI</h6>
                                <span class="text-muted">
                                    {{Intl.NumberFormat('en-US').format(bni)}}
                                </span>
                            </div>
                            <div class="text-right">
                                <h6 class="mb-0">BRI</h6>
                                <span class="text-muted">
                                    {{Intl.NumberFormat('en-US').format(bri)}}
                                </span>
                            </div>
                            <div class="text-right">
                                <h6 class="mb-0">MANDIRI</h6>
                                <span class="text-muted">
                                    {{Intl.NumberFormat('en-US').format(mandiri)}}
                                </span>
                            </div>
                            <div class="text-right">
                                <h6 class="mb-0">TRANSFER</h6>
                                <span class="text-muted">
                                    {{Intl.NumberFormat('en-US').format(transfer)}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="card">
                <div class="row pl-2">
                    <div class="col-md-4 text-center pt-3 pb-3 border-right">
                        <h6 class="font-weight-semibold">Penjualan</h6>
                        <h3>{{Intl.NumberFormat('en-US').format(penjualanTotal)}}</h3>
                        <div class="row border-top">
                            <div class="col-md-6 border-right">
                                <h6 class="font-weight-semibold mb-0 mt-2">{{Intl.NumberFormat('en-US').format(penjualan.length)}}</h6>
                                <span class="text-muted">Transaksi</span>
                            </div>
                            <div class="col-md-6">
                                <h6 class="font-weight-semibold mb-0 mt-2">000</h6>
                                <span class="text-muted">Barang</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 text-center pt-3 pb-3 border-right">
                        <h6 class="font-weight-semibold">Ngoles Barang</h6>
                        <h3>{{Intl.NumberFormat('en-US').format(ngolesTotal)}}</h3>
                        <div class="row border-top">
                            <div class="col-md-6 border-right">
                                <h6 class="font-weight-semibold mb-0 mt-2 text-success">{{Intl.NumberFormat('en-US').format(ngolesBayar)}}</h6>
                                <span class="text-muted">Sudah Bayar</span>
                            </div>
                            <div class="col-md-6">
                                <h6 class="font-weight-semibold mb-0 mt-2 text-danger">{{Intl.NumberFormat('en-US').format(ngolesBelumBayar)}}</h6>
                                <span class="text-muted">Belum Bayar</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 text-center pt-3 pb-3">
                        <h6 class="font-weight-semibold">Reseller</h6>
                        <h3>{{Intl.NumberFormat('en-US').format(resellerTotal)}}</h3>
                        <div class="row border-top">
                            <div class="col-md-6 border-right">
                                <h6 class="font-weight-semibold mb-0 mt-2 text-success">{{Intl.NumberFormat('en-US').format(resellerBayar)}}</h6>
                                <span class="text-muted">Sudah Bayar</span>
                            </div>
                            <div class="col-md-6">
                                <h6 class="font-weight-semibold mb-0 mt-2 text-danger">{{Intl.NumberFormat('en-US').format(resellerSisa)}}</h6>
                                <span class="text-muted">Belum Bayar</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="card">
            <h6 class="p-3 mb-0">DATA PENGELUARAN</h6>
            <table class="table">
                <thead class="bg-danger">
                    <tr>
                        <th>Tangal</th>
                        <th>Keterangan</th>
                        <th class="text-right">harga/Jumlah</th>
                        <th class="text-right">Banyaknya</th>
                        <th class="text-right">Total</th>
                    </tr>
                </thead>
                <tbody v-if="onLoad">
                    <tr v-for="p in 10" :key="p">
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td><div class="alpha-slate p-2 ph-item"></div></td>
                    </tr>
                </tbody>
                <tbody>
                    <tr v-for="(item, i) in pengeluarans" :key="i">
                        <td>{{item.created_at}}</td>
                        <td>{{item.keterangan}}</td>
                        <td  class="text-right">{{Intl.NumberFormat('en-US').format(item.harga)}}</td>
                        <td  class="text-right">{{item.qty}}</td>
                        <td  class="text-right">{{Intl.NumberFormat('en-US').format(item.total)}}</td>
                    </tr>
                </tbody>
            </table> 
            <div class="card-footer d-flex p-3 ">
                <div class="align-self-center">
                    <span class="text-muted">Total invoice: {{Intl.NumberFormat('en-US').format(page.total)}}</span>
                </div>
                <div class="ml-auto">
                    <Paginate :pages="page" @get-page="getPage($event)" />
                </div>
            </div>           
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import datepicker from '../../components/Datepicker'
import Paginate from '../../components/Paginate'
import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
    components: {
        datepicker,
        Paginate
    },
    data () {
        return {
            onLoad: false,
            penjualan: [],
            pengeluaran: '',
            purchase: '',
            ngoles: [],
            reseller: [],
            reseller_bayars: [],
            pengeluarans: '',
            page: '',
            tokos: '',
            filter_title: 'Semua Toko',
            filter: '',
        }
    },
    computed: {
        ...mapGetters({
            toko: 'auth/toko'
        }),
        
        reseller_bayar () {
            let data = []
            if(this.filter) {
                this.reseller.forEach(el => {
                    data.push(...this.reseller_bayars.filter(rb => rb.reseller_id == el.id))
                })
            } else {
                data = this.reseller_bayars
            }

            return data
        },
        tunai () {
            let data = ''
            let sum = 0
            data = this.penjualan.filter(el => {
                return el.cara_bayar.includes('TUNAI')
            })
            
            data.forEach(el => {
                sum += el.total
            });

            let d = ''
            let s = 0
            d = this.reseller_bayar.filter(el => {
                return el.cara_bayar.includes('TUNAI')
            })
            
            d.forEach(el => {
                s += el.bayar
            });
            
            return parseInt(sum + s)
        },
        transfer () {
            let data = ''
            let sum = 0
            data = this.penjualan.filter(el => {
                return el.cara_bayar.includes('TRANSFER')
            })
            
            data.forEach(el => {
                sum += el.total
            });

            let d = ''
            let s = 0
            d = this.reseller_bayar.filter(el => {
                return el.cara_bayar.includes('TRANSFER')
            })
            
            d.forEach(el => {
                s += el.bayar
            });
            
            return parseInt(sum + s)
        },
        debit () {
            let data = ''
            data = this.penjualan
                .filter(el => el.cara_bayar.includes('DEBIT/KREDIT')
            )
            return data
        },
        debitSeller () {
            let data = ''
            data = this.reseller_bayar.filter(el => el.cara_bayar.includes('DEBIT/KREDIT'))
            return data
        },
        bca () {
            let data = ''
            let sum = 0
            data = this.debit.filter(el => {
                return el.bank.includes('BCA')
            })
            
            data.forEach(el => {
                sum += el.total
            });

            let d = ''
            let s  = 0

            d = this.debitSeller.filter(el => {
                return el.bank.includes('BCA')
            })

            d.forEach(el => {
                s += el.bayar
            })
            return parseInt(sum + s)
        },
        bni () {
            let data = ''
            let sum = 0
            data = this.debit.filter(el => {
                return el.bank.includes('BNI')
            })
            
            data.forEach(el => {
                sum += el.total
            });

            let d = ''
            let s  = 0

            d = this.debitSeller.filter(el => {
                return el.bank.includes('BNI')
            })

            d.forEach(el => {
                s += el.bayar
            })
            return parseInt(sum + s)
        },
        bri () {
            let data = ''
            let sum = 0
            data = this.debit.filter(el => {
                return el.bank.includes('BRI')
            })
            
            data.forEach(el => {
                sum += el.total
            });

            let d = ''
            let s  = 0

            d = this.debitSeller.filter(el => {
                return el.bank.includes('BRI')
            })

            d.forEach(el => {
                s += el.bayar
            })
            return parseInt(sum + s)
        },
        mandiri () {
            let data = ''
            let sum = 0
            data = this.debit.filter(el => {
                return el.bank.toLowerCase().includes('mandiri')
            })
            
            data.forEach(el => {
                sum += el.total
            });

            let d = ''
            let s  = 0

            d = this.debitSeller.filter(el => {
                return el.bank.toLowerCase().includes('mandiri')
            })

            d.forEach(el => {
                s += el.bayar
            })
            return parseInt(sum + s)
        },
        penjualanTotal() {
            let sum = 0;
            this.penjualan.forEach(el => {
                sum += el.total
            });
            return sum
        },
        ngolesTotal () {
            let sum = 0
            this.ngoles.forEach(el => {
                sum += el.total
            });

            return sum
        },
        ngolesBayar () {
            let data = ''
            let sum = 0;
            data = this.ngoles.filter(el => el.status.includes('LUNAS'))
            data.forEach(el => {
                sum += el.total
            });
            return sum
        },
        ngolesBelumBayar () {
            let data = ''
            let sum = 0;
            data = this.ngoles.filter(el => el.status.includes('BELUM BAYAR'))
            data.forEach(el => {
                sum += el.total
            });
            return sum
        },
        resellerTotal () {
            let sum = 0
            this.reseller.forEach(el => {
                sum += el.total
            });
            return sum
        },
        resellerBayar () {
            let sum = 0
            this.reseller.forEach(el => {
                sum += el.bayar
            });

            return sum
        },
        resellerSisa () {
            let sum = 0
            this.reseller.forEach(el => {
                sum += el.sisa
            });

            return sum
        },
        pendapatanKotor () {
            let sum = 0;
            sum = parseInt(this.penjualanTotal + this.ngolesTotal + this.resellerTotal)
            return sum
        },
        pendapatanBersih () {
            let sum = 0
            sum = parseInt(this.pendapatanKotor - this.pengeluaran)
            return sum
        },
        date () {
            let d = {
                date: '',
                label: '',
            }
            d.date = this.$refs.datepicker.date
            d.label = this.$refs.datepicker.date_label
            return d
        }
    },
    mounted () {
        this.filter_title = this.toko.name
        this.filter = this.toko.id
        this.getToko()
    },
    methods: {
        getData (e) {
            this.onLoad = true
            this.pengeluarans = ''
            axios.get('/report', {
                params: {
                    page: e,
                    date: this.date.date,
                    label: this.date.label,
                    toko_id: this.filter
                }
            })
            .then((response) => {
                this.onLoad = false
                this.penjualan = response.data.pendapatan
                this.pengeluaran = response.data.pengeluaran
                this.purchase = response.data.purchase
                this.ngoles = response.data.ngoles
                this.reseller = response.data.reseller
                this.reseller_bayars = response.data.reseller_bayar
                this.pengeluarans = response.data.pengeluarans.data
                this.page = response.data.pengeluarans
            })
        },
        getToko () {
            axios.get('/store')
            .then((response) => {
                this.tokos = response.data
            })
        },
        getPage(e) {
            $("html, body").animate({ scrollTop: "600" })
            this.getData(e)
        },
        onSelect () {
            $("html, body").animate({ scrollTop: "0" })
            this.getData()
        },
        toggleFilter (i) {
            if(this.filter != this.tokos[i].id) {
                this.filter = this.tokos[i].id
                this.filter_title = this.tokos[i].name
            } else {
                this.filter = ''
                this.filter_title = 'Semua Toko'
            }
            this.getData()
        },
    },
}
</script>