<template>
    <div class="vld-parent">
        <Loading 
            :color="'#26a69a'"
            :loader="'spinner'"
            :active.sync="isLoading"
            :is-full-page="false">
        </Loading>
        <div v-if="onLoad" class="row">
            <div class="col-md-8">
                <div class="card card-body">
                    <div class="row">
                        <div class="col-md-6 border-right">
                            <div class="media">
                                <div class="mr-3 align-self-center ph-item">
                                    <i class="icon-stack-check icon-3x text-muted"></i>
                                </div>

                                <div class="media-body text-left">
                                    <div class="alpha-slate p-2 ph-item mb-1"></div>
                                    <div class="alpha-slate p-1 ph-item col-3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="media">
                                <div class="mr-3 align-self-center ph-item">
                                    <i class="icon-file-spreadsheet icon-3x text-muted"></i>
                                </div>

                                <div class="media-body text-left">
                                    <div class="alpha-slate p-2 ph-item mb-1 col-7"></div>
                                    <div class="alpha-slate p-1 ph-item col-10"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-body bg-danger has-bg-image">
                    <div class="media">
                        <div class="mr-3 align-self-center">
                            <i class="icon-calculator2 icon-3x opacity-75"></i>
                        </div>

                        <div class="media-body text-right">
                            <h4 class="font-weight-semibold mb-0">{{Intl.NumberFormat('en-US').format(total)}}</h4>
                            <span class="text-uppercase font-size-sm">TOTAL ITEM</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="stockopname" class="row">
            <div class="col-md-8">
                <div class="card card-body">
                    <div class="row">
                        <div class="col-md-6 border-right">
                            <div class="media">
                                <div class="mr-3 align-self-center">
                                    <i class="icon-stack-check icon-3x"></i>
                                </div>

                                <div class="media-body text-left">
                                    <h5 class="font-weight-semibold mb-0">STOK OPNAME</h5>
                                    <span class="text-uppercase font-size-sm text-muted">Entry Data</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="media">
                                <div class="mr-3 align-self-center">
                                    <i class="icon-file-spreadsheet icon-3x text-info-400"></i>
                                </div>

                                <div class="media-body text-left">
                                    <h4 class="font-weight-semibold mb-0">{{stockopname.opname}}</h4>
                                    <span class="text-uppercase font-size-sm text-muted">TANGGAL {{stockopname.created_at}} </span> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-body bg-danger has-bg-image">
                    <div class="media">
                        <div class="mr-3 align-self-center">
                            <i class="icon-calculator2 icon-3x opacity-75"></i>
                        </div>

                        <div class="media-body text-right">
                            <h4 class="font-weight-semibold mb-0">{{Intl.NumberFormat('en-US').format(total)}}</h4>
                            <span class="text-uppercase font-size-sm">total item</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <div class="form-group row mb-0">
                    <div class="col-md-2">
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <span class="input-group-text">AR</span>
                            </span>
                            <input ref="sku" v-model="sku" @input="getSKU" type="text" class="form-control" placeholder="Kode Barang" maxlength="4">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <input v-model="barang.nama_barang" type="text" class="form-control" disabled placeholder="Nama Barang">
                    </div>
                    <div class="col-md-2">
                        <input v-model="barang.stok" type="text" class="text-right form-control" disabled placeholder="stok lama">
                    </div>
                    <div class="col-md-2">
                        <autonumeric 
                            ref="qty"
                            v-model="qty"
                            class="form-control text-right" 
                            :placeholder="'qty'"
                            :options="{modifyValueOnWheel: false, decimalPlaces: 0}"
                            @keydown.enter.native="onEnterQty"
                            >
                        </autonumeric>
                    </div>
                </div>
            </div>
            <table class="table">
                <thead class="bg-info">
                    <tr>
                        <th>KODE BARANG</th>
                        <th>NAMA BARANG</th>
                        <th class="text-right">STOK LAMA</th>
                        <th class="text-right">STOK BARU</th>
                        <th class="text-right">SELISIH</th>
                        <th class="text-center">Aksi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(barang, i) in opnames.slice().reverse()" :key="i" :class="[i == 0 && fadeIn? 'animated fadeIn':'']">
                        <td>{{barang.kode_barang}}</td>
                        <td>{{barang.nama_barang}}</td>
                        <td class="text-right">{{Intl.NumberFormat('en-US').format(barang.stok_lama)}}</td>
                        <td class="text-right">{{barang.stok_baru}}</td>
                        <td class="text-right">{{barang.selisih}}</td>
                        <td class="text-center">
                            <a @click.prevent="onDelete(barang.index)" href="#"><i class="icon-trash text-danger"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="card-footer">
                <div class="text-right">
                    <button @click="onBatal" class="btn btn-light">BATAL</button>
                    <button @click="onSimpan" class="btn btn-primary ml-1" :disabled="opnames.length == 0 || isLoading && opnames.length >= 1">SIMPAN</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import autonumeric from '../../components/AutoNumeric'
import swalInit from '../../sweetalert'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    components: {
        autonumeric,
        Loading
    },
    data () {
        return {
            status: false,
            onLoad: false,
            isLoading: false,
            stockopname: '',
            sku: '',
            qty: '',
            barang: {
                id: '',
                kode_barang: '',
                nama_barang: '',
                stok: '0'
            },
            opnames: [],
            fadeIn: false
        }
    },
    mounted () {
        this.$refs.sku.focus()
        let id = this.$route.params.id
        this.getData(id);
    },
    computed: {
        total () {
            return this.opnames.length
        }
    },
    methods: {
        getData (id) {
            this.onLoad = true
            axios.get('/stockopname/edit/'+id)            
            .then((response) => {
                let i = 0
                this.onLoad = false
                this.stockopname = response.data.data
                response.data.details.forEach(el => {
                    this.opnames.push({
                        index: i+=1,
                        barang_id: el.barang_id,
                        kode_barang: el.kode_barang,
                        nama_barang: el.nama_barang,
                        stok_lama: el.stok_lama,
                        stok_baru: el.stok_baru,
                        selisih: el.selisih
                    })
                });
            })
        },
        getSKU () {
            this.barang = {
                id: '',
                kode_barang: '',
                nama_barang: '',
                harga_beli: 'harga beli'
            }
            this.new_harga_beli = ''
            this.qty = ''
            this.fadeIn = false
            if(this.sku.length==4) {
                axios.get('/barang/search/AR'+this.sku)
                .then((response) => {
                    if(response.data.error) {
                        swalInit.fire({
                            title: 'AR'+this.sku,
                            text: response.data.error,
                            type: 'error',
                            confirmButtonClass: 'btn btn-primary',
                            buttonsStyling: false,
                        })
                        .then(()=> {
                            this.$refs.sku.focus()
                        }) 
                    } else {
                        this.barang.id = response.data.id
                        this.barang.nama_barang = response.data.nama_barang
                        this.barang.kode_barang = response.data.kode_barang
                        this.barang.stok = response.data.stok
                        this.$refs.qty.$el.focus()
                    }
                })
            }
        },
        onEnterQty () {
            if(this.qty >= 0) {
                let i = this.opnames.length

                this.fadeIn = true
                this.opnames.push({
                    index: i+1,
                    barang_id: this.barang.id,
                    kode_barang: this.barang.kode_barang,
                    nama_barang: this.barang.nama_barang,
                    stok_lama: this.barang.stok,
                    stok_baru: this.qty,
                    selisih: parseInt(this.qty - this.barang.stok)
                })
                this.$nextTick(() => {
                    this.barang = {
                        id: '',
                        kode_barang: '',
                        nama_barang: '',
                        stok: 'stok lama'
                    }
                    this.sku = ''
                    this.new_harga_beli = ''
                    this.qty = ''
                    this.$refs.sku.focus()
                })
            }
        },
        onDelete (index) {
            let i = this.opnames.map(item => item.index).indexOf(index)
            this.opnames.splice(i, 1)
        },
        onSimpan () {
            this.isLoading = true
            axios.post('/stockopname/update', {
                id: this.$route.params.id,
                details: this.opnames
            })
            .then(() => {
                this.status = true
                this.isLoading = false
                this.$router.push('/products/stockopnames')
            })
        },
        onBatal () {
            this.status = true
            this.$router.push('/products/stockopnames')
        },
    },
    beforeRouteLeave(to, from, next) {
        if(this.status) {
            next()
        } else {
            swalInit.fire({
                title: 'BELUM DI SIMPAN',
                text: 'Apakah anda ingin menyipan data ini ?',
                type:'question',
                showCancelButton: true,
                confirmButtonText: 'Ya Simpan!',
                cancelButtonText: 'Tidak',
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                buttonsStyling: false,
                allowOutsideClick: false,
            })
            .then((result)=>{
                if(result.value) {
                    this.onSimpan()
                } else {
                    this.status = true
                   this.$router.push('/products/stockopnames')
                }
            })
        }
    }
}
</script>