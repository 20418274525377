<template>
    <div>
        <div class="card">
            <div class="card-header bg-white header-elements-inline">
                <h6 class="card-title">KATEGORI</h6>
                <div class="header-elements">
                    <button @click="onAdd" type="button" class="btn bg-teal-400 btn-labeled btn-labeled-left" data-toggle="modal" data-target="#modal"><b><i class="icon-plus-circle2"></i></b>Tambah Kategori</button>
                </div>
            </div>
            <table class="table">
                <thead class="bg-slate">
                    <tr>
                        <th style="width:30%;">Kategori</th>
                        <th style="width:60%;">Keterangan</th>
                        <th class="text-center" style="width:10%;">Aksi</th>
                    </tr>
                </thead>
                <tbody v-if="onLoad">
                    <tr v-for="i in 10" :key="i">
                        <td>
                            <div class="alpha-slate p-1 ph-item"></div>
                        </td>
                        <td>
                            <div class="alpha-slate p-1 ph-item"></div>
                        </td>
                        <td>
                            <div class="d-flex justify-content-center">
                                <div class="alpha-slate p-2 col-1 ph-item"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr v-for="(kategori, i) in kategoris" :key="i" :id="i" :class="index == i && flash?'animated flash bg-success':''">
                        <td>{{kategori.nama_kategori}}</td>
                        <td>{{kategori.keterangan}}</td>
                        <td class="text-center">
                            <a @click.prevent="onEdit(i)" href="#" data-toggle="modal" data-target="#modal"><i class="icon-pencil5"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <form @submit.prevent="onSubmit" class="modal fade right" id="modal">
            <div class="modal-dialog vld-parent">
                <Loading 
                    :color="'#26a69a'"
                    :loader="'dots'"
                    :active.sync="isLoading"
                    :is-full-page="false">
                </Loading>
                <div class="modal-content">
                    <div class="modal-header pb-3">
                        <h5 class="modal-title">{{modal_title}}</h5>
                    </div>
                    <div class="modal-body">
                        <fieldset :disabled="isLoading">
                            <div class="form-group">
                                <label>Nama Kategori <span class="text-danger">*</span></label>
                                <input v-model="form.nama_kategori" type="text" class="form-control" @keydown="error.nama_kategori=''" placeholder="Nama Kategori">
                                <span v-if="error.nama_kategori" class="form-text text-danger">{{error.nama_kategori}}</span>
                            </div>
                            <div class="form-group">
                                <label>Keterangan</label>
                                <textarea v-model="form.keterangan" rows="5" class="form-control" placeholder="Keterangan"></textarea>
                            </div>
                        </fieldset>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">BATAL</button>
                        <button type="submit" class="btn bg-teal" :disabled="isLoading">SIMPAN</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import $ from 'jquery'
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import _ from 'lodash'
export default {
    components: {
        Loading
    },
    data () {
        return {
            onLoad: false,
            isLoading: false,
            kategoris: '',
            modal_mode: '',
            modal_title: '',
            index: '',
            flash: '',
            form: {
                nama_kategori: '',
                keterangan: '',
            },
            error: {
                nama_kategori: '',
            }
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.onLoad = true
            this.kategoris = ''
            axios.get('/kategori')
            .then((response) => {
                this.onLoad = false
                this.kategoris = response.data
                this.leaveFlash()
                if(this.index) {
                    this.$nextTick(() => {
                        $("html, body").animate({ scrollTop: $('#'+this.index).offset().top - $(window).height()/2 })
                    })
                }
            })
        },
        onAdd () {
            this.modal_mode = 'new'
            this.modal_title = 'Tambah Kategori Baru'
            this.form= {
                nama_kategori: '',
                keterangan: '',
            }
            this.error= {
                nama_kategori: '',
            }
        },
        onEdit(i) {
            this.index = i
            this.modal_mode = 'edit'
            this.modal_title = 'Ubah Data Kategori'
            this.form = {
                id: this.kategoris[i].id,
                nama_kategori: this.kategoris[i].nama_kategori,
                keterangan: this.kategoris[i].keterangan
            }
            this.error.nama_kategori = ''
        },
        onSubmit () {
            if(!this.form.nama_kategori) {
                this.error.nama_kategori = 'Nama Kategori harus diisi!!'
            }
            if(this.form.nama_kategori) {
                this.isLoading = true
                if(this.modal_mode == 'new') {
                    this.postKategori()
                }
                if(this.modal_mode == 'edit') {
                    this.updateKategori()
                }
            }
        },
        postKategori () {
            axios.post('/kategori/create', this.form)
            .then((response) => {
                if(response.data.error) {
                    this.isLoading = false
                    this.error.nama_kategori = response.data.error                    
                } else {
                    $('#modal').modal('hide')
                    this.isLoading = false
                    this.getData()
                }
            })
        },
        updateKategori () {
            axios.post('/kategori/update', this.form)
            .then((response) => {
                if(response.data.error) {
                    this.isLoading = false
                    this.error.nama_kategori = response.data.error
                } else {
                    this.getData()
                    $('#modal').modal('hide')
                    this.isLoading = false
                    this.flash = true
                }
               
            })
        },
        leaveFlash: _.debounce( function() {
            this.flash = false
        }, 2000)
    }
}
</script>