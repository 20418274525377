<template>
    <div>
        <div style="position: relative">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">LAPORAN BARANG BULAN INI</h6>
            </div>
        </div>
        <div class="alert alert-info alert-styled-left alert-dismissible mt-2">
            <button type="button" class="close" data-dismiss="alert"><span>&times;</span></button>
            <span class="font-weight-semibold">Barang masuk & Barang Terjual</span> Berdasakan Nilai Harga Net barang & quantity.
        </div>
        <div v-if="onLoad" class="row mt-3">
            <div v-for="p in 3" :key="p" class="col-md-4">
                <div class="card card-body alpha-slate ph-item" style="height:138px"></div>
            </div>
        </div>
        <div v-if="onLoad == false" class="row mt-3">
            <div class="col-md-4">
                <div class="card card-body">
                    <h6 class="mb-0 font-weight-semibold">BARANG MASUK</h6>
                    <div class="media">
                        <div class="mr-3 align-self-center">
                            <i class="icon-stack-down icon-3x opacity-75 text-primary"></i>
                        </div>

                        <div class="media-body text-right">
                            <h3 class="mb-0">{{Intl.NumberFormat('en-US').format(totalMasuk)}}</h3>
                            <div class="text-uppercase font-size-lg">QTY : {{Intl.NumberFormat('en-US').format(totalQtyMasuk)}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-body">
                    <h6 class="mb-0 font-weight-semibold">BARANG TERJUAL</h6>
                    <div class="media">
                        <div class="mr-3 align-self-center">
                            <i class="icon-stack-up icon-3x opacity-75 text-danger"></i>
                        </div>

                        <div class="media-body text-right">
                            <h3 class="mb-0">{{Intl.NumberFormat('en-US').format(totalTerjual)}}</h3>
                            <div class="text-uppercase font-size-lg">QTY : {{Intl.NumberFormat('en-US').format(totalQtyTerjual)}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-body">
                    <h6 class="mb-0 font-weight-semibold">BARANG TERSEDIA</h6>
                    <div class="media">
                        <div class="mr-3 align-self-center">
                            <i class="icon-stack-check icon-3x opacity-75 text-success"></i>
                        </div>

                        <div class="media-body text-right">
                            <h3 class="mb-0">{{Intl.NumberFormat('en-US').format(totalBarang)}}</h3>
                            <div class="text-uppercase font-size-lg">STOK : {{Intl.NumberFormat('en-US').format(totalQtyBarang)}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <h6 class="mb-0">BARANG MASUK</h6>
                    </div>
                    <table class="table">
                        <thead class="bg-primary">
                            <tr>
                                <th>Kode Barang</th>
                                <th>Nama Barang</th>
                                <th class="text-right">QTY</th>
                                <th class="text-right">Nilai Net</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, i) in masuks" :key="i">
                                <td>{{item.kode_barang}}</td>
                                <td>{{item.nama_barang}}</td>
                                <td class="text-right">{{item.qty}}</td>
                                <td class="text-right">{{Intl.NumberFormat('en-US').format(parseInt(item.qty * item.harga_net))}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <h6 class="mb-0">BARANG TERJUAL</h6>
                    </div>
                    <table class="table">
                        <thead class="bg-primary">
                            <tr>
                                <th>Kode Barang</th>
                                <th>Nama Barang</th>
                                <th class="text-right">QTY</th>
                                <th class="text-right">Nilai Net</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, i) in terjuals" :key="i">
                                <td>{{item.kode_barang}}</td>
                                <td>{{item.nama_barang}}</td>
                                <td class="text-right">{{item.qty}}</td>
                                <td class="text-right">{{Intl.NumberFormat('en-US').format(parseInt(item.qty * item.harga_net))}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data () {
        return {
            onLoad: false,
            masuks: [],
            terjuals: [],
            barangs: [],
        }
    },
    mounted() {
        this.getData()
    },
    computed: {
        totalBarang () {
            let sum = 0
            this.barangs.forEach(el => {
                sum += parseInt(el.stok * el.harga_net)
            })
            return sum
        },
        totalQtyBarang () {
            let sum = 0
            this.barangs.forEach(el => {
                sum += parseInt(el.stok)
            })
            return sum
        },
        totalMasuk () {
            let sum = 0
            this.masuks.forEach(el => {
                sum += parseInt(el.qty * el.harga_net)
            })
            return sum
        },
        totalQtyMasuk () {
            let sum = 0
            this.masuks.forEach(el => {
                sum += parseInt(el.qty)
            })
            return sum
        },
        totalTerjual () {
            let sum = 0
            this.terjuals.forEach(el => {
                sum += parseInt(el.qty * el.harga_net)
            })
            return sum
        },
        totalQtyTerjual () {
            let sum = 0
            this.terjuals.forEach(el => {
                sum += parseInt(el.qty)
            })
            return sum
        }
    },
    methods: {
        getData () {
            this.onLoad = true
            axios.get('/report/barang')
            .then((response) => {
                this.onLoad = false
                this.masuks = response.data.masuks
                this.terjuals = response.data.terjuals
                this.barangs = response.data.barangs
            })
        }
    },
}
</script>