<template>
    <div>
        <legend>EDIT PROFILE</legend>
        <form @submit.prevent="onSubmit" class="vld-parent">
            <Loading 
                :color="'#26a69a'"
                :active.sync="isLoading"
                :is-full-page="false">
            </Loading>
            <div class="row">
                <div class="col-md-6">
                    <label for="name">Name</label>
                    <div class="form-group form-group-feedback form-group-feedback-left">
                        <input v-model="user.name" type="text" name="name" class="form-control">
                        <div class="form-control-feedback form-control-feedback-lg">
                            <i class="icon-user"></i>
                        </div>
                        <span class="form-text text-danger">{{error.name}}</span>
                    </div>                    
                </div>
                <div class="col-md-6">
                    <label for="phone">Mobile</label>
                    <div class="form-group form-group-feedback form-group-feedback-left">
                        <input v-model="user.phone" type="text" name="phone" class="form-control" placeholder="Mobile Number" maxlength="15">
                        <div class="form-control-feedback form-control-feedback-lg">
                            <i class="icon-mobile"></i>
                        </div>
                    </div>                     
                </div>
            </div>
            <div class="form-group">
                <label for="address">Address</label>
                <div class="form-group form-group-feedback form-group-feedback-left">
                    <input v-model="user.address" @keydown.prevent.tab="onTabAddress" type="text" name="address" class="form-control" placeholder="Address">
                    <div class="form-control-feedback form-control-feedback-lg">
                        <i class="icon-pin-alt"></i>
                    </div>
                </div> 
            </div>
            <div class="form-group row">
                <div class="col-md-6">
                    <label for="province">Province</label>
                    <select name="province" id="province">
                        <option value=""></option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label for="regency">Regency</label>
                    <select name="regency" id="regency">
                        <option value=""></option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-6">
                    <label for="district">District</label>
                    <select name="district" id="district">
                        <option value=""></option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label for="zip">ZIP</label>
                    <input v-model="user.zip" type="text" class="form-control" placeholder="Postal code">
                </div>
            </div>
            <div class="text-right">
                <button type="submit" class="btn btn-primary">SAVE CHANGE</button>
            </div>
        </form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import $ from 'jquery'
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
require('select2')
export default {
    components: {
        Loading
    },
    data () {
        return {
            isLoading: false,
            user: {
                name: '',
                phone: '',
                address: '',
                province_id: '',
                regency_id: '',
                district_id: '',
                zip: ''
            },
            error: {
                name:''
            }
        }
    },
    mounted () {
        let app = this
        this.getData()
        $('#province').select2({
            width: '100%',
            placeholder: 'Select Province'
        })
        $('#regency').select2({
            width: '100%',
            placeholder: 'Select Regency'
        })
        $('#district').select2({
            width: '100%',
            placeholder: 'Select Regency'
        })
        $('#province').on('select2:select', () => {
            var id = $('#province').val()
            app.user.province_id = id
            $('#regency').empty()
            $('#regency').append('<option value=""></option>')
            $('#district').empty()
            $('#district').append('<option value=""></option>')
            app.getRegency(id)
        })
        $('#regency').on('select2:select', () => {
            var id = $('#regency').val()
            app.user.regency_id = id
            $('#district').empty()
            $('#district').append('<option value=""></option>')
            app.getDistrict(id)
        })
        $('#district').on('select2:select', () => {
            var id = $('#district').val()
            app.user.district_id = id
        })
    },
    computed: {
        ...mapGetters({
            userData: 'auth/user'
        })
    },
    methods: {
        async getData () {
            this.isLoading =true
            this.user.name = this.userData.name
            if(this.userData.contact) {
                this.user.phone = this.userData.contact.phone
                this.user.address = this.userData.contact.address
                this.user.province_id = this.userData.contact.province_id
                this.user.regency_id = this.userData.contact.regency_id
                this.user.district_id = this.userData.contact.district_id
                this.user.zip = this.userData.contact.zip
            }
            await axios.get('/province')
            .then(response => {
                this.isLoading = false
                for (let i = 0; i < response.data.length; i++) {
                    $('#province').append('<option value="'+response.data[i].id+'">'+response.data[i].name+'</option>')
                } 
            })

            if(this.user.province_id && this.user.regency_id && this.user.district_id) {
                this.isLoading = true
                $('#province').val(this.user.province_id)
                .trigger('change')

                await axios.get('/regency/'+this.user.province_id)
                .then((response) => {
                    for (let i = 0; i < response.data.length; i++) {
                        $('#regency').append('<option value="'+response.data[i].id+'">'+response.data[i].name+'</option>')
                    } 

                    $('#regency')
                    .val(this.user.regency_id)
                    .trigger('change')
                }) 
                
                await axios.get('/district/'+this.user.regency_id)
                .then(response => {
                    this.isLoading = false
                    for (let i = 0; i < response.data.length; i++) {
                        $('#district').append('<option value="'+response.data[i].id+'">'+response.data[i].name+'</option>')
                    }
                    $('#district').val(this.user.district_id).trigger('change')
                })
            }

        },
        onTabAddress () {
            $('#province').select2('open')
        },
        getRegency(id) {
            this.isLoading = true
            axios.get('/regency/'+id)
            .then((response) => {
               for (let i = 0; i < response.data.length; i++) {
                    $('#regency').append('<option value="'+response.data[i].id+'">'+response.data[i].name+'</option>')
                } 
                this.isLoading = false
                $('#regency').select2('open')
            })
        },
        getDistrict (id) {
            this.isLoading = true
            axios.get('/district/'+id)
            .then((response) => {
               for (let i = 0; i < response.data.length; i++) {
                    $('#district').append('<option value="'+response.data[i].id+'">'+response.data[i].name+'</option>')
                } 
                this.isLoading = false
                $('#district').select2('open')
            })
        },
        onSubmit () {
            if(!this.user.name) {
                this.error.name = 'The filed name is required'
            }
            if(this.user.name) {
                this.onUpdate()
            }
        },
        onUpdate () {
            this.isLoading = true
            axios.post('/profile/update',this.user)
            .then(() => {
                this.isLoading = false
                this.$store.dispatch('auth/attempt', localStorage.getItem('token'))
                this.$toasted.success('Successfully updated',{
                    position: 'top-center',
                    iconPack: 'custom-class',
                    icon: 'icon-info22 mr-2',
                    duration: '1500',
                    action : {
                        text : 'Close',
                        onClick : (e, toastObject) => {
                            toastObject.goAway(0);
                        }
                    },
                })
            })
        }
    }


}
</script>