
try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    require('./assets/js/widgets.min.js')
    require('bootstrap');
    require('./assets/js/uniform');
    require('./assets/js/datepicker')
    require('./assets/js/datepicker.id')
    
} catch (e) {'error'} 