<template>
    <div>
        <div v-if="menu" class="navbar navbar-expand-lg navbar-light navbar-component rounded">
            <div class="text-center d-lg-none w-100">
                <button type="button" class="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-filter">
                    <i class="icon-unfold mr-2"></i>
                    Menu
                </button>
            </div>

            <div class="navbar-collapse collapse" id="navbar-filter">
                <span class="navbar-text font-weight-semibold mr-3">
                    Menu
                </span>

                <ul class="navbar-nav flex-wrap">
                    <li class="nav-item">
                        <router-link to="/penjualan" class="navbar-nav-link" exact>
                            <i class="icon-cart2 mr-2"></i>
                            Penjualan
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/penjualan/ngoles" class="navbar-nav-link">
                            <i class="icon-collaboration mr-2"></i>
                            Ngoles Barang
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/penjualan/reseller" class="navbar-nav-link">
                            <i class="icon-users mr-2"></i>
                            Reseller
                        </router-link>
                    </li>                 
                </ul>
                <ul class="navbar-nav flex-wrap ml-auto">
                    <li class="nav-item ml-auto">
                        <router-link to="/penjualan/seller" class="navbar-nav-link">
                            <i class="icon-address-book2 mr-2"></i>
                            Data Reseller
                        </router-link>
                    </li>
                </ul>

            </div>
        </div>
        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>
    </div>
</template>
<script>
export default {
    computed: {
        menu () {
            return this.$route.meta.menu
        }
    }
}
</script>
<style scoped>
    .navbar-nav-link.active {
        background-color: #4CAF50 !important;
        color: #fff !important;
    }
</style>