<template>
    <div>
        <div class="card vld-parent">
            <Loading 
                :color="'#26a69a'"
                :loader="'spinner'"
                :active.sync="onLoad"
                :is-full-page="false">
            </Loading>
            <div class="card-body">
                <div class="chart-container">
                    <div class="chart has-fixed-height" id="customers_chart"></div>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import echarts from '../../assets/js/echarts.min.js'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    components: {
        Loading
    },
    data () {
        return {
            onLoad: false
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.onLoad = true
            axios.get('/statistik')
            .then((response) => {
                this.onLoad = false
                var customers_chart_element = document.getElementById('customers_chart');

                let data = response.data;
                let Pengeluaran = [];
                let gross_profit = [];
                let order = [];
                for (let i = 0; i < data.length; i++) {
                    Pengeluaran.push(
                        data[i].pengeluaran
                    )  
                    gross_profit.push(
                        data[i].penjualan
                    )        
                    order.push(
                        data[i].belanja
                    )      
                }
                // Charts configuration
                //

                if (customers_chart_element) {

                    // Initialize chart
                    var customers_chart = echarts.init(customers_chart_element);


                    //
                    // Chart config
                    //

                    // Options
                    customers_chart.setOption({

                        // Define colors
                        color: ['#EF5350', '#03A9F4','#4CAF50'],

                        // Global text styles
                        textStyle: {
                            fontFamily: 'Roboto, Arial, Verdana, sans-serif',
                            fontSize: 13
                        },

                        // Chart animation duration
                        animationDuration: 750,

                        // Setup grid
                        grid: {
                            left: 0,
                            right: 10,
                            top: 35,
                            bottom: 0,
                            containLabel: true
                        },

                        // Add legend
                        legend: {
                            data: ['Pengeluaran','Pendapatan','Barang Masuk'],
                            itemHeight: 8,
                            itemGap: 20,
                            textStyle: {
                                padding: [0, 5]
                            }
                        },

                        // Add tooltip
                        tooltip: {
                            trigger: 'axis',
                            backgroundColor: 'rgba(0,0,0,0.75)',
                            padding: [10, 15],
                            textStyle: {
                                fontSize: 13,
                                fontFamily: 'Roboto, sans-serif'
                            },
                            axisPointer: {
                                type: 'shadow',
                                shadowStyle: {
                                    color: 'rgba(0,0,0,0.025)'
                                }
                            }
                        },

                        // Horizontal axis
                        xAxis: [{
                            type: 'category',
                            data: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sept','Oct','Nov','Dec'],
                            axisLabel: {
                                color: '#333'
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#999'
                                }
                            },
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    color: '#eee',
                                    type: 'dashed'
                                }
                            }
                        }],

                        // Vertical axis
                        yAxis: [
                            {
                                type: 'value',
                                name: 'Pendapatan',
                                axisTick: {
                                    show: false
                                },
                                axisLabel: {
                                    color: '#333',
                                    formatter: '{value}'/10
                                },
                                axisLine: {
                                    lineStyle: {
                                        color: '#999'
                                    }
                                },
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        color: ['#eee']
                                    }
                                },
                                splitArea: {
                                    show: true,
                                    areaStyle: {
                                        color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.015)']
                                    }
                                }
                            },
                            {
                                type: 'value',
                                name: 'Barang Masuk',
                                axisTick: {
                                    show: false
                                },
                                axisLabel: {
                                    color: '#333',
                                    formatter: '{value}'/10
                                },
                                axisLine: {
                                    lineStyle: {
                                        color: '#999'
                                    }
                                },
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        color: ['#eee']
                                    }
                                },
                                splitArea: {
                                    show: true,
                                    areaStyle: {
                                        color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.015)']
                                    }
                                }
                            }
                        ],

                        // Add series
                        series: [
                            {
                                name: 'Pengeluaran',
                                type: 'bar',
                                data: Pengeluaran
                            },
                            {
                                name: 'Pendapatan',
                                type: 'bar',
                                data: gross_profit
                            },
                            {
                                name: 'Barang Masuk',
                                type: 'line',
                                smooth: true,
                                symbolSize: 7,
                                yAxisIndex: 1,
                                data: order,
                                itemStyle: {
                                    normal: {
                                        borderWidth: 2
                                    }
                                }
                            }
                        ]
                    });
                }

                //
                // Resize charts
                //

                // Resize function
                var triggerChartResize = function() {
                    customers_chart_element && customers_chart.resize();
                };

                // On sidebar width change
                var sidebarToggle = document.querySelector('.sidebar-control');
                sidebarToggle && sidebarToggle.addEventListener('click', triggerChartResize);

                // On window resize
                var resizeCharts;
                window.addEventListener('resize', function() {
                    clearTimeout(resizeCharts);
                    resizeCharts = setTimeout(function () {
                        triggerChartResize();
                    }, 200);
                });
            })
        }
    }
}
</script>