import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/auth/Login'
import Dashboard from '../views/dashboard/Dashboard'

// My Profile
import Profile from '../views/myprofile/Profile'
import EditProfile from '../views/myprofile/EditProfile'
import ChangePassword from '../views/myprofile/ChangePassword'
import EditEmail from '../views/myprofile/EditEmail'

// Settings
import Setting from '../views/setting/Setting'
import Information from '../views/setting/Information'
import Shipping from '../views/setting/Shipping'
import BankAccount from '../views/setting/Bank'
import StoreList from '../views/setting/Store'
import Market from '../views/setting/Market'
import User from '../views/setting/User'

// Produk
import IndexProduct from '../views/product/Index'
import Product from '../views/product/Product'
import Category from '../views/product/Category'
import Brand from '../views/product/Brand'
import Pemasok from '../views/product/Pemasok'
import StockOpname from '../views/product/StockOpname'
import EntryStockOpname from '../views/product/EntryStockOpname'
import EditStockOpname from '../views/product/EditStockOpname'

// Barang Masuk
import IndexPurchase from '../views/purchase/Index'
import Purchase from '../views/purchase/Purchase'
import EntryPurchase from '../views/purchase/EntryPurchase'
import EditPurchase from '../views/purchase/EditPurchase'

// Pengeluaran
import Pengeluaran from '../views/Pengeluaran/Pengeluaran'

// Penjualan
import IndexTransaksi from '../views/transaksi/Index'
import Penjualan from '../views/transaksi/penjualan/Penjualan'
import EntryPenjualan from '../views/transaksi/penjualan/EntryPenjualan'
import EditPenjualan from '../views/transaksi/penjualan/EditPenjualan'
import Ngoles from '../views/transaksi/ngoles/Ngoles'
import Reseller from '../views/transaksi/reseller/Reseller'
import EntryReseller from '../views/transaksi/reseller/EntryReseller'
import EditReseller from '../views/transaksi/reseller/EditReseller'
import Seller from '../views/transaksi/seller/Seller'

// Statistik
import Statistik from '../views/statistik/Statistik'

// Laporan 
import IndexReport from '../views/report/Index'
import Report from '../views/report/Report'
import Tagihan from '../views/report/Tagihan'
import Barang from '../views/report/Barang'

// Website 
import IndexWebsite from '../views/website/Index'
import DashboardWeb from '../views/website/Dashboard'
import Katalog from '../views/website/Katalog'

// Online
import IndexOnline from '../views/online/Index'
import Online from '../views/online/Online'
import Ongkir from '../views/online/Ongkir'
import NewOrder from '../views/online/NewOrder'
import EditOrder from '../views/online/EditOrder'
import NewDropship from '../views/online/NewDropship'
import EditDropship from '../views/online/EditDropship'
import Customer from '../views/online/Customer'

// Error
import Error404 from '../views/error/Error404'

Vue.use(VueRouter)

  const routes = [
	{
		path: '/',
		name: 'Dashboard',
		component: Dashboard,
		meta: {
			header: true,
			crumbs: '/Dashboard'
		}
	},
	{
		path: '/online',
		component: IndexOnline,
		children: [
			{
				path: '',
				name: 'Online',
				component: Online,
				meta: {
					crumbs: '/Online',
					menu: true
				}
			},
			{
				path: '/online/ongkir',
				name: 'Ongkir',
				component: Ongkir,
				meta: {
					crumbs: '/Online/Cek Ongkir',
					menu: true
				}
			},
			{
				path: '/online/new',
				name: 'Neworeder',
				component: NewOrder,
				meta: {
					crumbs: '/Online/Baru',
					menu: false
				}
			},
			{
				path: '/online/edit/:id',
				name: 'Editoreder',
				component: EditOrder,
				meta: {
					crumbs: '/Online/Edit',
					menu: false
				}
			},
			{
				path: '/online/dropship',
				name: 'Newdropship',
				component: NewDropship,
				meta: {
					crumbs: '/Online/Dropship',
					menu: false
				}
			},
			{
				path: '/online/dropship/:id',
				name: 'Editdropship',
				component: EditDropship,
				meta: {
					crumbs: '/Online/Dropship/edit',
					menu: false
				}
			},
			{
				path: '/online/customer',
				name: 'Customer',
				component: Customer,
				meta: {
					crumbs: '/Online/Pelangga',
					menu: true
				}
			}
		]
	},	
	{
		path: '/penjualan',
		component: IndexTransaksi,
		children: [
			{
				path:'',
				name: 'Penjualan',
				component: Penjualan,
				meta: {
					crumbs: '/Penjualan',
					menu: true
				}
			},
			{
				path:'/penjualan/entry',
				name: 'EntryPenjualan',
				component: EntryPenjualan,
				meta: {
					crumbs: '/Penjualan/Entry',
					menu: false
				}
			},
			{
				path:'/penjualan/edit/:id',
				name: 'EditPenjualan',
				component: EditPenjualan,
				meta: {
					crumbs: '/Penjualan/Entry/Edit',
					menu: false
				}
			},
			{
				path:'/penjualan/ngoles',
				name: 'Ngoles',
				component: Ngoles,
				meta: {
					crumbs: '/Penjualan/Ngoles',
					menu: true
				}
			},
			{
				path:'/penjualan/reseller',
				name: 'Reseller',
				component: Reseller,
				meta: {
					crumbs: '/Penjualan/Reseller',
					menu: true
				}
			},
			{
				path:'/penjualan/reseller/:id',
				name: 'EntryReseller',
				component: EntryReseller,
				meta: {
					crumbs: '/Penjualan/Reseller/Entry',
					menu: false
				}
			},
			{
				path:'/penjualan/reseller/edit/:id',
				name: 'EditReseller',
				component: EditReseller,
				meta: {
					crumbs: '/Penjualan/Reseller/Entry/Edit',
					menu: false
				}
			},
			{
				path:'/penjualan/seller',
				name: 'Seller',
				component: Seller,
				meta: {
					crumbs: '/Penjualan/Seller',
					menu: true
				}
			},

		]
	},
	{
		path: '/expenses',
		name: 'Pengeluaran',
		component: Pengeluaran,
		meta: {
			crumbs: '/Pengeluaran'
		}
	},
	{
		path: '/settings',								
		component: Setting,		
		children: [
			{
				path: '',
				name: 'Setting',
				component: Information,
				meta: {
					crumbs: '/Pengaturan'
				}

			},
			{
				path: '/settings/shipping',
				name: 'Shipping',
				component: Shipping,
				meta: {
					crumbs: '/Pengaturan/Pengiriman'
				}
			},
			{
				path: '/settings/bank',
				name: 'Bank-Account',
				component: BankAccount,
				meta: {
					crumbs: '/Pengaturan/Bank Account'
				}
			},
			{
				path: '/settings/store',
				name: 'store',
				component: StoreList,
				meta: {
					crumbs: '/Pengaturan/Daftar Toko'
				}
			},
			{
				path: '/settings/market',
				name: 'Market',
				component: Market,
				meta: {
					crumbs: '/Pengaturan/Marketplace'
				}
			},
			{
				path: '/settings/user',
				name: 'User',
				component: User,
				meta: {
					crumbs: '/Pengaturan/User'
				}
			}
		]
	},
	{
		path: '/profile',
		component: Profile,		
		children: [
			{
				path: '',
				name: 'Profile',
				component: EditProfile,
				meta: {
					crumbs: '/Profile'
				}
			},
			{
				path: '/profile/change-password',
				name: 'Change Password',
				component: ChangePassword,
				meta: {
					crumbs: '/Profile/Change Password'
				}
			},
			{
				path: '/profile/edit-email',
				name: 'Edit Email',
				component: EditEmail,
				meta: {
					crumbs: '/Profile/Edit Email'
				}
			},

		]
	},
	{
		path: '/products',
		component: IndexProduct,
		children: [
			{
				path:'',
				name: 'Product',
				component: Product,
				meta: {
					crumbs: '/Produk'
				}
			},
			{
				path:'/products/categories',
				name: 'Category',
				component: Category,
				meta: {
					crumbs: '/Produk/Kategori'
				}
			},
			{
				path:'/products/brands',
				name: 'Brand',
				component: Brand,
				meta: {
					crumbs: '/Produk/Merk'
				}
			},
			{
				path:'/products/suppliers',
				name: 'Suppliers',
				component: Pemasok,
				meta: {
					crumbs: '/Produk/Pemasok'
				}
			},
			{
				path:'/products/stockopnames',
				name: 'Stockopnames',
				component: StockOpname,
				meta: {
					crumbs: '/Produk/Stok Opname'
				}
			},
			{
				path:'/products/stockopnames/:id',
				name: 'EntryStockopnames',
				component: EntryStockOpname,
				meta: {
					crumbs: '/Produk/Stok Opname/Entry'
				}
			},
			{
				path:'/products/stockopnames/edit/:id',
				name: 'EditStockopnames',
				component: EditStockOpname,
				meta: {
					crumbs: '/Produk/Stok Opname/Entry/Edit'
				}
			}
		]
	},
	{
		path: '/purchases',
		component: IndexPurchase,
		children: [
			{
				path:'',
				component: Purchase,
				meta: {
					crumbs: '/Barang Masuk'
				}
			},
			{
				path:'/purchases/:id',
				component: EntryPurchase,
				meta: {
					crumbs: '/Barang Masuk/Entry'
				}
			},
			{
				path:'/purchases/edit/:id',
				component: EditPurchase,
				meta: {
					crumbs: '/Barang Masuk/Entry/Edit'
				}
			}
		]
	},
	{
		path:'/report',
		component: IndexReport,
		children: [
			{
				path: '',
				name: 'Report',
				component: Report,
				meta: {
					crumbs: '/Laporan'
				}
			},
			{
				path: '/report/receivable',
				name: 'Receivable',
				component: Tagihan,
				meta: {
					crumbs: '/Laporan/Tagihan'
				}	
			},
			{
				path: '/report/products',
				name: 'Barang',
				component: Barang,
				meta: {
					crumbs: '/Laporan/Barang'
				}	
			}
		]
	},
	{
		path:'/statistics',
		name: 'Statistik',
		component: Statistik,
		meta: {
			crumbs: '/Statistik'
		}
	},
	{
		path: '/website',
		component: IndexWebsite,
		children: [
			{
				path: '',
				name: 'Website',
				component: DashboardWeb,
				meta: {
					crumbs: '/Website'
				}
			},
			{
				path: '/website/katalog',
				name: 'Katalog',
				component: Katalog,
				meta: {
					crumbs: '/Website/Katalog'
				}
			}
		]
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			crumbs: '/Login'
		}
	},
	{
		path: '*', 
		component: Error404,
		meta: {
			crumbs: '/Error 404'
		},
	},
]

const router = new VueRouter({
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	mode: 'history',
	base: process.env.BASE_URL,
	linkExactActiveClass: 'active',
	linkActiveClass: 'active',
	routes,
})

function isAuthenticated () {
	return localStorage.getItem('token')
}

router.beforeEach((to, from, next) => {
	if (to.name !== 'Login' && !isAuthenticated()) next({ name: 'Login' })   
	else if  (to.name == 'Login' && isAuthenticated()) next({ name: 'Dashboard' })
	else next()
})

export default router
