<template>
    <div>
        <div class="card-body pt-0">
            <div class="row">
                <div class="col-md-6">
                    <h5 class="mb-0">Akun Pengguna</h5>
                </div>
                <div class="col-md-6 text-right">
                    <button @click="onAdd" type="button" class="btn bg-teal-400 btn-labeled btn-labeled-left" data-toggle="modal" data-target="#modal"><b><i class="icon-plus-circle2"></i></b> Tambah Pengguna</button>
                </div>
            </div>
        </div>
        <table class="table">
            <thead class="bg-grey">
                <tr>
                    <th style="width:25%">Name</th>
                    <th style="width:25%">Email</th>
                    <th style="width:25%">Role</th>
                    <th style="width:15%">Toko</th>
                    <th class="text-center" style="width:10%">Aksi</th>
                </tr>
            </thead>
            <tbody v-if="onLoad">
                <tr v-for="a in 5" :key="a">
                    <td>
                        <div class="alpha-slate p-1 col-6"></div>
                    </td>
                    <td>
                        <div class="alpha-slate p-1 col-8"></div>
                    </td>
                    <td>
                        <div class="alpha-slate p-1 col-8"></div>
                    </td>
                    <td>
                        <div class="alpha-slate p-1 col-9"></div>
                    </td>
                    <td>
                        <div class="d-flex justify-content-center">
                            <div class="alpha-slate p-2 col-1"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr v-for="(user, i) in users" :key="i">
                    <td>{{user.name}}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.role}}</td>
                    <td>{{user.store}}</td>
                    <td class="text-center">
                        <a @click.prevent="onEdit(i)" href="" data-toggle="modal" data-target="#modal"><i class="icon-pencil5"></i></a>
                    </td>
                </tr>
            </tbody>
        </table>   

        <form @submit.prevent="onSubmit" class="modal fade right" id="modal">
            <div class="modal-dialog vld-parent">
                <Loading 
                    :color="'#26a69a'"
                    :loader="'dots'"
                    :active.sync="isLoading"
                    :is-full-page="false">
                </Loading>
                <div class="modal-content">
                    <div class="modal-header pb-2">
                        <h5 class="modal-tite">{{modal_title}}</h5>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Nama Pengguna <span class="text-danger">*</span></label>
                            <input v-model="form.name" @keydown="error.name=''" type="text" class="form-control">
                            <span v-if="error.name" class="form-text text-danger font-size-xs">{{error.name}}</span>
                        </div>
                        <div class="form-group">
                            <label>Email <span class="text-danger">*</span></label>
                            <input v-model="form.email" @keydown="error.email=''" type="email" class="form-control">
                            <span v-if="error.email" class="form-text text-danger font-size-xs">{{error.email}}</span>
                        </div>
                        <div class="form-group">
                            <label>Sandi <span v-if="modal_mode == 'new'" class="text-danger">*</span></label>
                            <input v-model="form.password" @keydown="error.password=''" type="pwassord" class="form-control">
                            <span v-if="error.password" class="form-text text-danger font-size-xs">{{error.password}}</span>
                        </div>
                        <div class="form-group">
                            <label>Pilih Toko <span class="text-danger">*</span></label>
                            <select2 
                                :placeholder="'Pilih toko'"
                                v-model="form.store_id"
                                :options="stores"
                                @input="onStore"
                            />
                            <span v-if="error.store_id" class="form-text text-danger font-size-xs">{{error.store_id}}</span>
                        </div>
                        <div class="form-group mb-0">
                            <label> Hak Akses <span class="text-danger">*</span></label>
                            <select2 
                                :placeholder="'Pilih hak akses'"
                                v-model="form.role"
                                :options="roles"
                                @input="onRole"
                            />
                            <span v-if="error.role" class="form-text text-danger font-size-xs">{{error.role}}</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">BATAL</button>
                        <button type="submit" class="btn bg-teal">SIMPAN</button>
                    </div>
                </div>
            </div>
        </form>  
    </div>
</template>

<script>
import select2 from '../../components/Select2'
import axios from 'axios'
import $ from 'jquery'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    components: {
        select2,
        Loading
    },
    data () {
        return {
            onLoad: false,
            isLoading: false,
            users: null,
            modal_title: null,
            modal_mode: null,
            stores: null,
            roles : [
                {id:'Super Admin', text:'Super Admin'},
                {id:'Admin', text:'Admin'},
                {id:'staf', text:'staf'}
            ],
            form: {
                name: '',
                email: '',
                password: '',
                store_id: '',
                role: '',
            },
            error: {
                name: '',
                email: '',
                password: '',
                store_id: '',
                role: '',
            }
        }
    },
    mounted () {
        this.onLoad = true
        this.getData()
    },
    methods: {
        getData() {
            this.stores = [{id:'', name:''}]
            axios.get('/user_account')
            .then((response) => {
                this.onLoad = false
                this.users = response.data.users
                response.data.stores.forEach(store => {
                    this.stores.push({id: store.id, text: store.name})
                });
            })
        },
        onAdd () {
            this.modal_title = 'Tambah Pengguna',
            this.modal_mode = 'new'
            this.form= {
                name: '',
                email: '',
                password: '',
                store_id: '',
                role: '',
            }
            this.error = {
                name: '',
                email: '',
                password: '',
                store_id: '',
                role: '',
            }
        },
        onEdit (i) {
            this.modal_title= 'Ubah Akun Pengguna'
            this.modal_mode='edit'
            this.error = {
                name: '',
                email: '',
                password: '',
                store_id: '',
                role: '',
            }
            this.form = {
                id: this.users[i].id,
                name: this.users[i].name,
                email: this.users[i].email,
                store_id: this.users[i].store_id,
                role: this.users[i].role,
            }
        },
        onRole () {
            this.error.role = ''
        },
        onStore () {
            this.error.store_id = ''
        },
        onSubmit () {
            if(!this.form.name) {
                this.error.name = 'Nama Pengguna harus diisi !'
            }
            if(!this.form.email) {
                this.error.email = 'Email harus diisi !'
            }
            if(this.modal_mode == 'new') {
                if(!this.form.password) {
                    this.error.password = 'Password harus diisi !'
                }
            }
            if(!this.form.store_id) {
                this.error.store_id = 'Toko haru diisi !'
            }
            if(!this.form.role) {
                this.error.role = 'Hak akseses harus diisi !'
            }
            if(this.form.name && this.form.email && this.form.store_id && this.form.role) {
                this.isLoading = true
                if(this.modal_mode=='new' && this.form.password) {
                    axios.post('/user_account/create', this.form)
                    .then((response) => {
                        this.isLoading=false
                        if(response.data.error) {
                            this.error.email = response.data.error
                        } else {
                            this.getData()
                            $('#modal').modal('hide')
                        }
                    })
                }
                if(this.modal_mode == 'edit') {
                    axios.post('/user_account/update',this.form)
                    .then((response) => {
                        this.isLoading = false
                        if(response.data.error) {
                            this.error.email = response.data.error
                        } else {
                            this.getData()
                            $('#modal').modal('hide')
                        }
                    })
                }
            }
        }
    }
}
</script>
