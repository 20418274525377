<template>
    <div>
        <div class="card">
            <div class="card-header bg-white header-elements-inline">
                <h6 class="card-title">STOK OPNAME</h6>
                <div class="header-elements">
                    <button @click="onCreate" :disabled="onWait" type="button" class="btn bg-teal-400 btn-labeled btn-labeled-left" data-toggle="modal" data-target="#modal"><b><i class="icon-plus-circle2"></i></b>Buat stok opname</button>
                </div>
            </div>
            <table class="table">
                <thead class="bg-info">
                    <tr>
                        <th style="width: 30%">Opname</th>
                        <th style="width: 30%">User</th>
                        <th style="width: 30%">Tanggal</th>
                        <th class="text-center" style="width: 10%">Aksi</th>
                    </tr>
                </thead>
                <tbody v-if="onLoad">
                    <tr v-for="p in 10" :key="p">
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td>
                            <div class="d-flex justify-content-center ph-item">
                                <div class="alpha-slate p-2 col-2 mr-1 ph-item"></div>
                                <div class="alpha-slate p-2 col-2 ph-item"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr v-for="(opname, i) in stockopnames" :key="i">
                        <td>{{opname.opname}}</td>
                        <td>{{opname.user}}</td>
                        <td>{{opname.created_at}}</td>
                        <td class="text-center">
                            <a @click.prevent="onDetail(i)" href="#" data-toggle="modal" data-target="#modal"><i class="icon-file-text2 text-success mr-1"></i></a>
                            <router-link :to="'/products/stockopnames/edit/'+opname.id"><i class="icon-pencil5 text-primary"></i></router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="card-footer d-flex p-3 ">
                <div class="align-self-center">
                    <span class="text-muted">Total barang: {{Intl.NumberFormat('en-US').format(page.total)}}</span>
                </div>
                <div class="ml-auto">
                    <Paginate :pages="page" @get-page="getData($event)" />
                </div>
            </div>
        </div>

        <div class="modal fade" id="modal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{details.opname}} <span class="text-muted ml-2">{{details.date}}</span></h5>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-6 border-right">
                                <div class="media">
                                    <div class="mr-3 align-self-center">
                                        <i class="icon-stack-check icon-3x"></i>
                                    </div>

                                    <div class="media-body text-left">
                                        <h5 class="font-weight-semibold mb-0">STOK OPNAME</h5>
                                        <span class="text-uppercase font-size-sm text-muted">DETAIL</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="align-self-center text-center">
                                    <h5 class="font-weight-semibold mb-0">{{Intl.NumberFormat('en-US').format(details.barangs.length)}}</h5>
                                    <span class="text-muted">TOTAL ITEM</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive table-scrollable">
                        <table class="table">
                            <thead class="bg-info">
                                <tr>
                                    <th style="width: 30%">KODE BARANG</th>
                                    <th style="width: 30%">NAMA BARANG</th>
                                    <th class="text-right" style="width: 15%">STOK LAMA</th>
                                    <th class="text-right" style="width: 15%">STOK BARU</th>
                                    <th class="text-right" style="width: 10%">SELISIH</th>
                                </tr>
                            </thead>
                            <tbody v-if="onLoadDetail">
                                <tr v-for="p in 5" :key="p">
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr v-for="(barang, i) in details.barangs" :key="i">
                                    <td>{{barang.kode_barang}}</td>
                                    <td>{{barang.nama_barang}}</td>
                                    <td class="text-right">{{barang.stok_lama}}</td>
                                    <td class="text-right">{{barang.stok_baru}}</td>
                                    <td class="text-right">{{barang.selisih}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import axios from 'axios'
import Paginate from '../../components/Paginate'
export default {
    components: {
        Paginate
    },  
    data () {
        return {
            onLoad: false,
            onLoadDetail: false,
            onWait: false,
            page: '',
            stockopnames: '',
            details: {
                opname: '',
                date: '',
                barangs: '',
            }
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        onCreate () {
            this.onWait = true
            axios.post('/stockopname/create')
            .then((response) => {
                this.onWait = false
                this.$router.push('/products/stockopnames/'+response.data.id)
            })
        },
        getData (e) {
            this.stockopnames = ''
            this.onLoad = true
            $("html, body").animate({ scrollTop: "0" })
            axios.get('/stockopname',{
                params: {
                    page: e
                }
            })
            .then((response) => {
                this.onLoad = false
                this.stockopnames = response.data.data
                this.page = response.data
            })
        },
        onDetail (i) {
            this.onLoadDetail = true
            this.details.opname = this.stockopnames[i].opname
            this.details.date = this.stockopnames[i].created_at
            let id = this.stockopnames[i].id
            this.details.barangs = ''
            axios.get('/stockopname/detail/'+id)
            .then((response) => {
                this.onLoadDetail = false
                this.details.barangs = response.data
            })
        }
    }
}
</script>