<template>
    <div>
        <div v-if="onLoad" class="row">
            <div class="col-md-4">
                <div class="card card-body alpha-slate ph-item" style="height: 94px">

                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-body alpha-slate ph-item" style="height: 94px">

                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-body alpha-slate ph-item" style="height: 94px">

                </div>
            </div>
        </div>
        <div v-if="onLoad == false" class="row">
            <div class="col-md-4">
                <div class="card card-body">
                    <div class="media">
                        <div class="mr-3 align-self-center">
                            <i class="icon-collaboration icon-3x opacity-75"></i>
                        </div>

                        <div class="media-body text-right">
                            <h3 class="mb-0">{{Intl.NumberFormat('en-US').format(totalNgoles)}}</h3>
                            <span class="text-uppercase font-size-xs">NGOLES</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-body">
                    <div class="media">
                        <div class="mr-3 align-self-center">
                            <i class="icon-users2 icon-3x opacity-75"></i>
                        </div>

                        <div class="media-body text-right">
                            <h3 class="mb-0">{{Intl.NumberFormat('en-US').format(totalReseller)}}</h3>
                            <span class="text-uppercase font-size-xs">RESELLER</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-body bg-danger">
                    <div class="media">
                        <div class="mr-3 align-self-center">
                            <i class="icon-calculator2 icon-3x opacity-75"></i>
                        </div>

                        <div class="media-body text-right">
                            <h3 class="mb-0">{{Intl.NumberFormat('en-US').format(parseInt(totalNgoles + totalReseller))}}</h3>
                            <span class="text-uppercase font-size-xs">TOTAL TAGIHAN</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <h6 class="mb-0">TAGIHAN PADA POS NGOLES</h6>
                    </div>
                    <table class="table">
                        <thead class="bg-danger">
                            <tr>
                                <th>Tanggal</th>
                                <th>Nama</th>
                                <th class="text-right">Total</th>
                                <th>Toko</th>
                            </tr>
                        </thead>
                        <tbody v-if="onLoad">
                            <tr v-for="p in 10" :key="p">
                                <td><div class="alpha-slate p-1 ph-item"></div></td>
                                <td><div class="alpha-slate p-1 ph-item"></div></td>
                                <td><div class="alpha-slate p-1 ph-item"></div></td>
                                <td><div class="alpha-slate p-1 ph-item"></div></td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr v-for="(item, i) in ngoles" :key="i">
                                <td>{{item.created_at}}</td>
                                <td>{{item.nama}}</td>
                                <td class="text-right">{{Intl.NumberFormat('en-US').format(item.total)}}</td>
                                <td>{{item.toko}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <h6 class="mb-0">TAGIHAN PADA POS RESELLER</h6>
                    </div>
                    <table class="table">
                        <thead class="bg-danger">
                            <tr>
                                <th>Tanggal</th>
                                <th>Seller</th>
                                <th class="text-right">Total</th>
                                <th>Toko</th>
                            </tr>
                        </thead>
                        <tbody v-if="onLoad">
                            <tr v-for="p in 10" :key="p">
                                <td><div class="alpha-slate p-1 ph-item"></div></td>
                                <td><div class="alpha-slate p-1 ph-item"></div></td>
                                <td><div class="alpha-slate p-1 ph-item"></div></td>
                                <td><div class="alpha-slate p-1 ph-item"></div></td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr v-for="(item, i) in reseller" :key="i">
                                <td>{{item.created_at}}</td>
                                <td>{{item.seller}}</td>
                                <td class="text-right">{{Intl.NumberFormat('en-US').format(item.sisa)}}</td>
                                <td>{{item.toko}}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data () {
        return {
            onLoad: false,
            ngoles: [],
            reseller: [],
        }
    },
    mounted () {
        this.getData()
    },
    computed: {
        totalNgoles () {
            let sum = 0
            this.ngoles.forEach(el => {
                sum += el.total
            })
            return sum
        },
        totalReseller () {
            let sum = 0
            this.reseller.forEach(el => {
                sum += el.sisa
            })
            return sum
        }
    },
    methods: {
        getData () {
            this.onLoad = true
            axios.get('/report/tagihan')
            .then((response) => {
                this.onLoad = false
                this.ngoles = response.data.ngoles
                this.reseller = response.data.reseller
            })
        }
    }
}
</script>