<template>
    <div>
        <div class="card">
            <div class="card-header bg-white header-elements-inline">
                <h6 class="card-title">BARANG MASUK</h6>
                <div class="header-elements">
                    <button @click="onAdd" type="button" class="btn bg-teal-400 btn-labeled btn-labeled-left" data-toggle="modal" data-target="#modal"><b><i class="icon-plus-circle2"></i></b>Entry barang masuk</button>
                </div>
            </div>
            <table class="table">
                <thead class="bg-success">
                    <tr>
                        <th style="width: 20%">Tanggal</th>
                        <th style="width: 15%">User</th>
                        <th style="width: 15%">Invoice</th>
                        <th style="width: 20%">Pemasok</th>
                        <th style="width: 10%">Pembayaran</th>
                        <th style="width: 12%" class="text-right">Total</th>
                        <th class="text-center" style="width: 8%">Aksi</th>
                    </tr>
                </thead>
                <tbody v-if="onLoad">
                    <tr v-for="p in 10" :key="p">
                        <td>
                            <div class="alpha-slate p-1 ph-item"></div>
                        </td>
                        <td>
                            <div class="alpha-slate p-1 ph-item"></div>
                        </td>
                        <td>
                            <div class="alpha-slate p-1 ph-item"></div>
                        </td>
                        <td>
                            <div class="alpha-slate p-1 ph-item"></div>
                        </td>
                        <td>
                            <div class="alpha-slate p-1 ph-item"></div>
                        </td>
                        <td>
                            <div class="alpha-slate p-1 ph-item"></div>
                        </td>
                        <td>
                            <div class="alpha-slate p-2 ph-item"></div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-if="barang_masuks">
                    <tr v-for="(barang, i) in barang_masuks" :key="i">
                        <td>{{barang.created_at}}</td>
                        <td>{{barang.user}}</td>
                        <td>{{barang.invoice}}</td>
                        <td>{{barang.pemasok}}</td>
                        <td>{{barang.Pembayaran}}</td>
                        <td class="text-right">{{Intl.NumberFormat('en-US').format(barang.total)}}</td>
                        <td class="text-center">
                            <a @click.prevent="onDetail(i)" href="#" class="mr-1" data-toggle="modal" data-target="#detail"><i class="icon-file-text2 text-success"></i></a>
                            <router-link :to="'/purchases/edit/'+barang.id"><i class="icon-pencil5 text-primary"></i></router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="card-footer d-flex p-3 ">
                <div class="align-self-center">
                    <span class="text-muted">Total invoice: {{Intl.NumberFormat('en-US').format(page.total)}}</span>
                </div>
                <div class="ml-auto">
                    <Paginate :pages="page" @get-page="getData($event)" />
                </div>
            </div>
        </div>
        <div class="modal fade" id="detail">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{details.invoice}} <span class="text-muted ml-2">{{details.date}}</span></h5>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-6 border-right">
                                <div class="media">
                                    <div class="mr-3 align-self-center">
                                        <i class="icon-store icon-3x"></i>
                                    </div>

                                    <div class="media-body text-left">
                                        <h5 class="font-weight-semibold mb-0">{{details.pemasok}}</h5>
                                        <span class="text-uppercase font-size-sm text-muted">PEMASOK</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="text-center">
                                    <h5 class="mb-0 font-weight-semibold">{{Intl.NumberFormat('en-US').format(details.total)}}</h5>
                                    <span class="text-muted">TOTAL</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive table-scrollable">
                        <table class="table">
                            <thead class="bg-info"> 
                                <tr>
                                    <th style="width: 20%">KODE BARANG</th>
                                    <th style="width: 30%">NAMA BARANG</th>
                                    <th class="text-right" style="width: 20%">HARGA BELI</th>
                                    <th class="text-right" style="width: 10%">QTY</th>
                                    <th class="text-right" style="width: 20%">TOTAL</th>
                                </tr>
                            </thead>
                            <tbody v-if="onLoadDetail">
                                <tr v-for="p in 5" :key="p">
                                    <td><div class="alpha-slate p-2 ph-item"></div></td>
                                    <td><div class="alpha-slate p-2 ph-item"></div></td>
                                    <td><div class="alpha-slate p-2 ph-item"></div></td>
                                    <td><div class="alpha-slate p-2 ph-item"></div></td>
                                    <td><div class="alpha-slate p-2 ph-item"></div></td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr v-for="(barang, i) in details.barangs" :key="i">
                                    <td>{{barang.kode_barang}}</td>
                                    <td>{{barang.nama_barang}}</td>
                                    <td class="text-right">{{Intl.NumberFormat('en-US').format(barang.harga_beli)}}</td>
                                    <td class="text-right">{{barang.qty}}</td>
                                    <td class="text-right">{{Intl.NumberFormat('en-US').format(barang.total)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <form @submit.prevent="onSubmit" class="modal fade" id="modal">
            <div class="modal-dialog">
                <Loading 
                    :color="'#26a69a'"
                    :loader="'bars'"
                    :active.sync="isLoading"
                    :is-full-page="false">
                </Loading>
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title">Pilih Pemasok</h6>
                    </div>
                    <div class="modal-body">
                        <div class="form-group mr-5 ml-5">
                            <label>Pilih Pemasok</label>
                            <select2 v-model="form.pemasok_id" @input="error.pemasok_id=''" :options="pemasoks" :placeholder="'Pilih Pemasok'" />
                            <span v-if="error.pemasok_id" class="form-text text-danger">{{error.pemasok_id}}</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">BATAL</button>
                        <button type="submit" class="btn bg-teal" :disabled="isLoading">BERIKUTNYA</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import $ from 'jquery'
import select2 from '../../components/Select2'
import axios from 'axios'
import Paginate from '../../components/Paginate'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    components: {
        select2,
        Paginate,
        Loading
    },
    data () {
        return {
            onLoadDetail: false,
            onLoad: false,
            isLoading: false,
            barang_masuks: '',
            page: '',
            details: {
                invoice: '',
                date: '',
                pemasok: '',
                total: '',
                barangs: '',
            },

            pemasoks: [
                {id: '', text: ''}
            ],
            form: {
                pemasok_id: ''
            },
            error: {
                pemasok_id: ''
            }
        }
    },
    mounted () {
        this.getData()
        this.getPemasok()
    },
    methods: {
        getData (e) {
            this.onLoad = true
            this.barang_masuks = ''
            $("html, body").animate({ scrollTop: "0" })
            axios.get('/barang_masuk',{
                params: {
                    page: e
                }
            })
            .then((response) => {
                this.onLoad = false
                this.barang_masuks = response.data.data
                this.page = response.data
            })
        },
        getPemasok () {
            axios.get('/pemasok')
            .then((response) => {
                response.data.forEach(el => {
                    this.pemasoks.push({id: el.id, text: el.nama_perusahaan})
                });
            })
        },
        onAdd () {
            this.form.pemasok_id = ''
            this.error.pemasok_id = ''
        },
        onDetail(i) {
            this.onLoadDetail = true
            let id = this.barang_masuks[i].id
            this.details.invoice = this.barang_masuks[i].invoice
            this.details.date = this.barang_masuks[i].created_at
            this.details.pemasok = this.barang_masuks[i].pemasok
            this.details.total = this.barang_masuks[i].total
            this.details.barangs = ''
            axios.get('/barang_masuk/detail/'+id)
            .then((response) => {
                this.onLoadDetail = false
                this.details.barangs = response.data
            })
        },
        onSubmit () {
            if(!this.form.pemasok_id) {
                this.error.pemasok_id = 'Pemasok harus dipilih!!'
            }
            if(this.form.pemasok_id) {
                this.isLoading = true
                axios.post('/barang_masuk/create', this.form)
                .then((response) => {
                    $('#modal').modal('hide');
                    this.isLoading = false
                    this.$router.push('/purchases/'+response.data.id)
                })
            }
        },
    }
}
</script>