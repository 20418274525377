<template>
    <div>
        <div class="card">
            <div class="card-header bg-white header-elements-inline p-2">
                <h6 class="card-title ml-2">PENJUALAN ONLINE</h6>
                <div class="header-elements">
                    <button @click="onTransfer" type="button" class="btn btn-primary btn-labeled btn-labeled-left mr-1" data-toggle="modal" data-target="#modal"><b><i class="icon-printer"></i></b>CETAK TRANSFER</button>
                    <router-link to="/online/dropship" type="button" class="btn bg-orange btn-labeled btn-labeled-left mr-1" data-toggle="modal" data-target="#modal"><b><i class="icon-collaboration"></i></b>Dropship Baru</router-link>
                    <router-link to="/online/new" type="button" class="btn bg-teal-400 btn-labeled btn-labeled-left mr-1" data-toggle="modal" data-target="#modal"><b><i class="icon-plus-circle2"></i></b>Penjualan Baru</router-link>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-3 align-self-center">
                        <div class="form-group-feedback form-group-feedback-left">
                            <input v-model="search" type="search" class="form-control" placeholder="Ketik Nama Customer">
                            <div class="form-control-feedback">
                                <i class="icon-search4 font-size-base text-muted"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9 align-self-center text-right">
                        <div class="btn-group btn-group-justified">
                            <div class="btn-group">
                                <button @click="onFilter('semua')" type="button" class="btn" :class="[filter=='semua' ? 'btn-success':'btn-info']">Semua</button>
                            </div>
                            <div class="btn-group">
                                <button @click="onFilter('hari ini')" type="button" class="btn" :class="[filter=='hari ini' ? 'btn-success':'btn-info']">Hari ini ({{today}})</button>
                            </div>
                            <div class="btn-group">
                                <button @click="onFilter('belum diprint')" type="button" class="btn" :class="[filter=='belum diprint' ? 'btn-success':'btn-info']">Belum diprint ({{prints}})</button>
                            </div>

                            <div class="btn-group">
                                <button @click="onFilter('siap dikirim')" type="button" class="btn" :class="[filter=='siap dikirim' ? 'btn-success':'btn-info']">Siap dikirim ({{ready}})</button>
                            </div>

                            <div class="btn-group">
                                <button @click="onFilter('sedang dikirim')" type="button" class="btn" :class="[filter=='sedang dikirim' ? 'btn-success':'btn-info']">Sedang dikirim ({{process}})</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-3">
                   <div v-for="(item, i) in shippings" :key="i" class="py-2 px-3 d-flex shipping border-right" :class="[i == 0 ? 'border-left':'']" style="flex-grow: 1;">
                        <div class="m-auto">
                            {{item.courier}} {{item.count}}
                        </div>
                    </div>
                </div>
            </div>
            <table class="table">
                <thead class="bg-success">
                    <tr>
                        <td>
                            <i class="icon-people mr-1"></i> CUSTOMER
                        </td>
                        <td>
                            <i class="icon-store mr-1"></i> MARKETPLACE
                        </td>
                        <td>
                            <i class="icon-truck mr-1"></i> KURIR
                        </td>
                        <td>
                            <i class="icon-file-text2 mr-1"></i> INVOICE
                        </td>
                        <td class="text-right">
                            <i class="icon-calculator mr-1"></i> TOTAL
                        </td>
                        <td class="text-center">
                            <i class="icon-touch mr-1"></i> AKSI
                        </td>
                    </tr>
                </thead>
                <tbody v-if="isLoading">
                    <tr v-for="i in 10" :key="i">
                        <td>
                            <div class="d-flex align-items-center ph-item">
                                <div class="mr-2">
                                    <div class="alpha-slate rounded-round" style="width: 32px; height:32px"></div>
                                </div>
                                <div>
                                    <div class="alpha-slate p-2 mb-1" style="width: 80px"></div>
                                    <div class="alpha-slate p-1" style="width: 50px"></div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="d-flex align-items-center ph-item">
                                <div class="mr-2">
                                    <div class="alpha-slate" style="width: 32px; height:32px"></div>
                                </div>
                                <div>
                                    <div class="alpha-slate p-2 mb-1" style="width: 80px"></div>
                                    <div class="alpha-slate p-1" style="width: 50px"></div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="alpha-slate p-2 mb-1 ph-item" style="width: 160px"></div>
                            <div class="alpha-slate p-1 ph-item" style="width: 90px"></div>
                        </td>
                        <td>
                            <div class="alpha-slate p-2 mb-1 ph-item" style="width: 110px"></div>
                            <div class="alpha-slate p-1" style="width: 100px"></div>
                        </td>
                        <td class="text-right ph-item">
                            <div class="alpha-slate p-2 mb-1" style="width: 80px"></div>
                            <div class="alpha-slate p-1" style="width: 50px"></div>
                        </td>
                        <td class="text-right">
                            <div class="d-flex justify-content-center ph-item">
                                <div class="alpha-slate" style="height: 33px; width: 33px;"></div>
                                <div class="alpha-info" style="height: 33px; width: 33px;"></div>
                                <div class="alpha-primary" style="height: 33px; width: 33px;"></div>
                                <div class="alpha-danger" style="height: 33px; width: 33px;"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr v-for="(item, i) in sellings" :key="i">
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="mr-2">
                                    <div class="btn bg-teal-400 rounded-round btn-icon btn-sm">
                                        <span class="letter-icon">{{item.customer.substring(0,1)}}</span>
                                    </div>
                                </div>
                                <div>
                                    <a href="#" class="font-weight-semibold letter-icon-title text-default">{{item.customer}}</a>
                                    <div v-if="item.type=='Retail'" class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span> Retail</div>
                                    <div v-if="item.type=='Dropship'" class="text-muted font-size-sm"><span class="badge badge-mark border-purple mr-1"></span> Dropship</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="mr-2">
                                    <img :src="'http://api.zhrleather.com/assets/images/marketplaces/'+item.market_icon" alt="" width="35px;">
                                </div>
                                <div>
                                    <a href="#" class="font-weight-semibold letter-icon-title text-default">{{item.market}}</a>
                                    <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1" :class="[item.status == 'Baru' ? 'border-danger':'border-success']"></span> {{item.status}}</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <h6 class="font-weight-semibold mb-0">{{item.courier}} - {{item.service}}</h6>
                            <span v-if="item.resi==null" class="text-muted">{{item.booking_code}}</span>
                            <span v-if="item.resi!=null" class="text-muted">{{item.resi}}</span>
                        </td>
                        <td>
                            <div class="font-size-lg font-weight-semibold">{{item.invoice}}</div>
                            <div class="text-muted font-size-sm">{{item.created_at}}</div>
                        </td>
                        <td class="text-right">
                            <h6 class="font-weight-semibold mb-0">{{Intl.NumberFormat('en-US').format(item.total + item.shipping_cost)}}</h6>
                            <span class="text-muted">{{item.bank}}</span>
                        </td>
                        <td class="text-center">
                            <div class="btn-group">
                                <button @click="onPrint(i)" class="btn btn-icon" :class="[item.printed == 1 ? 'btn-light':'btn-success']"><i class="icon-printer"></i></button>
                                <button v-if="item.printed == 1" @click="onResi(i)" class="btn btn-icon btn-info" data-toggle="modal" data-target="#resi"><i class="icon-barcode2"></i></button>
                                <button v-if="item.status == 'Baru'" @click="onEdit(i)" class="btn btn-primary btn-icon"><i class="icon-pencil5"></i></button>
                                <button v-if="item.status == 'dikirim' || item.status=='selesai'" class="btn btn-success btn-icon"><i class="icon-truck"></i></button>
                                <button @click="onDelete(i)" v-if="item.status == 'Baru'" class="btn btn-danger btn-icon"><i class="icon-trash"></i></button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="card-footer d-flex p-3 ">
                <div class="align-self-center">
                    <span class="text-muted">Total Penjualan: {{Intl.NumberFormat('en-US').format(page.total)}}</span>
                </div>
                <div class="ml-auto">
                    <Paginate :pages="page" @get-page="getData($event)" />
                </div>
            </div>
        </div>
        <div id="printTrf">
            <div class="row">
                <div class="col-6">
                    <div class="card rouded-0">
                        <h6 class="text-center mt-2">LAPORAN TRANSFER BANK</h6>
                        <div class="row m-2">
                            <div class="col-6">
                                TANGGAL
                            </div>
                            <div class="col-6 text-right">
                                {{tanggal}}
                            </div>
                        </div>
                        <div class="row ml-2 mr-2 mb-2">
                            <div class="col-6">
                                TOTAL
                            </div>
                            <div class="col-6 text-right">
                                <div class="font-weight-semibold">{{Intl.NumberFormat('en-US').format(totaltrf)}}</div>
                            </div>
                        </div>
                        <table class="table table-xs">
                            <thead class="border-top">
                                <tr>
                                    <th>NAMA BANK</th>
                                    <th class="text-right">TOTAL</th>
                                    <th class="text-center">CEKLIS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in laptransfer" :key="i">
                                    <td>{{item.bank}}</td>
                                    <td class="text-right">{{Intl.NumberFormat('en-US').format(item.total)}}</td>
                                    <td class="text-center">
                                        <i class="icon-checkbox-unchecked2"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div id="print">
            <div class="row">
                <div class="col-6">
                    <div class="card rounded-0">
                        <div class="d-flex border-bottom pb-2 pt-2 pl-3 pr-3 mb-0">
                            <div v-if="label.type == 'Retail'">
                                <img src="@/assets/images/logo_print.png" alt="" width="100">
                            </div>
                        </div>
                        <div class="card-body m-0 pt-1">                            
                            <div class="d-flex justify-content-center">
                                <div>
                                    <barcode :value ="label.booking_code" fontSize="15" height="55"></barcode>
                                </div>
                            </div>
                            <div :class="[label.type == 'Dropship' ? '':'bg-image']">
                                <div class="d-flex border mt-1 text-default">
                                    <div class="col-3 align-self-center">
                                        <img v-if="label.logo" :src="'http://api.zhrleather.com/assets/images/shippings/'+label.logo" alt="" width="80">
                                    </div>
                                    <div class="col-3">
                                        <div class="mb-0 mt-1">SERVICE</div>
                                        <div class="font-weight-bold">{{label.service}}</div>
                                    </div>
                                    <div class="col-6">
                                        <div class="mb-0 mt-1">INVOICE</div>
                                        <div>{{label.invoice}} </div>
                                    </div>
                                </div>
                            
                                <div class="row mt-2 text-default">
                                    <div class="col-3">
                                        <h6>Kepada</h6>
                                    </div>
                                    <div class="col-9 text-default">
                                        <div class="font-weight-bold">{{label.to.name}}  +62{{label.to.phone}}</div>
                                        <div>{{label.to.address}}</div>
                                    </div>
                                </div>
                                <div class="row mt-2 text-default">
                                    <div class="col-3">
                                        <h6 class="font-weight-semibold">Dari</h6>
                                    </div>
                                    <div class="col-9">
                                        <div class="font-weight-bold">{{label.from.name}}  +62{{label.from.phone}}</div>
                                        <div>Garut, Jawa Barat</div>
                                    </div>
                                </div>
                                <table class="table table-xs mt-3">
                                <thead>
                                        <th>Nama</th>
                                        <th>SKU</th>
                                        <th>QTY</th>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, i) in details" :key="i">
                                        <td>{{item.nama_barang}}</td>
                                        <td>
                                            <div class="font-weight-bold">
                                                    {{item.kode_barang}}
                                            </div>
                                            </td>
                                        <td>{{item.qty}}</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <form @submit.prevent="onSubmit" class="modal fade" id="resi">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content vld-parent">
                    <Loading 
                        :color="'#26a69a'"
                        :loader="'spinner'"
                        :active.sync="isResi"
                        :is-full-page="false">
                    </Loading>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-6 border-right">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="mr-2">
                                        <div class="btn bg-teal-400 rounded-round btn-icon btn-sm">
                                            <span class="letter-icon">{{resi.nama.substring(0,1)}}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="font-weight-semibold letter-icon-title text-default">{{resi.nama}}</div>
                                        <div>{{resi.type}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="mr-2">
                                        <img v-if="resi.logo" :src="'http://api.zhrleather.com/assets/images/shippings/'+resi.logo" alt="" width="65px;">
                                    </div>
                                    <div>
                                        <div class="font-weight-semibold letter-icon-title text-default">{{resi.courier}}</div>
                                        <div>{{resi.service}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mt-4">
                            <input v-model="resi.noresi" @keydown="error_resi=''" :class="[error_resi ? 'border-danger':'']" type="text" ref="resi" class="form-control form-control-lg text-center font-weight-bold" placeholder="Input resi disini">
                            <span v-if="error_resi" class="form-text text-danger">{{error_resi}}</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">BATAL</button>
                        <button type="submit" class="btn bg-teal">SIMPAN</button>
                    </div>
                </div>
            </div>
        </form>
        <div class="modal fade" id="printed">
            <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content vld-parent">
                    <Loading 
                        :color="'#26a69a'"
                        :loader="'spinner'"
                        :active.sync="isPrinted"
                        :is-full-page="false">
                    </Loading>
                    <div class="modal-body p-5">
                        <div class="text-center">
                            <h5 class="mb-0">PRINTING...</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import axios from 'axios'
import Paginate from '../../components/Paginate'
import swalInit from '../../sweetalert'
import VueBarcode from 'vue-barcode'
import _ from 'lodash'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    components: {
        Paginate,
        Loading,
        'barcode': VueBarcode
    },
    data () {
        return {
            isResi: false,
            isLoading: false,
            isPrinted: false,
            search : '',
            sellings: '',
            filter: 'semua',
            page:'',
            today: '',
            prints: '',
            ready: '',
            process: '',
            shippings: '',
            label: {
                type: '',
                user_id: '',
                booking_code: '',
                logo: '',
                service: '',
                invoice: '',
                to: {
                    name: '',
                    phone: '',
                    address: '',
                },
                from: {
                    name: '',
                    phone: '',
                    address: '',
                },
                dropship : {
                    name: '',
                    phone: '',
                    address: '',
                } 
            },
            details: '',
            resi: {
                id: '',
                nama: '',
                type: '',
                courier: '',
                service: '',
                logo: '',
                noresi: '',
            },
            error_resi: '',
            transfers: []
        }
    },
    mounted() {
        this.getData()
        $('#resi').on('shown.bs.modal', () => {
            this.$refs.resi.focus()
        })
    },
    computed: {
        laptransfer () {
            return _.orderBy(this.transfers,['bank','created_at'],['asc','asc'])
        },
        totaltrf () {
            let sum = 0
            this.transfers.forEach(el => {
                sum += el.total
            });
            return sum
        },
        tanggal () {
            let val =''
            if(this.transfers.length > 0) {
                val = this.transfers[this.transfers.length -1].created_at
            }
            return val
        }
    },
    methods: {
        getData (e) {
            this.isLoading = true
            this.sellings = ''
            $("html, body").animate({ scrollTop: "0" })
            axios.get('/online',{
                params: {
                    page: e,
                    search: this.search,
                    filter: this.filter
                }
            })
            .then((res) => {
                this.isLoading = false
                this.sellings = res.data.data.data
                this.today = res.data.today;
                this.prints = res.data.print
                this.ready = res.data.ready
                this.process = res.data.process
                this.shippings = res.data.ship
                this.page = res.data.data
            })
        },
        onFilter(val) {
            this.filter = val
            this.search = ''
            this.getData()
        },
        onResi(i) {
            this.resi= {
                id: this.sellings[i].id,
                nama: this.sellings[i].customer,
                type: this.sellings[i].type,
                courier: this.sellings[i].courier,
                service: this.sellings[i].service,
                logo: this.sellings[i].courier_logo,
                noresi: this.sellings[i].resi
            }
        },
        onTransfer () {
            this.isPrinted = true
            $('#printed').modal('show')
            axios.get('/online/transfer')
            .then((res) => {
                $('#printed').modal('hide')
                this.isPrinted = false
                this.transfers = res.data
                this.$nextTick(() => {
                    this.printtrfs()
                })
            })
        },
        onSubmit () {
            this.isResi = true
            if(!this.resi.noresi) {
                this.error_resi = 'Resi belum diisi'
                this.isResi = false
                this.$refs.resi.focus()
            }
            if(this.resi.noresi) {
                axios.post('/online/resi', {
                    id: this.resi.id,
                    resi: this.resi.noresi
                })
                .then(() => {
                    $('#resi').modal('hide')
                    this.isResi = false
                    this.getData()
                })
            }
        },
        onEdit(i) {
            let id = this.sellings[i].id
            let type = this.sellings[i].type
            if(type == 'Retail') {
                this.$router.push('/online/edit/'+id)
            }
            if(type == 'Dropship') {
                this.$router.push('/online/dropship/'+id)
            }
        },
        onDelete(i) {
            let id = this.sellings[i].penjualan_id
            let app = this
            swalInit.fire({
                title: 'Hapus Penjualan',
                text: "Anda yaking ingin menghapus penjualan ini?",
                type: 'question',
                showCancelButton: true,
                confirmButtonText: 'Ya hapus!',
                cancelButtonText: 'Tidak',
                showLoaderOnConfirm: true,
                preConfirm: function () {
                    return axios.post('/online/delete', {
                        id: id
                    })
                    .then(() => {
                        app.getData()
                    })
                }
            })
        },
        onPrint(i) {
            this.isPrinted = true
            $('#printed').modal('show')
            this.label = {
                type: '',
                user_id: '',
                booking_code: '',
                logo: '',
                service: '',
                invoice: '',
                to: {
                    name: '',
                    phone: '',
                    address: '',
                },
                from: {
                    name: '',
                    phone: '',
                    address: '',
                },
                dropship : {
                    name: '',
                    phone: '',
                    address: '',
                } 
            }
            this.details = ''
            if(this.sellings[i].type == 'Retail') {
                this.label.type = this.sellings[i].type
                this.label.service = this.sellings[i].service
                this.label.booking_code = this.sellings[i].booking_code
                this.label.logo = this.sellings[i].courier_logo
                this.label.invoice = this.sellings[i].invoice
                this.label.from = {
                    name: 'ZHR LEATHER',
                    phone: '85223500414',
                    address: 'GARUT'
                }
                this.label.to = {
                    name: this.sellings[i].customer,
                    phone: this.sellings[i].phone,
                    address: this.sellings[i].address + ', ' + this.sellings[i].district + ', ' + this.sellings[i].regency + ', ' + this.sellings[i].provincy+', ' + this.sellings[i].zip
                }
                let id = this.sellings[i].penjualan_id
                axios.get('/online/details/'+id)
                .then((res) => {
                    this.isPrinted = false
                    $('#printed').modal('hide')
                    this.details = res.data
                    this.$nextTick(() => {
                        this.print()
                    }) 
                })
                axios.post('/online/printed',{
                    id: this.sellings[i].id
                })
                .then(() => {
                    this.sellings[i].printed = 1
                })
            }
            if(this.sellings[i].type == 'Dropship') {
                this.label.type = this.sellings[i].type
                this.label.service = this.sellings[i].service
                this.label.booking_code = this.sellings[i].booking_code
                this.label.logo = this.sellings[i].courier_logo
                this.label.invoice = this.sellings[i].invoice
                this.label.from = {
                    name: this.sellings[i].customer,
                    phone: this.sellings[i].phone,
                    address: 'GARUT'
                }
                
                let id = this.sellings[i].penjualan_id
                axios.get('/online/details_dropship/'+id)
                .then((res) => {
                    this.isPrinted = false
                    $('#printed').modal('hide')
                    this.details = res.data.details
                    this.label.to = {
                        name: res.data.customer.customer,
                        phone: res.data.customer.phone,
                        address: res.data.customer.address + ', ' + res.data.customer.district + ', ' + res.data.customer.regency + ', ' + res.data.customer.province+', ' + res.data.customer.zip
                    }
                    this.$nextTick(() => {
                        this.print()
                    }) 
                })
                axios.post('/online/printed',{
                    id: this.sellings[i].id
                })
                .then(() => {
                    this.sellings[i].printed = 1
                })
            }
        },
        print () {
            const prtHtml = document.getElementById('print').innerHTML;

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
            stylesHtml += node.outerHTML;
            }            
            // Open the print window
            const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            
            WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body style="background: none;">
                ${prtHtml}
            </body>
            </html`);

            setTimeout(() => {
                WinPrint.document.close();
                WinPrint.focus();
                WinPrint.print();
                WinPrint.close();
            }, 1500)
        },
        printtrfs () {
            const prtHtml = document.getElementById('printTrf').innerHTML;

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
            stylesHtml += node.outerHTML;
            }            
            // Open the print window
            const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            
            WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body style="background: none;">
                ${prtHtml}
            </body>
            </html`);

            setTimeout(() => {
                WinPrint.document.close();
                WinPrint.focus();
                WinPrint.print();
                WinPrint.close();
            }, 1500)
        },
        searchData: _.debounce(function () {
            this.getData()
        }, 500)
    },
    watch: {
        search () {
            this.searchData()
        }
    }
}
</script>

<style scoped>
@media screen {
    #print {
        display: none;
        }
    #printTrf {
        display: none;
        }
    }
    .shipping:hover {
        cursor: pointer;
        background-color:#ededed;
    }
    .bg-image{
        position: relative; 
    }
    
    .bg-image::before {
        content:"";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.13;
        background-image: url('http://app.zhrleather.com/images/company.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 180px;
    }
</style>