<template>
    <div>
        <div v-if="onLoad" class="row">
            <div v-for="p in 4" :key="p" class="col-sm-6 col-xl-3 mb-3">
                <div class="alpha-slate rounded ph-item" style="height: 145px;"></div>
            </div>
        </div>
        <div v-if="onLoad == false" class="row">
            <div class="col-sm-6 col-xl-3">
                <div class="card bg-success">
                    <div class="card-body">
                        <div class="media">
                            <div class="mr-3 align-self-center">
                                <i class="icon-store icon-3x opacity-75"></i>
                            </div>

                            <div class="media-body text-right">
                                <h3 class="mb-0">{{Intl.NumberFormat('en-US').format(zhrTotal)}}</h3>
                                <span class="text-uppercase font-size-xs">ZHR LEATHER</span>
                            </div>
                        </div>
                    </div>
                    <div id="zhrchart"></div>
                </div>
            </div>
            <div class="col-sm-6 col-xl-3">
                <div class="card bg-purple">
                    <div class="card-body">
                        <div class="media">
                            <div class="mr-3 align-self-center">
                                <i class="icon-store icon-3x opacity-75"></i>
                            </div>

                            <div class="media-body text-right">
                                <h3 class="mb-0">{{Intl.NumberFormat('en-US').format(astriTotal)}}</h3>
                                <span class="text-uppercase font-size-xs">ASTRI LEATHER</span>
                            </div>
                        </div>
                    </div>
                    <div id="astrichart"></div>
                </div>
            </div>
            <div class="col-sm-6 col-xl-3">
                <div class="card bg-info">
                    <div class="card-body">
                        <div class="media">
                            <div class="mr-3 align-self-center">
                                <i class="icon-store icon-3x opacity-75"></i>
                            </div>

                            <div class="media-body text-right">
                                <h3 class="mb-0">{{Intl.NumberFormat('en-US').format(zahirTotal)}}</h3>
                                <span class="text-uppercase font-size-xs">ZAHIR LEATHER</span>
                            </div>
                        </div>
                    </div>
                    <div id="zahirchart"></div>
                </div>
            </div>
            <div class="col-sm-6 col-xl-3">
                <div class="card bg-primary">
                    <div class="card-body">
                        <div class="media">
                            <div class="mr-3 align-self-center">
                                <i class="icon-store icon-3x opacity-75"></i>
                            </div>

                            <div class="media-body text-right">
                                <h3 class="mb-0">{{Intl.NumberFormat('en-US').format(onlineTotal)}}</h3>
                                <span class="text-uppercase font-size-xs">ONLINE</span>
                            </div>
                        </div>
                    </div>
                    <div id="onlinechart"></div>
                </div>
            </div>
        </div> 

        <div class="row">
            <div class="col-md-6">
                <div class="card card-body bg-success text-center">
                    <h6>TOTAL PENDAPATAN KOTOR HARI INI</h6>
                    <h3 class="mb-0">{{Intl.NumberFormat('en-US').format(pendapatan)}}</h3>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card card-body bg-danger text-center">
                    <h6>TOTAL PENGELUARAN HARI INI</h6>
                    <h3 class="mb-0">{{Intl.NumberFormat('en-US').format(pengeluaran)}}</h3>
                </div>
            </div>
        </div>  
    </div>
</template>

<script>
import axios from 'axios'
import $ from 'jquery'
import * as d3 from '../../assets/js/d3.min.js'
import d3Tip from 'd3-tip'
d3.tip = d3Tip
export default {
    name: 'Dashboard',
    data () {
        return {
            onLoad: false,
            zhr: [],
            astri: [],
            zahir: [],
            online: [],
            pendapatan: '',
            pengeluaran: '',
        }
    },
    mounted () {
        this.getData()
    },
    computed: {
        zhrTotal () {
            let sum = 0
            this.zhr.forEach(el => {
                sum += el.total
            })
            return sum
        },
        astriTotal () {
            let sum = 0
            this.astri.forEach(el => {
                sum += el.total
            })
            return sum
        },
        onlineTotal () {
            let sum = 0
            this.online.forEach(el => {
                sum += el.total
            })
            return sum
        },
        zahirTotal () {
            let sum = 0
            this.zahir.forEach(el => {
                sum += el.total
            })
            return sum
        }
    },
    methods: {
        getData () {
            this.onLoad = true
            this.tokos = ''
            this.sales = []
            axios.get('/dashboard')
            .then(response => {
                this.onLoad = false
                this.zhr = response.data.zhr
                this.astri = response.data.astri
                this.online = response.data.online
                this.zahir = response.data.zahir
                this.pendapatan = response.data.pendapatan
                this.pengeluaran = response.data.pengeluaran
                this.$nextTick(() => {
                    this.zhrChart()
                    this.astriChart()
                    this.zahirChart()
                    this.onlineChart()
                })
            })
        },
        zhrChart () {
            let app = this
             _DailyRevenueLineChart('#zhrchart', 50);
            function _DailyRevenueLineChart(element, height) {
            if (typeof d3 == 'undefined') {
                console.warn('Warning - d3.min.js is not loaded.');
                return;
            }
    
            // Initialize chart only if element exsists in the DOM
            var revResize;
            if($(element).length > 0) {
    
    
                // Basic setup
                // ------------------------------
                var id_ID = {                    
                    "decimal": ".",
                    "thousands": ",",
                    "grouping": [3],
                    "currency": ["$", ""],
                    "dateTime": "%a %b %e %X %Y",
                    "date": "%m/%d/%Y",
                    "time": "%H:%M:%S",
                    "periods": ["AM", "PM"],
                    "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                    "shortDays": ["Ming", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
                    "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                    "shortMonths": ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"]
                }
                // Add data set
                var dataset = app.zhr;
    
                // Main variables
                var d3Container = d3.select(element),
                    margin = {top: 0, right: 0, bottom: 0, left: 0},
                    width = d3Container.node().getBoundingClientRect().width - margin.left - margin.right,
                    // height = height - margin.top - margin.bottom,
                    padding = 20;
    
                // Format date
                var loc = d3.locale(id_ID)
                var parseDate = loc.timeFormat('%Y-%m-%d').parse,
                    formatDate = loc.timeFormat('%a, %B %e');
    
                // Colors
                var lineColor = '#fff',
                    guideColor = 'rgba(255,255,255,0.3)';
    
    
    
                // Add tooltip
                // ------------------------------
    
                var tooltip = d3.tip()
                    .attr('class', 'd3-tip')
                    .html(function (d) {
                        return '<ul class="list-unstyled mb-1">' +
                            '<li>' + '<div class="font-size-base my-1"><i class="icon-calendar2 mr-2"></i>' + formatDate(d.date) + '</div>' + '</li>' +
                            '<li>' + 'Transaksi: &nbsp;' + '<span class="font-weight-semibold float-right">' + d.count + '</span>' + '</li>' +
                            '<li>' + 'Pendapatan: &nbsp;' + '<span class="font-weight-semibold float-right">' + Intl.NumberFormat('en-US').format(d.total) + '</span>' + '</li>' +
                        '</ul>';
                    });
    
    
    
                // Create chart
                // ------------------------------
    
                // Add svg element
                var container = d3Container.append('svg');
    
                // Add SVG group
                var svg = container
                        .attr('width', width + margin.left + margin.right)
                        .attr('height', height + margin.top + margin.bottom)
                        .append('g')
                            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
                            .call(tooltip);
    
    
    
                // Load data
                // ------------------------------
    
                dataset.forEach(function (d) {
                    d.date = parseDate(d.date);
                    d.count = +d.count;
                });
    
    
    
                // Construct scales
                // ------------------------------
    
                // Horizontal
                var x = d3.time.scale()
                    .range([padding, width - padding]);
    
                // Vertical
                var y = d3.scale.linear()
                    .range([height, 5]);
    
    
    
                // Set input domains
                // ------------------------------
    
                // Horizontal
                x.domain(d3.extent(dataset, function (d) {
                    return d.date;
                }));
    
                // Vertical
                y.domain([0, d3.max(dataset, function (d) {
                    return Math.max(d.count);
                })]);
    
    
    
                // Construct chart layout
                // ------------------------------
    
                // Line
                var line = d3.svg.line()
                    .x(function(d) {
                        return x(d.date);
                    })
                    .y(function(d) {
                        return y(d.count)
                    });
    
    
    
                //
                // Append chart elements
                //
    
                // Add mask for animation
                // ------------------------------
    
                // Add clip path
                var clip = svg.append('defs')
                    .append('clipPath')
                    .attr('id', 'clip-line-small');
    
                // Add clip shape
                var clipRect = clip.append('rect')
                    .attr('class', 'clip')
                    .attr('width', 0)
                    .attr('height', height);
    
                // Animate mask
                clipRect
                    .transition()
                        .duration(1000)
                        .ease('linear')
                        .attr('width', width);
    
    
    
                // Line
                // ------------------------------
    
                // Path
                svg.append('path')
                    .attr({
                        'd': line(dataset),
                        'clip-path': 'url(#clip-line-small)',
                        'class': 'd3-line d3-line-medium'
                    })
                    .style('stroke', lineColor);
    
                // Animate path
                svg.select('.line-tickets')
                    .transition()
                        .duration(1000)
                        .ease('linear');
    
    
    
                // Add vertical guide lines
                // ------------------------------
    
                // Bind data
                var guide = svg.append('g')
                    .selectAll('.d3-line-guides-group')
                    .data(dataset);
    
                // Append lines
                guide
                    .enter()
                    .append('line')
                        .attr('class', 'd3-line-guides')
                        .attr('x1', function (d) {
                            return x(d.date);
                        })
                        .attr('y1', function () {
                            return height;
                        })
                        .attr('x2', function (d) {
                            return x(d.date);
                        })
                        .attr('y2', function () {
                            return height;
                        })
                        .style('stroke', guideColor)
                        .style('stroke-dasharray', '4,2')
                        .style('shape-rendering', 'crispEdges');
    
                // Animate guide lines
                guide
                    .transition()
                        .duration(1000)
                        .delay(function(d, i) { return i * 150; })
                        .attr('y2', function (d) {
                            return y(d.count);
                        });
    
    
    
                // Alpha app points
                // ------------------------------
    
                // Add points
                var points = svg.insert('g')
                    .selectAll('.d3-line-circle')
                    .data(dataset)
                    .enter()
                    .append('circle')
                        .attr('class', 'd3-line-circle d3-line-circle-medium')
                        .attr('cx', line.x())
                        .attr('cy', line.y())
                        .attr('r', 3)
                        .style('stroke', lineColor)
                        .style('fill', lineColor);
    
    
    
                // Animate points on page load
                points
                    .style('opacity', 0)
                    .transition()
                        .duration(250)
                        .ease('linear')
                        .delay(1000)
                        .style('opacity', 1);
    
    
                // Add user interaction
                points
                    .on('mouseover', function (d) {
                        tooltip.offset([-10, 0]).show(d, this);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 4);
                    })
    
                    // Hide tooltip
                    .on('mouseout', function (d) {
                        tooltip.hide(d);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 3);
                    });
    
                // Change tooltip direction of first point
                d3.select(points[0][0])
                    .on('mouseover', function (d) {
                        tooltip.offset([0, 10]).direction('e').show(d, this);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 4);
                    })
                    .on('mouseout', function (d) {
                        tooltip.direction('n').hide(d);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 3);
                    });
    
                // Change tooltip direction of last point
                d3.select(points[0][points.size() - 1])
                    .on('mouseover', function (d) {
                        tooltip.offset([0, -10]).direction('w').show(d, this);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 4);
                    })
                    .on('mouseout', function (d) {
                        tooltip.direction('n').hide(d);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 3);
                    })
    
    
    
                // Resize chart
                // ------------------------------
    
                // Call function on window resize
                window.addEventListener('resize', revResize);
    
                // Call function on sidebar width change
                var sidebarToggle = document.querySelector('.sidebar-control');
                sidebarToggle && sidebarToggle.addEventListener('click', revResize);
    
                // Resize function
                // 
                // Since D3 doesn't support SVG resize by default,
                // we need to manually specify parts of the graph that need to 
                // be updated on window resize
                revResize = function revenueResize() {
    
                    // Layout variables
                    width = d3Container.node().getBoundingClientRect().width - margin.left - margin.right;
    
    
                    // Layout
                    // -------------------------
    
                    // Main svg width
                    container.attr('width', width + margin.left + margin.right);
    
                    // Width of appended group
                    svg.attr('width', width + margin.left + margin.right);
    
                    // Horizontal range
                    x.range([padding, width - padding]);
    
    
                    // Chart elements
                    // -------------------------
    
                    // Mask
                    clipRect.attr('width', width);
    
                    // Line path
                    svg.selectAll('.d3-line').attr('d', line(dataset));
    
                    // Circles
                    svg.selectAll('.d3-line-circle').attr('cx', line.x());
    
                    // Guide lines
                    svg.selectAll('.d3-line-guides')
                        .attr('x1', function (d) {
                            return x(d.date);
                        })
                        .attr('x2', function (d) {
                            return x(d.date);
                        });
                    }
                }
            }
        },
        astriChart () {
            let app = this
             _DailyRevenueLineChart('#astrichart', 50);
            function _DailyRevenueLineChart(element, height) {
            if (typeof d3 == 'undefined') {
                console.warn('Warning - d3.min.js is not loaded.');
                return;
            }
    
            // Initialize chart only if element exsists in the DOM
            var revResize;
            if($(element).length > 0) {
    
    
                // Basic setup
                // ------------------------------
                var id_ID = {                    
                    "decimal": ".",
                    "thousands": ",",
                    "grouping": [3],
                    "currency": ["$", ""],
                    "dateTime": "%a %b %e %X %Y",
                    "date": "%m/%d/%Y",
                    "time": "%H:%M:%S",
                    "periods": ["AM", "PM"],
                    "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                    "shortDays": ["Ming", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
                    "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                    "shortMonths": ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"]
                }
                // Add data set
                var dataset = app.astri;
    
                // Main variables
                var d3Container = d3.select(element),
                    margin = {top: 0, right: 0, bottom: 0, left: 0},
                    width = d3Container.node().getBoundingClientRect().width - margin.left - margin.right,
                    // height = height - margin.top - margin.bottom,
                    padding = 20;
    
                // Format date
                var loc = d3.locale(id_ID)
                var parseDate = loc.timeFormat('%Y-%m-%d').parse,
                    formatDate = loc.timeFormat('%a, %B %e');
    
                // Colors
                var lineColor = '#fff',
                    guideColor = 'rgba(255,255,255,0.3)';
    
    
    
                // Add tooltip
                // ------------------------------
    
                var tooltip = d3.tip()
                    .attr('class', 'd3-tip')
                    .html(function (d) {
                        return '<ul class="list-unstyled mb-1">' +
                            '<li>' + '<div class="font-size-base my-1"><i class="icon-calendar2 mr-2"></i>' + formatDate(d.date) + '</div>' + '</li>' +
                            '<li>' + 'Transaksi: &nbsp;' + '<span class="font-weight-semibold float-right">' + d.count + '</span>' + '</li>' +
                            '<li>' + 'Pendapatan: &nbsp;' + '<span class="font-weight-semibold float-right">' + Intl.NumberFormat('en-US').format(d.total) + '</span>' + '</li>' +
                        '</ul>';
                    });
    
    
    
                // Create chart
                // ------------------------------
    
                // Add svg element
                var container = d3Container.append('svg');
    
                // Add SVG group
                var svg = container
                        .attr('width', width + margin.left + margin.right)
                        .attr('height', height + margin.top + margin.bottom)
                        .append('g')
                            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
                            .call(tooltip);
    
    
    
                // Load data
                // ------------------------------
    
                dataset.forEach(function (d) {
                    d.date = parseDate(d.date);
                    d.count = +d.count;
                });
    
    
    
                // Construct scales
                // ------------------------------
    
                // Horizontal
                var x = d3.time.scale()
                    .range([padding, width - padding]);
    
                // Vertical
                var y = d3.scale.linear()
                    .range([height, 5]);
    
    
    
                // Set input domains
                // ------------------------------
    
                // Horizontal
                x.domain(d3.extent(dataset, function (d) {
                    return d.date;
                }));
    
                // Vertical
                y.domain([0, d3.max(dataset, function (d) {
                    return Math.max(d.count);
                })]);
    
    
    
                // Construct chart layout
                // ------------------------------
    
                // Line
                var line = d3.svg.line()
                    .x(function(d) {
                        return x(d.date);
                    })
                    .y(function(d) {
                        return y(d.count)
                    });
    
    
    
                //
                // Append chart elements
                //
    
                // Add mask for animation
                // ------------------------------
    
                // Add clip path
                var clip = svg.append('defs')
                    .append('clipPath')
                    .attr('id', 'clip-line-small');
    
                // Add clip shape
                var clipRect = clip.append('rect')
                    .attr('class', 'clip')
                    .attr('width', 0)
                    .attr('height', height);
    
                // Animate mask
                clipRect
                    .transition()
                        .duration(1000)
                        .ease('linear')
                        .attr('width', width);
    
    
    
                // Line
                // ------------------------------
    
                // Path
                svg.append('path')
                    .attr({
                        'd': line(dataset),
                        'clip-path': 'url(#clip-line-small)',
                        'class': 'd3-line d3-line-medium'
                    })
                    .style('stroke', lineColor);
    
                // Animate path
                svg.select('.line-tickets')
                    .transition()
                        .duration(1000)
                        .ease('linear');
    
    
    
                // Add vertical guide lines
                // ------------------------------
    
                // Bind data
                var guide = svg.append('g')
                    .selectAll('.d3-line-guides-group')
                    .data(dataset);
    
                // Append lines
                guide
                    .enter()
                    .append('line')
                        .attr('class', 'd3-line-guides')
                        .attr('x1', function (d) {
                            return x(d.date);
                        })
                        .attr('y1', function () {
                            return height;
                        })
                        .attr('x2', function (d) {
                            return x(d.date);
                        })
                        .attr('y2', function () {
                            return height;
                        })
                        .style('stroke', guideColor)
                        .style('stroke-dasharray', '4,2')
                        .style('shape-rendering', 'crispEdges');
    
                // Animate guide lines
                guide
                    .transition()
                        .duration(1000)
                        .delay(function(d, i) { return i * 150; })
                        .attr('y2', function (d) {
                            return y(d.count);
                        });
    
    
    
                // Alpha app points
                // ------------------------------
    
                // Add points
                var points = svg.insert('g')
                    .selectAll('.d3-line-circle')
                    .data(dataset)
                    .enter()
                    .append('circle')
                        .attr('class', 'd3-line-circle d3-line-circle-medium')
                        .attr('cx', line.x())
                        .attr('cy', line.y())
                        .attr('r', 3)
                        .style('stroke', lineColor)
                        .style('fill', lineColor);
    
    
    
                // Animate points on page load
                points
                    .style('opacity', 0)
                    .transition()
                        .duration(250)
                        .ease('linear')
                        .delay(1000)
                        .style('opacity', 1);
    
    
                // Add user interaction
                points
                    .on('mouseover', function (d) {
                        tooltip.offset([-10, 0]).show(d, this);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 4);
                    })
    
                    // Hide tooltip
                    .on('mouseout', function (d) {
                        tooltip.hide(d);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 3);
                    });
    
                // Change tooltip direction of first point
                d3.select(points[0][0])
                    .on('mouseover', function (d) {
                        tooltip.offset([0, 10]).direction('e').show(d, this);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 4);
                    })
                    .on('mouseout', function (d) {
                        tooltip.direction('n').hide(d);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 3);
                    });
    
                // Change tooltip direction of last point
                d3.select(points[0][points.size() - 1])
                    .on('mouseover', function (d) {
                        tooltip.offset([0, -10]).direction('w').show(d, this);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 4);
                    })
                    .on('mouseout', function (d) {
                        tooltip.direction('n').hide(d);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 3);
                    })
    
    
    
                // Resize chart
                // ------------------------------
    
                // Call function on window resize
                window.addEventListener('resize', revResize);
    
                // Call function on sidebar width change
                var sidebarToggle = document.querySelector('.sidebar-control');
                sidebarToggle && sidebarToggle.addEventListener('click', revResize);
    
                // Resize function
                // 
                // Since D3 doesn't support SVG resize by default,
                // we need to manually specify parts of the graph that need to 
                // be updated on window resize
                revResize = function revenueResize() {
    
                    // Layout variables
                    width = d3Container.node().getBoundingClientRect().width - margin.left - margin.right;
    
    
                    // Layout
                    // -------------------------
    
                    // Main svg width
                    container.attr('width', width + margin.left + margin.right);
    
                    // Width of appended group
                    svg.attr('width', width + margin.left + margin.right);
    
                    // Horizontal range
                    x.range([padding, width - padding]);
    
    
                    // Chart elements
                    // -------------------------
    
                    // Mask
                    clipRect.attr('width', width);
    
                    // Line path
                    svg.selectAll('.d3-line').attr('d', line(dataset));
    
                    // Circles
                    svg.selectAll('.d3-line-circle').attr('cx', line.x());
    
                    // Guide lines
                    svg.selectAll('.d3-line-guides')
                        .attr('x1', function (d) {
                            return x(d.date);
                        })
                        .attr('x2', function (d) {
                            return x(d.date);
                        });
                    }
                }
            }
        },
        zahirChart () {
            let app = this
             _DailyRevenueLineChart('#zahirchart', 50);
            function _DailyRevenueLineChart(element, height) {
            if (typeof d3 == 'undefined') {
                console.warn('Warning - d3.min.js is not loaded.');
                return;
            }
    
            // Initialize chart only if element exsists in the DOM
            var revResize;
            if($(element).length > 0) {
    
    
                // Basic setup
                // ------------------------------
                var id_ID = {                    
                    "decimal": ".",
                    "thousands": ",",
                    "grouping": [3],
                    "currency": ["$", ""],
                    "dateTime": "%a %b %e %X %Y",
                    "date": "%m/%d/%Y",
                    "time": "%H:%M:%S",
                    "periods": ["AM", "PM"],
                    "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                    "shortDays": ["Ming", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
                    "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                    "shortMonths": ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"]
                }
                // Add data set
                var dataset = app.zahir;
    
                // Main variables
                var d3Container = d3.select(element),
                    margin = {top: 0, right: 0, bottom: 0, left: 0},
                    width = d3Container.node().getBoundingClientRect().width - margin.left - margin.right,
                    // height = height - margin.top - margin.bottom,
                    padding = 20;
    
                // Format date
                var loc = d3.locale(id_ID)
                var parseDate = loc.timeFormat('%Y-%m-%d').parse,
                    formatDate = loc.timeFormat('%a, %B %e');
    
                // Colors
                var lineColor = '#fff',
                    guideColor = 'rgba(255,255,255,0.3)';
    
    
    
                // Add tooltip
                // ------------------------------
    
                var tooltip = d3.tip()
                    .attr('class', 'd3-tip')
                    .html(function (d) {
                        return '<ul class="list-unstyled mb-1">' +
                            '<li>' + '<div class="font-size-base my-1"><i class="icon-calendar2 mr-2"></i>' + formatDate(d.date) + '</div>' + '</li>' +
                            '<li>' + 'Transaksi: &nbsp;' + '<span class="font-weight-semibold float-right">' + d.count + '</span>' + '</li>' +
                            '<li>' + 'Pendapatan: &nbsp;' + '<span class="font-weight-semibold float-right">' + Intl.NumberFormat('en-US').format(d.total) + '</span>' + '</li>' +
                        '</ul>';
                    });
    
    
    
                // Create chart
                // ------------------------------
    
                // Add svg element
                var container = d3Container.append('svg');
    
                // Add SVG group
                var svg = container
                        .attr('width', width + margin.left + margin.right)
                        .attr('height', height + margin.top + margin.bottom)
                        .append('g')
                            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
                            .call(tooltip);
    
    
    
                // Load data
                // ------------------------------
    
                dataset.forEach(function (d) {
                    d.date = parseDate(d.date);
                    d.count = +d.count;
                });
    
    
    
                // Construct scales
                // ------------------------------
    
                // Horizontal
                var x = d3.time.scale()
                    .range([padding, width - padding]);
    
                // Vertical
                var y = d3.scale.linear()
                    .range([height, 5]);
    
    
    
                // Set input domains
                // ------------------------------
    
                // Horizontal
                x.domain(d3.extent(dataset, function (d) {
                    return d.date;
                }));
    
                // Vertical
                y.domain([0, d3.max(dataset, function (d) {
                    return Math.max(d.count);
                })]);
    
    
    
                // Construct chart layout
                // ------------------------------
    
                // Line
                var line = d3.svg.line()
                    .x(function(d) {
                        return x(d.date);
                    })
                    .y(function(d) {
                        return y(d.count)
                    });
    
    
    
                //
                // Append chart elements
                //
    
                // Add mask for animation
                // ------------------------------
    
                // Add clip path
                var clip = svg.append('defs')
                    .append('clipPath')
                    .attr('id', 'clip-line-small');
    
                // Add clip shape
                var clipRect = clip.append('rect')
                    .attr('class', 'clip')
                    .attr('width', 0)
                    .attr('height', height);
    
                // Animate mask
                clipRect
                    .transition()
                        .duration(1000)
                        .ease('linear')
                        .attr('width', width);
    
    
    
                // Line
                // ------------------------------
    
                // Path
                svg.append('path')
                    .attr({
                        'd': line(dataset),
                        'clip-path': 'url(#clip-line-small)',
                        'class': 'd3-line d3-line-medium'
                    })
                    .style('stroke', lineColor);
    
                // Animate path
                svg.select('.line-tickets')
                    .transition()
                        .duration(1000)
                        .ease('linear');
    
    
    
                // Add vertical guide lines
                // ------------------------------
    
                // Bind data
                var guide = svg.append('g')
                    .selectAll('.d3-line-guides-group')
                    .data(dataset);
    
                // Append lines
                guide
                    .enter()
                    .append('line')
                        .attr('class', 'd3-line-guides')
                        .attr('x1', function (d) {
                            return x(d.date);
                        })
                        .attr('y1', function () {
                            return height;
                        })
                        .attr('x2', function (d) {
                            return x(d.date);
                        })
                        .attr('y2', function () {
                            return height;
                        })
                        .style('stroke', guideColor)
                        .style('stroke-dasharray', '4,2')
                        .style('shape-rendering', 'crispEdges');
    
                // Animate guide lines
                guide
                    .transition()
                        .duration(1000)
                        .delay(function(d, i) { return i * 150; })
                        .attr('y2', function (d) {
                            return y(d.count);
                        });
    
    
    
                // Alpha app points
                // ------------------------------
    
                // Add points
                var points = svg.insert('g')
                    .selectAll('.d3-line-circle')
                    .data(dataset)
                    .enter()
                    .append('circle')
                        .attr('class', 'd3-line-circle d3-line-circle-medium')
                        .attr('cx', line.x())
                        .attr('cy', line.y())
                        .attr('r', 3)
                        .style('stroke', lineColor)
                        .style('fill', lineColor);
    
    
    
                // Animate points on page load
                points
                    .style('opacity', 0)
                    .transition()
                        .duration(250)
                        .ease('linear')
                        .delay(1000)
                        .style('opacity', 1);
    
    
                // Add user interaction
                points
                    .on('mouseover', function (d) {
                        tooltip.offset([-10, 0]).show(d, this);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 4);
                    })
    
                    // Hide tooltip
                    .on('mouseout', function (d) {
                        tooltip.hide(d);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 3);
                    });
    
                // Change tooltip direction of first point
                d3.select(points[0][0])
                    .on('mouseover', function (d) {
                        tooltip.offset([0, 10]).direction('e').show(d, this);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 4);
                    })
                    .on('mouseout', function (d) {
                        tooltip.direction('n').hide(d);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 3);
                    });
    
                // Change tooltip direction of last point
                d3.select(points[0][points.size() - 1])
                    .on('mouseover', function (d) {
                        tooltip.offset([0, -10]).direction('w').show(d, this);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 4);
                    })
                    .on('mouseout', function (d) {
                        tooltip.direction('n').hide(d);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 3);
                    })
    
    
    
                // Resize chart
                // ------------------------------
    
                // Call function on window resize
                window.addEventListener('resize', revResize);
    
                // Call function on sidebar width change
                var sidebarToggle = document.querySelector('.sidebar-control');
                sidebarToggle && sidebarToggle.addEventListener('click', revResize);
    
                // Resize function
                // 
                // Since D3 doesn't support SVG resize by default,
                // we need to manually specify parts of the graph that need to 
                // be updated on window resize
                revResize = function revenueResize() {
    
                    // Layout variables
                    width = d3Container.node().getBoundingClientRect().width - margin.left - margin.right;
    
    
                    // Layout
                    // -------------------------
    
                    // Main svg width
                    container.attr('width', width + margin.left + margin.right);
    
                    // Width of appended group
                    svg.attr('width', width + margin.left + margin.right);
    
                    // Horizontal range
                    x.range([padding, width - padding]);
    
    
                    // Chart elements
                    // -------------------------
    
                    // Mask
                    clipRect.attr('width', width);
    
                    // Line path
                    svg.selectAll('.d3-line').attr('d', line(dataset));
    
                    // Circles
                    svg.selectAll('.d3-line-circle').attr('cx', line.x());
    
                    // Guide lines
                    svg.selectAll('.d3-line-guides')
                        .attr('x1', function (d) {
                            return x(d.date);
                        })
                        .attr('x2', function (d) {
                            return x(d.date);
                        });
                    }
                }
            }
        },
        onlineChart () {
            let app = this
             _DailyRevenueLineChart('#onlinechart', 50);
            function _DailyRevenueLineChart(element, height) {
            if (typeof d3 == 'undefined') {
                console.warn('Warning - d3.min.js is not loaded.');
                return;
            }
    
            // Initialize chart only if element exsists in the DOM
            var revResize;
            if($(element).length > 0) {
    
    
                // Basic setup
                // ------------------------------
                var id_ID = {                    
                    "decimal": ".",
                    "thousands": ",",
                    "grouping": [3],
                    "currency": ["$", ""],
                    "dateTime": "%a %b %e %X %Y",
                    "date": "%m/%d/%Y",
                    "time": "%H:%M:%S",
                    "periods": ["AM", "PM"],
                    "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                    "shortDays": ["Ming", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
                    "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                    "shortMonths": ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"]
                }
                // Add data set
                var dataset = app.online;
    
                // Main variables
                var d3Container = d3.select(element),
                    margin = {top: 0, right: 0, bottom: 0, left: 0},
                    width = d3Container.node().getBoundingClientRect().width - margin.left - margin.right,
                    // height = height - margin.top - margin.bottom,
                    padding = 20;
    
                // Format date
                var loc = d3.locale(id_ID)
                var parseDate = loc.timeFormat('%Y-%m-%d').parse,
                    formatDate = loc.timeFormat('%a, %B %e');
    
                // Colors
                var lineColor = '#fff',
                    guideColor = 'rgba(255,255,255,0.3)';
    
    
    
                // Add tooltip
                // ------------------------------
    
                var tooltip = d3.tip()
                    .attr('class', 'd3-tip')
                    .html(function (d) {
                        return '<ul class="list-unstyled mb-1">' +
                            '<li>' + '<div class="font-size-base my-1"><i class="icon-calendar2 mr-2"></i>' + formatDate(d.date) + '</div>' + '</li>' +
                            '<li>' + 'Transaksi: &nbsp;' + '<span class="font-weight-semibold float-right">' + d.count + '</span>' + '</li>' +
                            '<li>' + 'Pendapatan: &nbsp;' + '<span class="font-weight-semibold float-right">' + Intl.NumberFormat('en-US').format(d.total) + '</span>' + '</li>' +
                        '</ul>';
                    });
    
    
    
                // Create chart
                // ------------------------------
    
                // Add svg element
                var container = d3Container.append('svg');
    
                // Add SVG group
                var svg = container
                        .attr('width', width + margin.left + margin.right)
                        .attr('height', height + margin.top + margin.bottom)
                        .append('g')
                            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
                            .call(tooltip);
    
    
    
                // Load data
                // ------------------------------
    
                dataset.forEach(function (d) {
                    d.date = parseDate(d.date);
                    d.count = +d.count;
                });
    
    
    
                // Construct scales
                // ------------------------------
    
                // Horizontal
                var x = d3.time.scale()
                    .range([padding, width - padding]);
    
                // Vertical
                var y = d3.scale.linear()
                    .range([height, 5]);
    
    
    
                // Set input domains
                // ------------------------------
    
                // Horizontal
                x.domain(d3.extent(dataset, function (d) {
                    return d.date;
                }));
    
                // Vertical
                y.domain([0, d3.max(dataset, function (d) {
                    return Math.max(d.count);
                })]);
    
    
    
                // Construct chart layout
                // ------------------------------
    
                // Line
                var line = d3.svg.line()
                    .x(function(d) {
                        return x(d.date);
                    })
                    .y(function(d) {
                        return y(d.count)
                    });
    
    
    
                //
                // Append chart elements
                //
    
                // Add mask for animation
                // ------------------------------
    
                // Add clip path
                var clip = svg.append('defs')
                    .append('clipPath')
                    .attr('id', 'clip-line-small');
    
                // Add clip shape
                var clipRect = clip.append('rect')
                    .attr('class', 'clip')
                    .attr('width', 0)
                    .attr('height', height);
    
                // Animate mask
                clipRect
                    .transition()
                        .duration(1000)
                        .ease('linear')
                        .attr('width', width);
    
    
    
                // Line
                // ------------------------------
    
                // Path
                svg.append('path')
                    .attr({
                        'd': line(dataset),
                        'clip-path': 'url(#clip-line-small)',
                        'class': 'd3-line d3-line-medium'
                    })
                    .style('stroke', lineColor);
    
                // Animate path
                svg.select('.line-tickets')
                    .transition()
                        .duration(1000)
                        .ease('linear');
    
    
    
                // Add vertical guide lines
                // ------------------------------
    
                // Bind data
                var guide = svg.append('g')
                    .selectAll('.d3-line-guides-group')
                    .data(dataset);
    
                // Append lines
                guide
                    .enter()
                    .append('line')
                        .attr('class', 'd3-line-guides')
                        .attr('x1', function (d) {
                            return x(d.date);
                        })
                        .attr('y1', function () {
                            return height;
                        })
                        .attr('x2', function (d) {
                            return x(d.date);
                        })
                        .attr('y2', function () {
                            return height;
                        })
                        .style('stroke', guideColor)
                        .style('stroke-dasharray', '4,2')
                        .style('shape-rendering', 'crispEdges');
    
                // Animate guide lines
                guide
                    .transition()
                        .duration(1000)
                        .delay(function(d, i) { return i * 150; })
                        .attr('y2', function (d) {
                            return y(d.count);
                        });
    
    
    
                // Alpha app points
                // ------------------------------
    
                // Add points
                var points = svg.insert('g')
                    .selectAll('.d3-line-circle')
                    .data(dataset)
                    .enter()
                    .append('circle')
                        .attr('class', 'd3-line-circle d3-line-circle-medium')
                        .attr('cx', line.x())
                        .attr('cy', line.y())
                        .attr('r', 3)
                        .style('stroke', lineColor)
                        .style('fill', lineColor);
    
    
    
                // Animate points on page load
                points
                    .style('opacity', 0)
                    .transition()
                        .duration(250)
                        .ease('linear')
                        .delay(1000)
                        .style('opacity', 1);
    
    
                // Add user interaction
                points
                    .on('mouseover', function (d) {
                        tooltip.offset([-10, 0]).show(d, this);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 4);
                    })
    
                    // Hide tooltip
                    .on('mouseout', function (d) {
                        tooltip.hide(d);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 3);
                    });
    
                // Change tooltip direction of first point
                d3.select(points[0][0])
                    .on('mouseover', function (d) {
                        tooltip.offset([0, 10]).direction('e').show(d, this);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 4);
                    })
                    .on('mouseout', function (d) {
                        tooltip.direction('n').hide(d);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 3);
                    });
    
                // Change tooltip direction of last point
                d3.select(points[0][points.size() - 1])
                    .on('mouseover', function (d) {
                        tooltip.offset([0, -10]).direction('w').show(d, this);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 4);
                    })
                    .on('mouseout', function (d) {
                        tooltip.direction('n').hide(d);
    
                        // Animate circle radius
                        d3.select(this).transition().duration(250).attr('r', 3);
                    })
    
    
    
                // Resize chart
                // ------------------------------
    
                // Call function on window resize
                window.addEventListener('resize', revResize);
    
                // Call function on sidebar width change
                var sidebarToggle = document.querySelector('.sidebar-control');
                sidebarToggle && sidebarToggle.addEventListener('click', revResize);
    
                // Resize function
                // 
                // Since D3 doesn't support SVG resize by default,
                // we need to manually specify parts of the graph that need to 
                // be updated on window resize
                revResize = function revenueResize() {
    
                    // Layout variables
                    width = d3Container.node().getBoundingClientRect().width - margin.left - margin.right;
    
    
                    // Layout
                    // -------------------------
    
                    // Main svg width
                    container.attr('width', width + margin.left + margin.right);
    
                    // Width of appended group
                    svg.attr('width', width + margin.left + margin.right);
    
                    // Horizontal range
                    x.range([padding, width - padding]);
    
    
                    // Chart elements
                    // -------------------------
    
                    // Mask
                    clipRect.attr('width', width);
    
                    // Line path
                    svg.selectAll('.d3-line').attr('d', line(dataset));
    
                    // Circles
                    svg.selectAll('.d3-line-circle').attr('cx', line.x());
    
                    // Guide lines
                    svg.selectAll('.d3-line-guides')
                        .attr('x1', function (d) {
                            return x(d.date);
                        })
                        .attr('x2', function (d) {
                            return x(d.date);
                        });
                    }
                }
            }
        }
    }
}
</script>
