import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Toasted from 'vue-toasted'
import VueCroppie from 'vue-croppie'
import 'croppie/croppie.css'
import VueHtmlToPaper from 'vue-html-to-paper';

axios.defaults.baseURL ='http://api.zhrleather.com/api'

require('@/bootstrap')
require('@/store/subscribe')

require('./assets/css/bootstrap.css')
require('./assets/css/bootstrap_limitless.css')
require('./assets/css/icons/icomoon/styles.min.css')
require('./assets/css/extras/animate.min.css')
require('./assets/css/colors.css')
require('./assets/css/components.css')
require('./assets/css/datepicker.css')
require('./assets/css/fileuploader.css')
require('./assets/css/jquery.fileuploader-theme-gallery.css')
require('./assets/css/layout.css')
require('./assets/css/placeholder.css')

const left = (screen.width/2)-(900/2); 
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes',
    'resizable=no',
    'left='+left,
    'width=900',
    'height=900'
  ],
  styles: [
    '/assets/css/struk.css',    
  ]
};

Vue.use(VueHtmlToPaper, options);

Vue.config.productionTip = false
Vue.use(Toasted)
Vue.use(VueCroppie)

store.dispatch('auth/attempt', localStorage.getItem('token'))
.then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
