<template>
	<input type="checkbox" :value="val" v-model="checked" @change="change">
</template>

<script>
    import $ from 'jquery'
	export default {
        name: 'checkbox',
		props: ['value','val', 'wrapperclass'],
		data () {
			return {
				checkedProxy: false
			}
		},
		mounted: function() {
            $(this.$el).uniform()
		},
		computed: {
			checked: {
                get () {
                    return this.value
                },
                set (val) {
                    this.checkedProxy = val
                }
			}			
		},
		methods: {
			change: function () {
				this.$emit('input', this.checkedProxy)
			}
		},
		watch : {
			checked: function() {
				$.uniform.update()
			},
			wrapperclass: function() {
				$(this.$el).uniform({
					wrapperClass: 'border-warning'
				})
				$.uniform.update()
			}			
		}
	}
</script>