<template>
    <div>
        <template v-if="auth == null">
            <NavbarLogin/>
        </template>

        <template v-if="auth">
            <Navbar/>
        </template>

        <div class="page-content" style="min-height:750px;">
            <template v-if="auth">
                <Sidebar/>
            </template>
            <div class="content-wrapper">
                <template v-if="auth">
                    <PageHeader/>   
                </template>
                <div class="content">
                    <transition name="fade" mode="out-in">
                        <router-view></router-view>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NavbarLogin from '@/components/NavbarLogin'
import Navbar from '@/components/Navbar'
import Sidebar from '@/components/Sidebar'
import PageHeader from '@/components/PageHeader'
import { mapGetters } from 'vuex'
export default {
    name: 'Home',
    components: {
        NavbarLogin,
        Navbar,
        Sidebar,
        PageHeader
    },
    computed: {
       ...mapGetters({
           auth: 'auth/authenticated',
       }),
    }, 
}
</script>

<style>
    @import url("https://fonts.googleapis.com/css?family=Roboto:400,300,100,500,700,900");
    .nav-tabs-bottom a.nav-link.active {
        color: #26a69a !important;
    }
</style>
