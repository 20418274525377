<template>
    <div>
        <div class="card">
            <div class="card-header bg-white header-elements-inline">
                <h6 class="card-title">SELLER</h6>
                <div class="header-elements">
                    <button @click="onAdd" type="button" class="btn bg-teal-400 btn-labeled btn-labeled-left" data-toggle="modal" data-target="#modal"><b><i class="icon-plus-circle2"></i></b>Tambah Seller</button>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <form >
                            <div class="form-group mb-0 form-group-feedback form-group-feedback-right">
                                <input v-model="search" type="search" class="form-control" placeholder="Cari nama seller">
                                <div class="form-control-feedback">
                                    <i class="icon-search4 font-size-base text-muted"></i>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <table class="table">
                <thead class="bg-orange">
                    <tr>
                        <td style="width: 25%">Nama</td>
                        <td style="width: 25%">Kontak</td>
                        <td style="width: 30%">Alamat</td>
                        <td class="text-right" style="width: 10%">Tagihan</td>
                        <td class="text-center" style="width: 10%">Aksi</td>
                    </tr>
                </thead>
                <tbody v-if="onLoad">
                    <tr v-for="p in 10" :key="p">
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td><div class="alpha-slate p-1 ph-item"></div></td>
                        <td>
                            <div class="d-flex justify-content-center">
                                <div class="alpha-slate p-2 col-1 ph-item"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr v-for="(item, i) in sellers" :key="i">
                        <td>{{item.nama_seller}}</td>
                        <td>{{item.kontak}}</td>
                        <td>{{item.alamat}}</td>
                        <td class="text-right">{{Intl.NumberFormat('en-US').format(item.sisa)}}</td>
                        <td class="text-center">
                            <a @click.prevent="onEdit(i)" href="#" data-toggle="modal" data-target="#modal"><i class="icon-pencil5 text-primary"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="card-footer d-flex p-3 ">
                <div class="align-self-center">
                    <span class="text-muted">Total barang: {{Intl.NumberFormat('en-US').format(page.total)}}</span>
                </div>
                <div class="ml-auto">
                    <Paginate :pages="page" @get-page="getData($event)" />
                </div>
            </div>
        </div>

        <form @submit.prevent="onSubmit" class="modal fade right" id="modal">
            <div class="modal-dialog vld-parent">
                <Loading 
                    :color="'#26a69a'"
                    :loader="'spinner'"
                    :active.sync="isLoading"
                    :is-full-page="false">
                </Loading>
                <div class="modal-content">
                    <div class="modal-header p-3">
                        <h5 class="modal-title">{{modal_title}}</h5>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Nama Seller <span class="text-danger">*</span></label>
                            <input ref="nama_seller" v-model="form.nama_seller" @keydown="error.nama_seller=''" :class="[error.nama_seller ? 'border-danger':'']" type="text" class="form-control" placeholder="Nama Selelr">
                            <span v-if="error.nama_seller" class="form-text text-danger font-size-sm">{{error.nama_seller}}</span>
                        </div>
                        <div class="form-group">
                            <label>Kontak / No HP</label>
                            <input v-model="form.kontak" type="text" class="form-control" placeholder="No HP">
                        </div>
                        <div class="form-group">
                            <label>Alamat</label>
                            <textarea v-model="form.alamat" rows="3" class="form-control"></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">BATAL</button>
                        <button type="submit" class="btn bg-teal" :disabled="isLoading">SIMPAN</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import $ from 'jquery'
import axios from 'axios'
import Paginate from '../../../components/Paginate'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    components: {
        Paginate,
        Loading
    },
    data () {
        return {
            onLoad: false,
            isLoading: false,
            search: '',
            page: '',
            sellers: '',
            modal_title: '',
            modal_mode: '',
            form: {
                nama_seller: '',
                kontak: '',
                alamat: ''
            },
            error: {
                nama_seller: '',
            }
        }
    },
    mounted () {
        let app = this
        this.getData()
        $('#modal').on('shown.bs.modal', () => {
            app.$refs.nama_seller.focus()
        })
    },
    methods: {
        getData (e) {
            this.onLoad = true
            this.sellers = ''
            $("html, body").animate({ scrollTop: "0" })
            axios.get('/seller', {
                params: {
                    page: e,
                    search: this.search
                }
            })
            .then((response) => {
                this.onLoad = false
                this.sellers = response.data.data
                this.page = response.data
            })
        },
        onAdd () {
            this.modal_title = 'TAMBAH SELLER BARU'
            this.modal_mode = 'new'
            this.form= {
                nama_seller: '',
                kontak: '',
                alamat: ''
            }
            this.error= {
                nama_seller: '',
            }
        },
        onEdit (i) {
            this.modal_title = 'EDIT SELLER'
            this.modal_mode = 'edit'
            this.form= {
                id: this.sellers[i].id,
                nama_seller: this.sellers[i].nama_seller,
                kontak: this.sellers[i].kontak,
                alamat: this.sellers[i].alamat
            }
            this.error= {
                nama_seller: '',
            }
        },
        onSubmit () {
            if(!this.form.nama_seller) {
                this.error.nama_seller = 'Nama seller belum diisi'
            }
            if(this.form.nama_seller) {
                this.isLoading = true
                if(this.modal_mode == 'new') {
                    axios.post('/seller/create', this.form)
                    .then((response) => {
                        this.isLoading = false
                        if(response.data.error) {
                            this.error.nama_seller = response.data.error
                        } else {
                            $('#modal').modal('hide')
                            this.getData()
                        }
                    })
                }
                if(this.modal_mode == 'edit') {
                    axios.post('/seller/update', this.form)
                    .then((response) => {
                        this.isLoading = false
                        if(response.data.error) {
                            this.error.nama_seller = response.data.error
                        } else {
                            $('#modal').modal('hide')
                            this.getData()
                        }
                    })
                }
            }
        }
    }
}
</script>