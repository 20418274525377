import axios from 'axios'

export default {
    namespaced: true,
    state: {
        token: null,
        user: null,
        toko: null,
    },
    getters: {
        authenticated (state) {
            return state.token
        },
        user (state) {
            return state.user
        },
        toko (state) {
            return state.toko
        }
    },
    mutations: {
      SET_TOKEN(state, token) {
          state.token = token
      },
      SET_USER(state, user) {
          state.user = user
      },
      SET_TOKO(state, toko) {
          state.toko = toko
      }
    },
    actions: {
        async login ( { dispatch }, credential) {
           let response = await axios.post('/login', credential)
           dispatch('attempt', response.data.token)
        },
         
        async attempt( { commit, state }, token) {
            if(token) {
                commit('SET_TOKEN', token)
            }

            if(!state.token) {
                return
            }

            try {
                let response = await axios.get('/user')
                commit('SET_USER', response.data)
            } catch (e) {
                commit('SET_TOKEN', null)
                commit('SET_USER', null)                
                commit('SET_TOKO', null)                
            }

            let restoko = await axios.get('/toko')
            commit('SET_TOKO', restoko.data)
        },

        logout ({commit}) {
            return axios.post('/logout')
            .then(() => {
                localStorage.removeItem('token')
                commit('SET_TOKEN', null)
                commit('SET_USER', null)
                commit('SET_TOKO', null)
            })
        }
        
    },

}