<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <div style="font-size: 16px">
                <i class="icon-people mr-2"></i> Customer
            </div>
            <form @submit.prevent="" class="my-3 my-xl-0">
                <div class="form-group-feedback form-group-feedback-left mb-3 mb-xl-0">
                    <div class="input-group">
                        <input type="text" v-model="search" class="form-control" placeholder="Type customer name and hit Enter" style="width: 450px;">
                        <div class="input-group-append">
                            <button type="button" class="btn bg-grey-400 btn-labeled btn-labeled-left dropdown-toggle" data-toggle="dropdown"><b><i class="icon-filter3"></i></b> {{filter}}</button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a v-for="(market, i) in marketplaces" :key="i" @click.prevent="filterData(i)" href="#" class="dropdown-item"><i class="icon-store"></i> {{market.marketplace}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="form-control-feedback">
                        <i class="icon-search4 font-size-base text-muted"></i>
                    </div>
                </div>
            </form>
            
            <div>
                <button @click="addCustomer" type="button" class="btn bg-teal-400 btn-labeled btn-labeled-left" data-toggle="modal" data-target="#modal"><b><i class="icon-plus-circle2"></i></b>Pelanggan Baru</button>
            </div>
        </div>
        <div class="card mt-3">
            <table class="table">
                <thead>
                    <tr>
                        <th>Pelanggan</th>
                        <th>Alamat</th>
                        <th>Market</th>
                        <th class="text-center">Aksi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(customer, i) in customers" :key="i">
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="mr-3">
                                    <a href="#" class="btn bg-teal-400 rounded-round btn-icon btn-sm">
                                        <span class="letter-icon">{{customer.name.substr(0,1)}}</span>
                                    </a>
                                </div>
                                <div>
                                    <a href="#" class="text-default font-weight-semibold letter-icon-title">{{customer.name}}</a>                                    
                                    <div class="text-muted font-size-sm">+62{{customer.phone}}</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div>{{customer.address}} {{customer.district}}</div>
                            <div>{{customer.regency}} - {{customer.province}} {{customer.zip}}</div>
                        </td>
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="mr-2">
                                    <a href="#">
                                        <img :src="'http://api.zhrleather.com/assets/images/marketplaces/'+customer.icon" class="rounded-circle" width="32" height="32" alt="">
                                    </a>
                                </div>
                                <div>
                                    <a href="#" class="text-default font-weight-semibold">{{customer.market}}</a>
                                    <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span> {{customer.type}}</div>
                                </div>
                            </div>
                        </td>
                        <td class="text-center">
                            <a href="#"><i class="icon-pencil5 text-promary"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="card-footer d-flex p-3 ">
                <div class="align-self-center">
                    <span class="text-muted">Total Penjualan: {{Intl.NumberFormat('en-US').format(page.total)}}</span>
                </div>
                <div class="ml-auto">
                    <Paginate :pages="page" @get-page="getData($event)" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
import axios from 'axios'
import Paginate from '../../components/Paginate'
export default {
    components: {
        Paginate
    },
    data () {
        return{
            search: '',
            filter: 'Semua market',
            marketplaces: '',
            customers: '',
            page: ''
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData (e) {
            $("html, body").animate({ scrollTop: "0" })
            axios.get('/customer', {
                params: {
                    page: e
                }
            })
            .then((res) => {
                this.customers = res.data.data
                this.page = res.data
            })
        },
        addCustomer () {

        }
    }
}
</script>