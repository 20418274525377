<template>
    <div class="card-body pt-0">
        <h5 class="mb-4">Shop Information</h5>
        <form @submit.prevent="onSubmit" class="vld-parent">
            <Loading 
                :color="'#26a69a'"
                :active.sync="isLoading"
                :z-index="10"
                :is-full-page="false">
            </Loading>
            <div class="form-group row">
                <label class="col-lg-3 col-form-label">Shop Name:</label>
                <div class="col-lg-9">
                    <input v-model="form.shop_name" type="text" class="form-control" placeholder="Shop Name" maxlength="48">
                    <span class="form-text text-right text-muted">{{48-form.shop_name.length}} characters remaining</span>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-3 col-form-label">Tagline:</label>
                <div class="col-lg-9">
                    <input v-model="form.tagline" type="text" class="form-control" placeholder="Shop Name" maxlength="48">
                    <span class="form-text text-right text-muted">{{48-form.tagline.length}} characters remaining</span>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-3 col-form-label">Description:</label>
                <div class="col-lg-9">
                    <textarea v-model="form.description" class="form-control" placeholder="Description" maxlength="140"></textarea>
                    <span class="form-text text-right text-muted">{{140-form.description.length}} characters remaining</span>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-3 col-form-label">Address:</label>
                <div class="col-lg-9">
                    <textarea v-model="form.address" class="form-control" placeholder="Address" maxlength="140"></textarea>
                    <span class="form-text text-right text-muted">{{140-form.address.length}} characters remaining</span>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-3">contact:</label>
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-md-4">
                            <label>Phone:</label>
                            <input v-model="form.phone" type="text" class="form-control" placeholder="phone" maxlength="20">
                            <span class="form-text text-right text-muted">{{20-form.phone.length}} characters remaining</span>
                        </div>
                        <div class="col-md-4">
                            <label>e-mail:</label>
                            <input v-model="form.email" type="text" class="form-control" placeholder="company@mail.com" maxlength="55">
                            <span class="form-text text-right text-muted">{{55-form.email.length}} characters remaining</span>
                        </div>
                        <div class="col-md-4">
                            <label>Website:</label>
                            <input v-model="form.website" type="text" class="form-control" placeholder="http://company.com" maxlength="55">
                            <span class="form-text text-right text-muted">{{55-form.website.length}} characters remaining</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <button type="submit" class="btn btn-primary">Save</button>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    components: {
        Loading
    },
    data () {
        return {
            isLoading: false,
            form: {
                shop_name: '',
                tagline: '',
                description: '',
                address: '',
                phone: '',
                email: '',
                website: ''
            }
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.isLoading = true
            axios.get('/setting')
            .then((response) => {
                this.isLoading = false
                if(response.data.shop_name) {
                    this.form.shop_name = response.data.shop_name
                }
                if(response.data.tagline) {
                    this.form.tagline = response.data.tagline
                }
                if(response.data.description) {
                    this.form.description = response.data.description
                }
                if(response.data.address) {
                    this.form.address = response.data.address
                }
                if(response.data.phone) {
                    this.form.phone = response.data.phone
                }
                if(response.data.email) {
                    this.form.email = response.data.email
                }
                if(response.data.website) {
                    this.form.website = response.data.website
                }
                
            })
        },
        onSubmit () {
            this.isLoading = true
            axios.post('/setting/information',this.form)
            .then(() => {
                this.isLoading = false
                this.$toasted.success('Successfully updated',{
                    position: 'top-center',
                    iconPack: 'custom-class',
                    icon: 'icon-info22 mr-2',
                    duration: '1500',
                    action : {
                        text : 'Close',
                        onClick : (e, toastObject) => {
                            toastObject.goAway(0);
                        }
                    },
                })
            }).catch(() => {
                this.isLoading = false
            })
        }
    }
}
</script>