<template>
	<div class="sidebar sidebar-dark sidebar-main sidebar-fixed sidebar-expand-md">
		<!-- Sidebar mobile toggler -->
		<div class="sidebar-mobile-toggler text-center">
			<a href="#" class="sidebar-mobile-main-toggle">
				<i class="icon-arrow-left8"></i>
			</a>
			Navigation
			<a href="#" class="sidebar-mobile-expand">
				<i class="icon-screen-full"></i>
				<i class="icon-screen-normal"></i>
			</a>
		</div>
		<!-- /sidebar mobile toggler -->


		<!-- Sidebar content -->
		<div class="sidebar-content">
			
			<!-- User menu -->
			<div v-if="user"  class="sidebar-user">
				<div class="card-body">
					<div class="media">
						<div class="mr-3">
							<a href="#" v-if="user.avatar"><img  :src="user.avatar" width="38" height="38" class="rounded-circle" alt=""></a>
							<a href="#" v-else><img src="@/assets/images/placeholders/placeholder.jpg" width="38" height="38" class="rounded-circle" alt=""></a>
						</div>

						<div class="media-body">
							<div class="media-title font-weight-semibold">{{user.name}}</div>
							<div class="font-size-xs opacity-50">
								<i class="icon-pin font-size-sm"></i> &nbsp;Santa Ana, CA
							</div>
						</div>

						<div class="ml-3 align-self-center">
							<a href="#" class="text-white"><i class="icon-cog3"></i></a>
						</div>
					</div>
				</div>
			</div>
			<!-- /user menu -->

			
			<!-- Main navigation -->
			<div v-if="toko" class="card card-sidebar-mobile">
				<ul class="nav nav-sidebar" data-nav-type="accordion">

					<!-- Main -->
					<li class="nav-item-header"><div class="text-uppercase font-size-xs line-height-xs">Main</div> <i class="icon-menu" title="Main"></i></li>
					<li class="nav-item">
						<router-link to="/" class="nav-link" exact>
							<i class="icon-home4"></i>
							<span>
								Beranda								
							</span>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/penjualan" class="nav-link">
							<i class="icon-barcode2"></i>
							<span>
								Transaksi								
							</span>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/online" class="nav-link">
							<i class="icon-connection"></i>
							<span>
								Online								
							</span>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/products" class="nav-link">
							<i class="icon-stack2"></i>
							<span>
								Produk								
							</span>
						</router-link>
					</li>
					<li v-if="toko.id == 1" class="nav-item">
						<router-link to="/purchases" class="nav-link">
							<i class="icon-cart-add2"></i>
							<span>
								Barang Masuk								
							</span>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/expenses" class="nav-link">
							<i class="icon-basket"></i>
							<span>
								Pengeluaran								
							</span>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/statistics" class="nav-link">
							<i class="icon-stats-bars3"></i>
							<span>
								Statistik								
							</span>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/report" class="nav-link">
							<i class="icon-file-stats2"></i>
							<span>
								Laporan							
							</span>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/website" class="nav-link">
							<i class="icon-sphere"></i>
							<span>
								Website						
							</span>
						</router-link>
					</li>
											
				</ul>
			</div>
			<!-- /main navigation -->

		</div>
		<!-- /sidebar content -->
		
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import PerfectScrollbar from 'perfect-scrollbar'

export default {
	name: 'Sidebar',
	
	mounted () {
		new PerfectScrollbar('.sidebar-fixed .sidebar-content', {
			wheelSpeed: 1,
			wheelPropagation: true
		})		
	},
	computed: {
		...mapGetters({
			user: 'auth/user',
			toko: 'auth/toko'
		})
	}
}
</script>