<template>
    <div class="card">
        <div class="card-header bg-white header-elements-inline p-2">
            <h6 class="card-title ml-2">NGOLES BARANG</h6>
            <div class="header-elements">
                <button @click="onNgolesBaru" type="button" class="btn bg-teal-400 btn-labeled btn-labeled-left mr-1" data-toggle="modal" data-target="#modal"><b><i class="icon-plus-circle2"></i></b>Ngoles Baru</button>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-4 align-self-center">
                    <div class="form-group-feedback form-group-feedback-left">
                        <input v-model="search" type="search" class="form-control" placeholder="Ketik Nama Seller">
                        <div class="form-control-feedback">
                            <i class="icon-search4 font-size-base text-muted"></i>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 border-right border-left">
                    <div class="text-center">
                        <h6 class="font-weight-semibold mb-0">{{Intl.NumberFormat('en-US').format(order)}} Order</h6>
                        <span class="font-size-sm text-muted">ORDER HARI INI</span>
                    </div>
                </div>
                <div class="col-md-2 border-right text-success">
                     <div class="text-right">
                        <h6 class="font-weight-semibold mb-0">Rp. {{Intl.NumberFormat('en-US').format(sudah_bayar)}}</h6>
                        <span class="font-size-sm text-muted">SUDAH BAYAR HARI INI</span>
                    </div>
                </div>
                <div class="col-md-2 text-danger">
                    <div class="text-right">
                        <h6 class="font-weight-semibold mb-0">Rp. {{Intl.NumberFormat('en-US').format(belum_bayar)}}</h6>
                        <span class="font-size-sm text-muted">TOTAL BELUM BAYAR</span>
                    </div>
                </div>
            </div>
        </div>
        <table class="table">
            <thead class="bg-danger">
                <tr>
                    <th style="width:18%">TANGGAL</th>
                    <th style="width:32%">NAMA PEDAGANG</th>
                    <th class="text-right" style="width:5%">QTY</th>
                    <th class="text-right" style="width:15%">TOTAL</th>
                    <th style="width:15%">STATUS BAYAR</th>
                    <th class="text-center" style="width:15%">AKSI</th>
                </tr>
            </thead>
            <tbody v-if="onLoad">
                <tr v-for="p in 10" :key="p">
                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                    <td>
                        <div class="d-flex ph-item">
                            <div class="alpha-danger col-6" style="height: 32px"></div>
                            <div class="alpha-success col-6" style="height: 32px"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody v-if="ngoles">
                <tr v-for="(item, i) in ngoles" :key="i" :class="item.status=='BELUM BAYAR'?'text-danger':''">
                    <td>{{item.created_at}}</td>
                    <td>{{item.nama}}</td>
                    <td class="text-right">{{item.qty}}</td>
                    <td class="text-right">{{Intl.NumberFormat('en-US').format(item.total)}}</td>
                    <td>{{item.status}}</td>
                    <td v-if="item.status=='BELUM BAYAR'" class="text-center">
                        <div class="btn-group btn-group-justified">
                            <a @click="onBatal(item.id)" href="#" class="btn btn-sm bg-danger">Batal</a>
                            <a @click="onBayar(i)" href="#" class="btn btn-sm bg-success" data-toggle="modal" data-target="#bayar">Bayar</a>
                        </div>
                    </td>
                    <td v-else class="text-center">
                        <div class="btn-group btn-group-justified">
                            <a @click="onBayar(i)"  href="#" class="btn btn-sm bg-info" data-toggle="modal" data-target="#detail">Lihat detail</a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="card-footer d-flex p-3 ">
            <div class="align-self-center">
                <span class="text-muted">Total Ngoles Barang: {{Intl.NumberFormat('en-US').format(page.total)}}</span>
            </div>
            <div class="ml-auto">
                <Paginate :pages="page" @get-page="getData($event)" />
            </div>
        </div>

        <div class="modal fade" data-backdrop="static" id="modal">
            <div class="modal-dialog modal-lg vld-parent">
                <Loading 
                    :color="'#26a69a'"
                    :loader="'spinner'"
                    :active.sync="isLoading"
                    :is-full-page="false">
                </Loading>
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title">NGOLES BARU</h6>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-6 border-right">
                                <div class="media">
                                    <div class="mr-3 align-self-center">
                                        <i class="icon-store icon-3x opacity-75"></i>
                                    </div>
                                    <div class="media-body text-left">
                                        <div class="input-group">
                                            <input ref="penjual" @keydown="error.ngoles_id=''" type="text" id="ac-penjual" class="border-dash" :class="error.ngoles_id ? 'border-danger':''" placeholder="Ketik Nama Pedagang">
                                            <span class="input-group-append">
												<button class="btn-border" :class="error.ngoles_id ? 'border-danger':''" type="button" data-toggle="modal" data-target="#pedagang"><i class="icon-add d-toggle" :class="error.ngoles_id ? 'd-block':''"></i></button>
											</span>
                                        </div>
                                        <span class="font-size-sm text-muted">NAMA PEDANGANG</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 text-right">
                                <h4 class="font-weight-semibold mb-0">Rp. {{Intl.NumberFormat('en-US').format(total)}}</h4>
                                <span class="text-uppercase font-size-sm">TOTAL TAGIHAN</span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body border-top">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <span class="input-group-text">AR</span>
                                    </span>
                                    <input ref="sku" @input="getSKU" v-model="sku" type="text" class="form-control" placeholder="Kode Barang" maxlength="4">
                                </div>
                            </div>
                            <div class="col-md-5">
                                <input type="text" v-model="barang.nama_barang" class="form-control" disabled placeholder="Nama Barang">
                            </div>
                            <div class="col-md-2">
                                <autonumeric 
                                    ref="harga_net"
                                    class="form-control text-right" 
                                    v-model="new_harga_net"
                                    :placeholder="Intl.NumberFormat('en-US').format(barang.harga_net)"
                                    :options="{modifyValueOnWheel: false, decimalPlaces: 0}"
                                    @keydown.enter.prevent.native="onEnterHargaNet"
                                    >
                                </autonumeric>
                            </div>
                            <div class="col-md-2">
                                <autonumeric 
                                    ref="qty"
                                    class="form-control text-right" 
                                    v-model="qty"
                                    :placeholder="'qty'"
                                    :options="{modifyValueOnWheel: false, decimalPlaces: 0}"
                                    @keydown.enter.prevent.native="onEnterQty"
                                    >
                                </autonumeric>
                            </div>
                        </div>
                    </div>
                    <div class="tabel-responsive table-scrollable mb-3">
                        <table class="table">
                            <thead class="bg-danger">
                                <tr>
                                    <th style="width: 20%">KODE BARANG</th>
                                    <th style="width: 30%">NAMA BARANG</th>
                                    <th class="text-right" style="width: 15%">HARGA</th>
                                    <th class="text-right" style="width: 10%">QTY</th>
                                    <th class="text-right" style="width: 15%">TOTAL</th>
                                    <th class="text-center" style="width: 10%">AKSI</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(barang, i) in form.ngoles_details.slice().reverse()" :key="i" :class="[i == 0 && fadeIn ? 'animated fadeIn':'']">
                                    <td>{{barang.kode_barang}}</td>
                                    <td>{{barang.nama_barang}}</td>
                                    <td class="text-right">{{Intl.NumberFormat('en-US').format(barang.harga)}}</td>
                                    <td class="text-right">{{barang.qty}}</td>
                                    <td class="text-right">{{Intl.NumberFormat('en-US').format(barang.total)}}</td>
                                    <td class="text-center">
                                        <a @click.prevent="onDelete(barang.index)" href="#"><i class="icon-trash text-danger"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">BATAL</button>
                        <button @click="onSimpan" type="button" class="btn bg-teal" :disabled="form.ngoles_details.length == 0 || isLoading && form.ngoles_details.length > 0">SIMPAN</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" data-backdrop="static" id="bayar">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-success">NGOLES BAYAR</h5>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-4 border-right">
                                <div class="media">
                                    <div class="mr-3 align-self-center">
                                        <i class="icon-store icon-3x opacity-75"></i>
                                    </div>
                                    <div class="media-body text-left">
                                        <h5 class="font-weight-semibold mb-0">{{form_bayar.pedagang}}</h5>
                                        <span class="text-uppercase font-size-sm text-muted">NAMA PEDAGANG</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 border-right d-flex justify-content-center">
                                <div class="media">
                                    <div class="mr-3 align-self-center">
                                        <i class="icon-calendar2 text-info icon-3x opacity-75"></i>
                                    </div>
                                    <div class="media-body text-left">
                                        <h5 class="font-weight-semibold mb-0">{{form_bayar.date}}</h5>
                                        <span class="text-uppercase font-size-sm text-muted">Tanggal Ambil Barang</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 d-flex justify-content-end">
                                <div class="media">
                                    <div class="mr-3 align-self-center">
                                        <i class="icon-calculator text-danger icon-3x opacity-75"></i>
                                    </div>
                                    <div class="media-body text-right">
                                        <h5 class="font-weight-semibold mb-0">Rp. {{Intl.NumberFormat('en-US').format(totalBayar)}}</h5>
                                        <span class="text-uppercase font-size-sm text-muted">TOTAL TAGIHAN</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tabel-responsive table-scrollable mb-3">
                        <table class="table">
                            <thead class="bg-danger">
                                <tr>
                                    <th style="width: 20%">KODE BARANG</th>
                                    <th style="width: 30%">NAMA BARANG</th>
                                    <th class="text-right" style="width: 15%">HARGA</th>
                                    <th class="text-right" style="width: 10%">QTY</th>
                                    <th class="text-right" style="width: 15%">TOTAL</th>
                                    <th class="text-center" style="width: 10%">AKSI</th>
                                </tr>
                            </thead>
                            <tbody v-if="onLoadBayar">
                                <tr v-for="p in 5" :key="p">
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                    <td>
                                        <div class="d-flex justify-content-center">
                                            <div class="alpha-slate p-2 col-1 ph-item"></div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-if="form_bayar.ngoles_details">
                                <tr v-for="(item, i) in form_bayar.ngoles_details" :key="i">
                                    <td>{{item.kode_barang}}</td>
                                    <td>{{item.nama_barang}}</td>
                                    <td class="text-right">{{Intl.NumberFormat('en-US').format(item.harga)}}</td>
                                    <td class="text-right">{{item.qty}}</td>
                                    <td class="text-right">{{Intl.NumberFormat('en-US').format(item.total)}}</td>
                                    <td class="text-center">
                                        <a @click.prevent="onDeleteItem(i)" href="#"><i class="icon-trash text-danger"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">BATAL</button>
                        <button @click="onPaid" type="button" class="btn btn-success btn-labeled btn-labeled-left mr-1"><b><i class="icon-coin-dollar"></i></b>BAYAR</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="detail">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-info">NGOLES DETAIL</h5>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-4 border-right">
                                <div class="media">
                                    <div class="mr-3 align-self-center">
                                        <i class="icon-store icon-3x opacity-75"></i>
                                    </div>
                                    <div class="media-body text-left">
                                        <h5 class="font-weight-semibold mb-0">{{form_bayar.pedagang}}</h5>
                                        <span class="text-uppercase font-size-sm text-muted">NAMA PEDAGANG</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 border-right d-flex justify-content-center">
                                <div class="media">
                                    <div class="mr-3 align-self-center">
                                        <i class="icon-calendar2 text-info icon-3x opacity-75"></i>
                                    </div>
                                    <div class="media-body text-left">
                                        <h5 class="font-weight-semibold mb-0">{{form_bayar.date}}</h5>
                                        <span class="text-uppercase font-size-sm text-muted">Tanggal Ambil Barang</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 d-flex justify-content-end">
                                <div class="media">
                                    <div class="mr-3 align-self-center">
                                        <i class="icon-calculator text-danger icon-3x opacity-75"></i>
                                    </div>
                                    <div class="media-body text-right">
                                        <h5 class="font-weight-semibold mb-0">Rp. {{Intl.NumberFormat('en-US').format(totalBayar)}}</h5>
                                        <span class="text-uppercase font-size-sm text-muted">TOTAL TAGIHAN</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body pt-0 pb-0">
                        <div class="row border-top">
                            <div class="col-md-4 border-right pt-1 pb-1 text-center">
                                <h6 class="mb-0">{{form_bayar.status}}</h6>
                                <span class="text-muted">STATUS</span>
                            </div>
                            <div class="col-md-4 border-right pt-1 pb-1 text-center">
                                <h6 class="mb-0">{{form_bayar.update}}</h6>
                                <span class="text-muted">TANGGAL BAYAR</span>
                            </div>
                            <div class="col-md-4 pt-1 pb-1 text-center">
                                <h6 class="mb-0">{{form_bayar.penerima}}</h6>
                                <span class="text-muted">BAYAR KE</span>
                            </div>
                        </div>
                    </div>
                    <div class="tabel-responsive table-scrollable mb-3">
                        <table class="table">
                            <thead class="bg-danger">
                                <tr>
                                    <th style="width: 20%">KODE BARANG</th>
                                    <th style="width: 35%">NAMA BARANG</th>
                                    <th class="text-right" style="width: 15%">HARGA</th>
                                    <th class="text-right" style="width: 15%">QTY</th>
                                    <th class="text-right" style="width: 15%">TOTAL</th>
                                </tr>
                            </thead>
                            <tbody v-if="onLoadBayar">
                                <tr v-for="p in 5" :key="p">
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                    <td><div class="alpha-slate p-1 ph-item"></div></td>
                                </tr>
                            </tbody>
                            <tbody v-if="form_bayar.ngoles_details">
                                <tr v-for="(item, i) in form_bayar.ngoles_details" :key="i">
                                    <td>{{item.kode_barang}}</td>
                                    <td>{{item.nama_barang}}</td>
                                    <td class="text-right">{{Intl.NumberFormat('en-US').format(item.harga)}}</td>
                                    <td class="text-right">{{item.qty}}</td>
                                    <td class="text-right">{{Intl.NumberFormat('en-US').format(item.total)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">TUTUP</button>
                    </div>
                </div>
            </div>
        </div>
        <form @submit.prevent="onSubmitPedagangBaru" class="modal fade" id="pedagang">
            <div class="modal-dialog modal-sm">
                <Loading 
                    :color="'#26a69a'"
                    :loader="'spinner'"
                    :active.sync="isLoadingPedagagng"
                    :is-full-page="false">
                </Loading>
                <div class="modal-content">
                    <div class="modal-header bg-info">
                        <h5 class="modal-title">Tambah Pedagang Baru</h5>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <input v-model="pedagang_baru" @keydown="error_pedagang_baru=''" ref="nama_pedagang" :class="[error_pedagang_baru ? 'border-danger':'']" type="text" class="form-control" placeholder="Nama Pedagang">
                            <span v-if="error_pedagang_baru" class="form-text text-danger">{{error_pedagang_baru}}</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">BATAL</button>
                        <button type="submit" class="btn bg-teal">SIMPAN</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import $ from 'jquery'
import axios from 'axios'
import autonumeric from '../../../components/AutoNumeric'
import swalInit from '../../../sweetalert'
import Paginate from '../../../components/Paginate'
import _ from 'lodash'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    components: {
        Paginate,
        autonumeric,
        Loading,
    },
    data () {
        return {
            onLoad: false,
            onLoadBayar: false,
            isLoading: false,
            isLoadingPedagagng: false,
            fadeIn: false,
            modal_mode: '',
            search: '',
            order: '',
            sudah_bayar:'',
            belum_bayar: '',
            ngoles:'',
            page: '',
            penjual: [],
            form: {
                ngoles_id: '',
                ngoles_details: [],
            },
            error: {
                ngoles_id: '',
            },
            barang: {
                id: '',
                kode_barang: '',
                nama_barang: '',
                harga_net: 0,
            },
            form_bayar: {
                ngoles_id: '',
                pedagang: '',
                date: '',
                update: '',
                penerima: '',
                status: '',
                total: '',
                ngoles_details: [],
            },
            sku: '',
            new_harga_net: '',
            qty: '',
            pedagang_baru: '',
            error_pedagang_baru: '',
        }
    },
    mounted() {
        this.getData()
        $('#modal').on('shown.bs.modal', () => {
            this.$refs.penjual.focus()
        })
        $('#modal').on('show.bs.modal', () => {
            let app = this
            $('#ac-penjual').autocomplete({
                minLength: 0,
                source: app.penjual,
                appendTo: $('#modal'),
                response: function(event, ui) {
                    if(ui.content.length == 0) {
                        swalInit.fire({
                            title: 'Oops...',
                            text: 'Nama Pedagang tidak ditemukan',
                            type: 'error'
                        })
                        .then(() => {
                            $('#ac-penjual').val('')
                        })
                    }
                },
                focus: function( event, ui ) {
                    $('#ac-penjual').val(ui.item.label);
                    return false;
                },
                select: function( event, ui ) {
                    $('#ac-penjual').val(ui.item.label);
                    app.form.ngoles_id = ui.item.id
                    app.$refs.sku.focus()
                    return false;
                }
            })
            .autocomplete('instance')._renderItem = function(ul, item) {
                return $('<li>').append('<span class="font-weight-semibold pb-0">' + item.label + '</span>').appendTo(ul);
            }
        })
        $('#pedagang').on('shown.bs.modal', () => {
            this.$refs.nama_pedagang.focus()
        })
    },
    computed: {
        total () {
            let sum = 0
            this.form.ngoles_details.forEach(el => {
                sum += el.total
            });
            return sum
        },
        totalBayar () {
            let sum = 0
            this.form_bayar.ngoles_details.forEach(el => {
                sum += el.total
            });
            return sum
        }
    },
    methods: {
        getData (e) {
            this.onLoad = true
            this.ngoles = ''
            this.penjual = []
            $("html, body").animate({ scrollTop: "0" })
            axios.get('/ngoles',{
                params: {
                    page: e,
                    search: this.search
                }
            })
            .then((response) => {
                this.onLoad = false
                this.ngoles = response.data.data.data
                this.page = response.data.data
                this.order = response.data.order
                this.sudah_bayar = response.data.bayar
                this.belum_bayar = response.data.belum_bayar
                response.data.penjual.forEach(el => {
                    this.penjual.push({id:el.id, label:el.nama})
                });
            })
        },
        onBayar (i) {
            this.onLoadBayar = true
            this.form_bayar = {
                ngoles_id: this.ngoles[i].id,
                pedagang: this.ngoles[i].nama,
                date: this.ngoles[i].created_at,
                update: this.ngoles[i].updated_at,
                status: this.ngoles[i].status,
                penerima: this.ngoles[i].penerima,
                ngoles_details: [],
            }
            axios.get('/ngoles/bayar',{
                params: {
                    ngoles_id: this.ngoles[i].id
                }
            })
            .then((response) => {
                this.onLoadBayar = false
                this.form_bayar.ngoles_details = response.data
            })
        },
        onDeleteItem (i) {
            let app = this
            let ngoles_id = this.form_bayar.ngoles_details[i].ngoles_nota_id
            let id  = this.form_bayar.ngoles_details[i].id
            let barang_id = this.form_bayar.ngoles_details[i].barang_id
            let total = parseInt(this.totalBayar - this.form_bayar.ngoles_details[i].total)
            let qty = this.form_bayar.ngoles_details[i].qty
            swalInit.fire({
                title: 'Barang ini Akan di hapus',
                text: "Anda yakin barang ini sudah dikembalikan?",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya, Hapus!',
                cancelButtonText: 'Tidak',
                showLoaderOnConfirm: true,
                preConfirm: function () {
                    return axios.post('/ngoles/bayar/delete',{
                        id: id,
                        ngoles_id: ngoles_id,
                        total: total,
                        barang_id: barang_id,
                        qty: qty
                    })
                    .then((response) => {
                        app.form_bayar.ngoles_details = response.data
                        app.getData()
                    })
                }
            })
        },
        onPaid() {
            let app = this
            swalInit.fire({
                title: 'Yakin Sudah Bayar?',
                text: 'Siapa yang menerima Pembayarannya?',
                input: 'text',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-primary',
                cancelButtonClass: 'btn btn-light',
                inputPlaceholder: 'tulis nama penerima disini!',
                showCancelButton: true,
                confirmButtonText: 'Ya, Bayar!',
                cancelButtonText: 'Tidak',
                inputClass: 'form-control',
                showLoaderOnConfirm: true,
                preConfirm: function(penerima) {
                    return axios.post('/ngoles/bayar/update',{
                        id: app.form_bayar.ngoles_id,
                        penerima: penerima
                    })
                    .then((response) => {
                        return response
                    })
                },
                inputValidator: function(value) {
                    return !value && 'Nama penerima harus di isi'
                }
            }).then((result) => {
                if(result.value) {
                   $('#bayar').modal('hide')
                   app.getData()
                }
            });
        },
        onBatal (id) {
            let app = this
            swalInit.fire({
                title: 'Anda ingin Membatalkannya?',
                text: "Data Ngoles ini akan dihapus dari database!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya, Batalkan!',
                cancelButtonText: 'Tidak!',
                showLoaderOnConfirm: true,
                preConfirm: function () {
                    return axios.post('/ngoles/cancel', {
                        id: id
                    })
                    .then(() => {
                        app.getData()
                    })
                }
            })
        },
        onNgolesBaru () {
            $('#ac-penjual').val('')
            this.modal_mode = 'new'
            this.sku = ''
            this.new_harga_net = ''
            this.qty = ''
            this.form = {
                ngoles_id: '',
                ngoles_details: []
            }
            this.error.ngoles_id = ''
            this.barang = {
                id: '',
                kode_barang: '',
                nama_barang: '',
                harga_net: 0,
            }
        },
        getSKU () {
            this.barang = {
                id: '',
                kode_barang: '',
                nama_barang: '',
                harga_net: 0
            }
            this.new_harga_beli = ''
            this.qty = ''
            this.fadeIn = false
            if(this.sku.length==4) {
                axios.get('/barang/search/AR'+this.sku)
                .then((response) => {
                    if(response.data.error) {
                        swalInit.fire({
                            title: 'AR'+this.sku,
                            text: response.data.error,
                            type: 'error',
                            confirmButtonClass: 'btn btn-primary',
                            buttonsStyling: false,
                        })
                        .then(()=> {
                            this.$refs.sku.focus()
                        }) 
                    } else {
                        this.barang.id = response.data.id
                        this.barang.nama_barang = response.data.nama_barang
                        this.barang.kode_barang = response.data.kode_barang
                        this.barang.harga_net = response.data.harga_net
                        this.$refs.harga_net.$el.focus()
                    }
                })
            }
        },
        onEnterHargaNet () {
            if(this.new_harga_net >= this.barang.harga_net) {
                this.$refs.qty.$el.focus()
            } 
            if(this.new_harga_net < this.barang.harga_net) {
                swalInit.fire({
                    title: 'Harga Rendah',
                    text: 'Harga yang anda masukan terlalu rendah',
                    type: 'error',
                    confirmButtonClass: 'btn btn-primary',
                    buttonsStyling: false,
                })
            }
        },
        onEnterQty () {
            let app = this
            if(this.new_harga_net < this.barang.harga_net) {
                app.$refs.qty.$el.blur()
                swalInit.fire({
                    title: 'Harga Rendah',
                    text: 'Harga yang anda masukan terlalu rendah',
                    type: 'error',
                    confirmButtonClass: 'btn btn-primary',
                    buttonsStyling: false,
                })
                .then(() => {                    
                    app.$refs.harga_net.$el.focus()
                })
            }

            if(this.qty <= 0 ) {
                swalInit.fire({
                    title: 'Qty Belum diisi',
                    text: 'Qty Minimum 1',
                    type: 'error',
                    confirmButtonClass: 'btn btn-primary',
                    buttonsStyling: false,
                })
            }

            if(this.qty > 0 && this.new_harga_net >= this.barang.harga_net) {
                this.fadeIn = true
                let i = this.form.ngoles_details.length
                this.form.ngoles_details.push({
                    index: i+1,
                    barang_id: this.barang.id,
                    kode_barang: this.barang.kode_barang, 
                    nama_barang: this.barang.nama_barang, 
                    harga: this.new_harga_net,
                    qty: this.qty,
                    total: parseInt(this.new_harga_net * this.qty)
                })
                this.$nextTick(() => {
                    this.barang = {
                        id: '',
                        kode_barang: '',
                        nama_barang: '',
                        harga_net: 0
                    }
                    this.sku = ''
                    this.new_harga_net =''
                    this.qty = ''
                    this.$refs.sku.focus()
                })
            }
        },
        onDelete (index) {
            let i = this.form.ngoles_details.map(item => item.index).indexOf(index)
            this.form.ngoles_details.splice(i, 1)
        },
        onSimpan () {
            if(!this.form.ngoles_id) {
                this.error.ngoles_id = 'Pedagang Belum dipilih'
                console.log('kosong');
            }
            if(this.modal_mode == 'new') {
                if(this.form.ngoles_id) {
                    this.isLoading = true
                    axios.post('/ngoles/create',{
                        ngoles_id: this.form.ngoles_id,
                        details: this.form.ngoles_details,
                        total: this.total
                    })
                    .then(() => {
                        this.getData()
                        $('#modal').modal('hide')
                        this.isLoading = false
                    })
                }
            }
        },
        onSubmitPedagangBaru () {
            if(!this.pedagang_baru) {
                this.error_pedagang_baru = 'Nama Pedagang harus diisi'
            }
            if(this.pedagang_baru) {
                this.isLoadingPedagagng = true
                axios.post('/ngoles/store',{
                    nama: this.pedagang_baru
                })
                .then((response) => {
                    this.isLoadingPedagagng = false
                    $('#ac-penjual').val(response.data.nama)
                    $('#pedagang').modal('hide')
                    this.form.ngoles_id = response.data.id
                    this.penjual.push({id: response.data.id, label: response.data.nama})
                })
            }
        },
        searchData: _.debounce(function () {
            this.getData()
        }, 500)
    },
    watch: {
        search () {
            this.searchData()
        }
    }
}
</script>

<style scoped>

.border-dash {
    display: block;
    width: auto;
    height: calc(0.8385em + 0.875rem + 1px);
    padding: 1px;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5385;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #fff;
    box-shadow: 0 0 0 0 transparent;
    
}
.d-toggle {
    display: none;
}
.border-dash:hover {
    border: dashed 1px #ddd;
}
.border-dash:focus {
    border: dashed 1px #ddd;
}
.border-dash::placeholder {
    color: rgb(192, 192, 192);
}
.btn-border {
    border: 1px solid #fff;
    background: #fff;
}
.btn-border:hover {
    border: dashed 1px #ddd;
}
.btn-border:focus {
    border: dashed 1px #ddd;
    outline: none;
}
.input-group:hover .btn-border {
    border: dashed 1px #ddd;
    background: rgb(247, 246, 246);
}
.input-group:hover .border-dash {
    border: dashed 1px #ddd;
}
.input-group:focus-within .btn-border {
    border: dashed 1px #ddd;
    background: rgb(247, 246, 246);
}

.input-group:hover .d-toggle {
    display: block;
}
.input-group:focus-within .d-toggle {
    display: block;
}
.input-group:focus-within .border-dash {
    border: dashed 1px #ddd;
}

</style>
