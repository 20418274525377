<template>
    <div class="card-body pt-0">
        <div class="row">
            <div class="col-md-6">
                <h5 class="mb-4">Cash & Bank Account</h5>
            </div>
            <div class="col-md-6 text-right">
                <button @click="onAdd" type="button" class="btn bg-teal-400 btn-labeled btn-labeled-left" data-toggle="modal" data-target="#modal"><b><i class="icon-plus-circle2"></i></b> Add Bank Account</button>
            </div>
        </div>
        <template v-if="onLoading">
            <div v-for="i in 3" :key="i" class="card card-body ph-item">
                <div class="media align-items-center align-items-lg-start text-center text-lg-left flex-column flex-lg-row">
                    <div class="mr-3 align-self-center">
                        <div class="alpha-slate p-4"></div>
                    </div>
                    <div class="media-body align-self-center mt-3 mt-lg-0">
                        <div class="alpha-slate p-2 col-3 mb-1"></div>
                        <div class="alpha-slate p-1 col-1 mb-1"></div>
                        <div class="alpha-slate p-1 col-2"></div>
                    </div>
                    <div class="align-self-center d-none d-lg-block pb-2 pt-2 border-left mr-0"></div>
                    <div class="align-self-center mt-3 mt-lg-0 ml-lg-3 text-center">
                        <div class="alpha-slate p-2"></div>
                    </div>
                    <div class="align-self-center mt-3 mt-lg-0 ml-lg-3 text-center">                    
                        <div class="alpha-slate p-2" style="width: 40px"></div>
                    </div>
                </div>
            </div>
        </template>
        
        <div v-for="(bank, i) in banks" :key="i" class="card card-body">
            <div class="media align-items-center align-items-lg-start text-center text-lg-left flex-column flex-lg-row">
                <div class="mr-3 align-self-center">
                    <a href="#" class="">
                        <img :src="'/images/banks/'+bank.logo" width="60">
                    </a>
                </div>
                <div class="media-body align-self-center mt-3 mt-lg-0">
                    <h5 class="mb-0">{{bank.name}}</h5>
                    <h6 class="mb-0">{{bank.account_number}}</h6>
                    <div class="font-weight-bold">{{bank.account_name}}</div>
                </div>
                <div class="align-self-center d-none d-lg-block pb-2 pt-2 border-left mr-0"></div>
                <div class="align-self-center mt-3 mt-lg-0 ml-lg-3 text-center">
                    <a @click="onEdit(i)" href="#" class="list-icons-item" data-popup="tooltip" title="Edit" data-toggle="modal" data-target="#modal"><i class="icon-pencil5"></i></a>
                </div>
                <div class="align-self-center mt-3 mt-lg-0 ml-lg-3 text-center">                    
                    <input @change="onChange($event, i)" v-model="bank.status" type="checkbox" class="form-check-input-switcher">
                </div>
            </div>
        </div>
        <form @submit.prevent="onSubmit" class="modal fade right" id="modal">
            <div class="modal-dialog">
                <div class="modal-content vld-parent">
                    <Loading 
                        :color="'#26a69a'"
                        :active.sync="isLoading"
                        :z-index="10"
                        :is-full-page="false">
                    </Loading>
                    <div class="modal-header pb-3">
                        <h5 class="modal-title">{{modalTitle}}</h5>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Select New Bank</label>
                            <select id="bank" :disabled="modalMode == 'edit'">
                                <option value=""></option>
                                <option v-for="(bank, i) in bankList" :value="i" :key="i">{{bank.name}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label> Account Number</label>
                            <input v-model="form.account_number" type="text" class="form-control" placeholder="Accoun number">
                        </div>
                        <div class="form-group">
                            <label> Account Name</label>
                            <input v-model="form.account_name" type="text" class="form-control" placeholder="Accoun name">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">CANCEL</button>
                        <button type="submit" class="btn bg-teal" :disabled="form.name == ''">
                                <i v-if="isLoading" class="icon-spinner4 spinner mr-2"></i>
                                SAVE
                            </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import $ from 'jquery'
import _ from 'lodash'
require('select2')
import Banks from '../../json/bank'
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
var Switchery = require('../../assets/js/switchery')

export default {
    components: {
        Loading
    },
    data () {
        return {
            onLoading: false,
            isLoading: false,
            banks: null,
            modalTitle: '',
            modalMode: '',
            editIndex: '',
            editId: '',
            listSwitchery: [],
            form: {
                name: '',
                logo: '',
                account_number: '',
                account_name: ''
            },

        }
    },
    mounted () {
        
        this.getData()
        let app = this       
        $('#modal').on('show.bs.modal', () => {
            $('#bank').select2({
                width: '100%',
                placeholder: 'Select Bank'
            }).on('select2:select', () => {
                let i = $('#bank').val()
                this.form.name = this.bankList[i].name
                this.form.logo = this.bankList[i].logo
            })

            if(this.modalMode == 'edit') {
                let i = this.editIndex
                this.$nextTick(() => {
                    $('#bank').val(i).trigger('change')
                    this.form.name = this.banks[i].name
                    this.form.account_number = this.banks[i].account_number
                    this.form.account_name = this.banks[i].account_name
                })
            }
        })
        
        $('#modal').on('hide.bs.modal', () => {
            $('#bank').val('').trigger('change')
            app.form = {
                name: '',
                logo: '',
                account_number: '',
                account_name: ''
            }
        })        
    },
    computed: {
        bankList () {
            let data = ''
            if(this.modalMode == 'new') {
                data = _.differenceBy(Banks, this.banks, 'name')
            } else {
                data = this.banks
            }
            return data
        }
    },
    methods: {
        getData () {
            let app = this
            app.listSwitchery = []
            app.onLoading = true
            app.banks = ''
            axios.get('/bank')
            .then((response) => {
                app.onLoading = false
                this.banks = response.data
                this.$nextTick(() => {
                    var elems = Array.prototype.slice.call(document.querySelectorAll('.form-check-input-switcher')); 
                    elems.forEach(function(html) {
                        var switchery =  new Switchery(html)
                        app.listSwitchery.push(switchery)
                    });
                })
            })
        },
        onAdd () {
            this.modalTitle = 'ADD NEW BANK'
            this.modalMode = 'new'
        },
        onEdit (i) {
            this.modalMode = 'edit'
            this.modalTitle = 'EDIT BANK ACCOUNT'
            this.editIndex = i
            this.editId = this.banks[i].id
        },
        onChange (e, i) {
            axios.post('/bank/status',{
                id: this.banks[i].id,
                status: e.target.checked
            })
        },
        onSubmit () {      
            this.isLoading = true      
            if(this.modalMode == 'new') {
                axios.post('/bank/store',this.form)
                .then(() => {
                    this.isLoading = false
                    $('#modal').modal('hide')
                    this.getData()
                })
            } else {
                axios.post('/bank/update',{
                    id: this.editId,
                    account_number: this.form.account_number,
                    account_name: this.form.account_name
                })
                .then(() => {
                    for (let i = 0; i < this.listSwitchery.length; i++) {                
                        this.listSwitchery[i].switcher.remove()
                    }
                    this.isLoading = false
                    $('#modal').modal('hide')
                    this.getData()
                })
            }
        }
    },
    
}
</script>