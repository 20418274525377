<template>
    <div>
        <legend>EDIT E-MAIL ACCOUNT </legend>
        <form @submit.prevent="onSubmit" class="vld-parent">
            <Loading 
                :color="'#26a69a'"
                :active.sync="isLoading"
                :is-full-page="false">
            </Loading>
            <div class="form-group">
                <label for="">e-Mail Account</label>
                <input v-model="user.email" @keydown="error=''" type="email" class="form-control" required>
                <span v-if="error" class="form-text text-danger">{{error}}</span>
            </div>
            <div class="text-right">
                <button type="submit" class="btn btn-primary">UPDATE</button>
            </div>
        </form>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    components: {
        Loading
    },
    data () {
        return {
            isLoading: false,
            error: ''
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        })
    },
    methods: {
        onSubmit() {
            if(!this.user.email) {
                this.error = 'This field email is required'
            }
            if(this.user.email) {
                this.onUpdate()
            }
        },
        onUpdate () {
            this.isLoading = true
            axios.post('/profile/email',this.user)
            .then(() => {
                this.isLoading = false
                this.$toasted.success('Successfully updated',{
                    position: 'top-center',
                    iconPack: 'custom-class',
                    icon: 'icon-info22 mr-2',
                    duration: '1500',
                    action : {
                        text : 'Close',
                        onClick : (e, toastObject) => {
                            toastObject.goAway(0);
                        }
                    },
                })
            })
            .catch(error => {
                this.isLoading = false
                this.error = error.response.data.errors.email[0]
                this.$toasted.error(error.response.data.errors.email[0],{
                    position: 'top-center',
                    iconPack: 'custom-class',
                    icon: 'icon-blocked mr-2',
                    action : {
                        text : 'Close',
                        onClick : (e, toastObject) => {
                            toastObject.goAway(0);
                        }
                    },
                })
            })
        }
    }
}
</script>