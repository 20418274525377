<template>
    <div>
        <legend>CHANGE PASSWORD</legend>
        <form @submit.prevent="onSubmit" class="vld-parent"> 
            <Loading 
                :color="'#26a69a'"
                :active.sync="isLoading"
                :is-full-page="false">
            </Loading>
            <div class="form-group row">
                <div class="col-md-6">
                    <label for="">New Passoword</label>
                    <input v-model="password" @keydown="error.password=''" type="password" class="form-control">
                    <span class="form-text text-danger">{{error.password}}</span>
                </div>
                <div class="col-md-6">
                    <label for="">Repeat Passoword</label>
                    <input v-model="confirmation_password" @keydown="error.confirmation_password=''" type="password" class="form-control">
                    <span class="form-text text-danger">{{error.confirmation_password}}</span>
                </div>
            </div>
            <div class="text-right">
                <button class="btn btn-primary">UPDATE</button>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    components: {
        Loading
    },
    data () {
        return {
            isLoading: false,
            password: '',
            confirmation_password: '',
            error: {
                password: '',
                confirmation_password: '',
            }
        }
    },
    methods: {
        onSubmit () {
            if(!this.password) {
                this.error.password = 'This field new password is required'
            }
            if(!this.confirmation_password) {
                this.error.confirmation_password = 'This field repeat password is required'
            }

            if(this.password !== this.confirmation_password) {
                this.error.password = 'This field password not match'
                this.error.confirmation_password = 'This field repeat password not match'
            }

            if(this.password && this.confirmation_password && this.password == this.confirmation_password) {
                this.onUpdate()
            }
        },
        onUpdate () {
            this.isLoading = true
            axios.post('/profile/password',{
                password: this.password
            })
            .then(() => {
                this.password = '',
                this.confirmation_password =''
                this.error.password = ''
                this.error.confirmation_password = ''
                this.isLoading = false
                this.$toasted.success('Successfully updated',{
                    position: 'top-center',
                    iconPack: 'custom-class',
                    icon: 'icon-info22 mr-2',
                    duration: '1500',
                    action : {
                        text : 'Close',
                        onClick : (e, toastObject) => {
                            toastObject.goAway(0);
                        }
                    },
                })
            })
        }
    }
}
</script>