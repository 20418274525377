<template>
    <div class="d-flex justify-content-center align-items-center pt-5">        
        <form class="login-form vld-parent" @submit.prevent="onSubmit">
            <Loading 
                :active.sync="isLoading"
                :is-full-page="false">
            </Loading>
            <div class="card mb-0">
                <div class="card-body">
                    <div class="text-center mb-3">
                        <i class="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
                        <h5 class="mb-0">Login to your account</h5>
                        <span class="d-block text-muted">Enter your credentials below</span>
                    </div>

                    <div class="form-group form-group-feedback form-group-feedback-left">
                        <input v-model="form.email" name="email" type="text" class="form-control" placeholder="Email" :class="[error.email ? 'border-danger':'']" @keydown="error.email=''">
                        <div class="form-control-feedback">
                            <i class="icon-mail5 text-muted"></i>
                        </div>
                        <span v-if="error.email" class="form-text text-danger">{{error.email}}</span>
                    </div>

                    <div class="form-group form-group-feedback form-group-feedback-left">
                        <input v-model="form.password" type="password" class="form-control" placeholder="Password" :class="[error.password ? 'border-danger':'']" @keydown="error.password=''">
                        <div class="form-control-feedback">
                            <i class="icon-lock2 text-muted"></i>
                        </div>
                        <span v-if="error.password" class="form-text text-danger">{{error.password}}</span>
                    </div>

                    <div class="form-group">
                        <button type="submit" class="btn btn-primary btn-block">Sign in <i class="icon-circle-right2 ml-2"></i></button>
                    </div>

                    <div class="text-center">
                        <a href="login_password_recover.html">Forgot password?</a>
                    </div>
                </div>
            </div>
        </form>        
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    components: {
        Loading
    },
    data () {
        return {
            isLoading: false,
            form: {
                email: '',
                password: '',
                device_name: 'browser'
            },
            error: {
                email: '',
                password: '',
            }
        }
    },
    methods: {
        ...mapActions({
            login: 'auth/login',
        }),
        onSubmit () {
            if(!this.form.email) {
                this.error.email = 'The email field is required.'
            }
            if(!this.form.password) {
                this.error.password = 'The password field is required.'
            }

            if(this.form.email && this.form.password) {
                this.signIn()
            }
        },
        signIn () {
            this.isLoading = true
            this.login(this.form)
            .then(() => {
                this.isLoading = false
                this.$router.push('/')
            })
            .catch(error => {
                this.isLoading = false
                this.error.email = error.response.data.errors.email[0]
                this.error.password = error.response.data.errors.email[0]
            })
        }
    }
}
</script>