<template>
    <div>
        <div class="row">
            <div class="col-md-4">
                <div class="card">
                    <div class="card-header bg-white header-elements-inline">
                        <h5 class="card-title"><i class="icon-truck mr-2"></i>Kecamatan Tujuan</h5>
                    </div>
                    <div class="card-body">
                        <div class="form-group-feedback form-group-feedback-left">
                            <input id="search" type="text" name="search" class="form-control" placeholder="Ketik nama kecamatan">
                            <div class="form-control-feedback">
                                <i class="icon-search4 font-size-base text-muted"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-8">
                <div class="card card-body" style="height: 135px">
                    <div class="row">
                        <div class="col-md-6 text-center border-right">
                            <h6><i class="icon-pin-alt mr-2"></i>ASAL DARI</h6>
                            <h3 class="mb-0">GARUT</h3>
                            <span class="text-muted">Jawa Barat</span>
                        </div>
                        <div class="col-md-6 text-center">
                            <h6><i class="icon-flag3 mr-2"></i>TUJUAN</h6>
                            <h3 class="mb-0 text-uppercase">{{destination.subdistrict_name}}</h3>
                            <span class="text-muted">{{destination.city}} - {{destination.province}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="placeholder">
            <div v-for="p in 4" :key="p" class="alpha-slate border ph-item mb-3" style="height: 85px;"></div>
        </template>
        <div v-if="results">
            <div v-for="(result, i) in results" :key="i" class="card card-body">
                <div class="media">
                    <div class="mr-3">
                        <a href="#">
                            <img :src="'/images/shippings/'+result.code+'.png'" class="rounded" height="38">
                        </a>
                    </div>

                    <div class="media-body">
                        <h6 class="font-weight-semibold mb-0 text-uppercase">{{result.code}}</h6>
                        <span class="text-muted">{{result.name}}</span>
                    </div>

                    <div v-for="(cost, index) in result.costs" :key="index" class="ml-5 align-self-center">
                        <div class="d-block">
                            <h6 class="font-weight-semibold mb-0">{{cost.service}} {{Intl.NumberFormat().format(cost.cost[0].value)}} <span class="text-success font-size-sm font-weight-normal ml-2"> etd ({{cost.cost[0].etd}})</span></h6>
                            <span class="text-muted">{{cost.description}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import axios from 'axios'
export default {
    data () {
        return {
            placeholder: false,
            origin: '',
            destination: '',
            results: ''
        }
    },
    mounted () {
        let app = this
        $('#search').bind('keydown', function(event){
            if(event.keyCode === $.ui.keyCode.TAB && $(this).autocomplete("instance").menu.active) {
                event.preventDefault();
            }
        }).focus()
        .autocomplete({
            minLength: 3,
            source: function(request, response){
                axios.get('/online/district/'+request.term)
                .then((res) => {
                    response($.map(res.data, function(n) {
                        return n;
                    }))
                })
                },
            search: function() {
                $(this).parent().addClass('ui-autocomplete-processing');
            },
            open: function() {
                $(this).parent().removeClass('ui-autocomplete-processing');
            },
            focus: function(event, ui){
                var val = ui.item.name+' - '+ui.item.regency
                $('#search').val(val);
                return false;
            },
            select: function(event, ui) {
                var val = ui.item.name+' - '+ui.item.regency
                $('#search').val(val);
                app.getShippCost(ui.item.id)
                return false;
            }
        })
        .autocomplete("instance")._renderItem = function(ul, item) {
            return $("<li>").append("<span class='text-semibold'>" + item.name + " - " +item.regency+ "</span>").appendTo(ul);
        }
        this.getData()
    },
    methods: {
        getData () {
            axios.get('/online/ongkir')
            .then((response) => {
                this.origin = response.data
            })
        },
        getShippCost (id) {
            this.results = ''
            this.placeholder = true
            axios.get('/online/trace',{
                params: {
                    origin: this.origin.district_id,
                    destination: id
                }
            })
            .then((response) => {
                this.placeholder = false
                this.destination = response.data.rajaongkir.destination_details
                this.results = response.data.rajaongkir.results
                console.log(response)
            })
        }
    }
}
</script>