<template>
    <select>
        <slot></slot>
    </select>
</template>

<script>
import $ from 'jquery'
require('select2')
export default {
    props:['options','value','placeholder'],
    mounted () {
        var vm = this
        $(this.$el)
        .select2({data: this.options, placeholder: this.placeholder, width: '100%' })
        .val(this.value)
        .trigger('change')
        .on('select2:select', function() {
            vm.$emit('input', this.value)
        })
    },
    watch: {
        value: function (value) {
            $(this.$el).val(value).trigger('change')
        },
        options: function(options) {
            $(this.$el).empty().select2({data: options, placeholder: this.placeholder, width: '100%' })
        }
    },
    destroyed: function () {
        $(this.$el).off().select2('destroy')
    }
}
</script>