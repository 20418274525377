<template>
    <div>
        <div class="card-body pt-0">
            <div class="row">
                <div class="col-md-6">
                    <h5>Marketplace</h5>
                </div>
                <div class="col-md-6 text-right">
                    <button @click="addMarketplace" type="button" class="btn bg-teal-400 btn-labeled btn-labeled-left" data-toggle="modal" data-target="#modal"><b><i class="icon-plus-circle2"></i></b> Tambah Marketplace</button>
                </div>
            </div>
            <div v-if="onLoad" class="row mt-3">
                <div v-for="p in 6" :key="p" class="col-md-6">
                    <div class="card card-body alpha-slate ph-item" style="height: 101px"></div>
                </div>
            </div>
            <div class="row mt-3">
                <div v-for="(market, i) in marketplaces" :key="i" class="col-md-6">
                    <div class="card card-body">
                        <div class="media">
                            <div class="mr-3">
                                <img :src="'http://api.zhrleather.com/assets/images/marketplaces/'+market.icon" width="60" alt="">
                            </div>
                            <div class="media-body">
                                <h4 class="media-title font-weight-semibold">{{market.name}}</h4>
                                <span class="text-muted">Marketplace</span>
                            </div>
                            <div class="ml-3 d-flex align-self-center">
                                <div class="align-self-center mr-1">
                                    <checkbox v-model="market.status" @input="checkStatus($event, i)"/>
                                </div>
                                <div class="p-1 border-right ml-1"></div>
                                <div class="ml-3">
                                    <a @click.prevent="onEdit(i)" href="#" data-toggle="modal" data-target="#modal"><i class="icon-pencil text-primary"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <form @submit.prevent="onSubmit" class="modal right fade" id="modal">
            <div class="modal-dialog vld-parent">
                <Loading 
                    :color="'#26a69a'"
                    :loader="'spinner'"
                    :active.sync="isLoading"
                    :is-full-page="false">
                </Loading>
                <div class="modal-content">                    
                    <div class="modal-header pb-3">
                        <h5 class="modal-title">{{title}}</h5>
                    </div>

                    <div class="modal-body">

                        <div class="form-group">
                            <label>Marketplace</label>
                            <input type="text" class="form-control" v-model="form.marketplace" :class="[errors.marketplace ? 'border-danger':'']" @keydown="errors.marketplace = ''">
                            <span v-if="errors.marketplace" class="form-text text-danger">{{errors.marketplace}}</span>
                        </div>

                        <div class="form-group">
                            <label>Link account</label>
                            <input type="text" class="form-control" v-model="form.link" :class="[errors.link ? 'border-danger':'']" @keydown="errors.link = ''">
                            <span v-if="errors.link" class="form-text text-danger">{{errors.link}}</span>
                        </div>

                        <div class="form-group">
                            <label>Upload icon logo</label>
                            <input type="file" id="file" ref="file" v-on:change="onChangeFileUpload()" class="form-control-uniform-custom">
                            <span v-if="errors.file" class="form-text text-danger">{{errors.file}}</span>
                        </div>
                    </div>
                    
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">BATAL</button>
                        <button type="submit" class="btn bg-teal">SIMPAN</button>
                    </div>
                </div>
            </div>
        </form>    
    </div>
</template>
<script>
import $ from 'jquery'
import axios from 'axios'
import checkbox from '@/components/CheckBox'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    components: {
        Loading,
        checkbox,
    },
    mounted () {
        this,this.getData()
        let app = this
        $('.form-control-uniform-custom').uniform({
            fileButtonClass: 'action btn bg-blue',
            selectClass: 'uniform-select bg-pink-400 border-pink-400'
        })
        $('#modal').on('hidden.bs.modal', () => {
            $('#file').val('')
            $('.filename').html('No file selected')
            app.form = {
                marketplace: '',
                link: '',
                file: '',
            },
            app.errors = {
                marketplace: '',
                link: '',
            }
        })
    },
    data () {
        return {
            title: '',
            mode: '',
            onLoad: false,
            isLoading: false,
            marketplaces: '',
            form: {
                marketplace: '',
                link: '',
                file: '',
            },
            errors: {
                marketplace: '',
                link: '',
                file: '',
            },
            id: '',
            icon: '',
        }
    },
    methods: {
        getData () {
            this.marketplaces = ''
            this.onLoad = true
            axios.get('/marketplaces')
            .then((response) => {
                this.onLoad = false
                this.marketplaces = response.data
            })
        },
        checkStatus (e, i) {
            axios.post('/marketplaces/status',{
                id: this.marketplaces[i].id,
                status: e,
            })
        },
        addMarketplace () {
            this.title ='TAMBAH MARKETPLACE BARU'
            this.mode = 'new'
        },
        onEdit (i) {
            this.title ='UBAH MARKETPLACE'
            this.mode = 'edit' 
            this.form.marketplace = this.marketplaces[i].name 
            this.form.link = this.marketplaces[i].link
            this.id = this.marketplaces[i].id
            this.icon = this.marketplaces[i].icon
        },
        onSubmit () {
            if(!this.form.marketplace) {
                this.errors.marketplace = 'This marketplace cannot be empty!'
            }
            if(!this.form.link) {
                this.errors.link = 'This link cannot be empty!'
            }
            if(!this.form.file && this.mode == 'new') {
                this.errors.file = 'This file cannot be empty'
            }
            
            if(this.form.marketplace && this.form.link){
                
                if(this.mode == 'new' && this.form.file) {
                    this.isLoading = true
                    this.postMarketplace()
                } else {
                    this.isLoading = true
                    this.updateMarketplace()
                }
            }
        },
        postMarketplace () {
            let formData = new FormData()
            formData.append('marketplace', this.form.marketplace)
            formData.append('link', this.form.link)
            formData.append('file', this.form.file)

            axios.post('/marketplaces/store', formData, {
                headers: {
                       'Content-Type': 'multipart/form-data' 
                    }
            })
            .then(() => {
                this.getData()
                this.isLoading = false
                $('#modal').modal('hide')
            })
        },
        updateMarketplace() {
            let formData = new FormData()
            formData.append('marketplace', this.form.marketplace)
            formData.append('link', this.form.link)
            formData.append('file', this.form.file)
            formData.append('id', this.id)
            formData.append('icon', this.icon)

            axios.post('/marketplaces/update', formData, {
                headers: {
                       'Content-Type': 'multipart/form-data' 
                    }
            })
            .then(() => {
                this.getData()
                this.isLoading = false
                $('#modal').modal('hide')
            })
        },
        onChangeFileUpload () {
            this.form.file = this.$refs.file.files[0];
            this.errors.file = ''
        }
    }
}
</script>