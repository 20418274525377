<template>
    <div class="breadcrumb">
        <router-link v-for="(crumb , i) in crumbs" :key="i"  class="breadcrumb-item" :to="{ path: crumb.path }" exact>
            <i v-if="i == 0" class="icon-home2 mr-2"></i>{{crumb.name}}
        </router-link>  
    </div>
</template>

<script>

export default {
    name: 'Breadcrumb',
    props: {root: String},
    computed: {
        crumbs: function () {            
            var path = '', title = (this.root || 'Dashboard');

			var cs = [ { name: title, path: '/'} ]; if(!this.$route) return [];

			var r = (this.$route.path                        ).split('/');
			var m = (this.$route.matched[0].meta.crumbs || this.$route.matched[1].meta.crumbs).split('/');

			for(var i = 1; i < r.length; i++)
			{
				var name = (m[i] || r[i]); if(r[i] == '') continue;
                var p = (r[i]); if(r[i] == '') continue; 
				title += '/'+ name;
				path  += '/'  + p;
		
				cs.push({ name: name, path: path });
			}

			window.document.title = 'APP SELLER '+title;
            return cs;
        }
    },
}
</script>