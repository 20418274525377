<template>
    <div>
        <div class="card">
            <div class="card-header bg-white header-elements-inline">
                <h6 class="card-title">MERK</h6>
                <div class="header-elements">
                    <button @click="onAdd" type="button" class="btn bg-teal-400 btn-labeled btn-labeled-left" data-toggle="modal" data-target="#modal"><b><i class="icon-plus-circle2"></i></b>Tambah Merk</button>
                </div>            
            </div>
            <table class="table">
                <thead class="bg-slate">
                    <tr>
                        <th style="width: 30%">Merk</th>
                        <th style="width: 60%">Keterangan</th>
                        <th class="text-center" style="widt: 10%">Aksi</th>
                    </tr>
                </thead>
                <tbody v-if="onLoad">
                    <tr v-for="p in 10" :key="p">
                        <td>
                            <div class="alpha-slate p-1 ph-item"></div>
                        </td>
                        <td>
                            <div class="alpha-slate p-1 ph-item"></div>
                        </td>
                        <td>
                            <div class="d-flex justify-content-center">
                                <div class="alpha-slate p-2 col-1 ph-item"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr v-for="(merk, i) in merks" :key="i" :id="i" :class="[index == i && flash ? 'animated flash bg-success':'']">
                        <td>{{merk.nama_merk}}</td>
                        <td>{{merk.keterangan}}</td>
                        <td class="text-center">
                            <a @click.prevent="onEdit(i)" href="#" data-toggle="modal" data-target="#modal"><i class="icon-pencil5"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <form @submit.prevent="onSubmit" class="modal fade right" id="modal">
            <div class="modal-dialog vld-parent">
                <Loading 
                    :color="'#26a69a'"
                    :loader="'dots'"
                    :active.sync="isLoading"
                    :is-full-page="false">
                </Loading>
                <div class="modal-content">
                    <div class="modal-header pb-3">
                        <h5 class="modal-title">{{modal_title}}</h5>
                    </div>
                    <div class="modal-body">
                        <fieldset :disabled="isLoading">
                            <div class="form-group">
                                <label>Nama Merk <span class="text-danger">*</span></label>
                                <input v-model="form.nama_merk" @keydown="error.nama_merk=''" type="text" class="form-control" placeholder="Nama Merk">
                                <span v-if="error.nama_merk" class="form-text text-danger">{{error.nama_merk}}</span>
                            </div>
                            <div class="form-group">
                                <label>Keterangan</label>
                                <textarea v-model="form.keterangan" rows="5" class="form-control"></textarea>
                            </div>
                        </fieldset>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">BATAL</button>
                        <button type="submit" class="btn bg-teal" :disabled="isLoading">SIMPAN</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import $ from 'jquery'
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    components: {
        Loading
    },
    data () {
        return {
            onLoad: false,
            isLoading: false,
            modal_mode: '',
            modal_title: '',
            merks: '',
            index: '',
            flash: false,
            form: {
                nama_merk: '',
                keterangan: ''
            },
            error: {
                nama_merk: '',
            }
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.onLoad = true
            this.merks = ''
            axios.get('/merk')
            .then((response) => {
                this.onLoad = false
                this.merks = response.data
            })
        },
        onAdd () {
            this.modal_mode = 'new'
            this.modal_title = 'Tambah Merk Baru'
            this.form = {
                nama_merk: '',
                keterangan: ''
            }
            this.error = {
                nama_merk: '',
            }
        },
        onEdit (i) {
            this.index = i
            this.modal_mode = 'edit'
            this.modal_title = 'Ubah Data Merk'
            this.form = {
                id: this.merks[i].id,
                nama_merk: this.merks[i].nama_merk,
                keterangan: this.merks[i].keterangan
            }
            this.error = {
                nama_merk: '',
            }
        },
        onSubmit () {
            if(!this.form.nama_merk) {
                this.error.nama_merk = 'Nama Merk harus diisi!!'
            }

            if(this.form.nama_merk) {
                this.isLoading = true
                if(this.modal_mode == 'new') {
                    this.postMerk()
                }
                if(this.modal_mode == 'edit') {
                    this.updateMerk()
                }
            }
        },
        postMerk () {
            axios.post('/merk/create', this.form)
            .then((response) => {
                if(response.data.error) {
                    this.isLoading = false
                    this.error.nama_merk = response.data.error
                } else {
                    $('#modal').modal('hide')
                    this.isLoading= false
                    this.getData()
                }
            })
        },
        updateMerk () {
            axios.post('/merk/update', this.form)
            .then((response) => {
                if(response.data.error) {
                    this.isLoading = false
                    this.error.nama_merk = response.data.error
                } else {
                    $('#modal').modal('hide')
                    this.isLoading = false
                    this.getData()
                }
            })
        }
    }
}
</script>