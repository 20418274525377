<template>
    <div>
        <div class="card">
            <div class="card-body">
                <div class="row mb-1">
                    <div class="col-md-6">
                        <fieldset>
                            <legend class="font-weight-semibold"><i class="icon-reading mr-2"></i> PILIH DROPSHIPPER</legend>
                            <div class="form-group form-group-feedback form-group-feedback-left row mb-1">
                                <div class="col-9">
                                    <div class="position-relative">
                                        <input ref="customer" type="text" class="form-control" placeholder="Ketik nama dropsipper'" id="ac-dropship">
                                        <div class="form-control-feedback">
                                            <i class="icon-search4 font-size-base"></i>
                                        </div>
                                        <span v-if="error_new_selling.dropship_id" class="form-text text-danger">{{error_new_selling.dropship_id}}</span>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <button class="btn bg-teal" data-toggle="modal" data-target="#new_dropship">Tambah Baru</button>
                                </div>
                            </div>
                            <div v-if="dropship" class="form-group">
                                <div class="font-weight-semibold">{{dropship.name}}</div>
                                <div>+62{{dropship.phone}}</div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-6">                        
                        <fieldset>
                            <legend class="font-weight-semibold"><i class="icon-reading mr-2"></i> PILIH PEMBELI</legend>
                            <div class="form-group form-group-feedback form-group-feedback-left row mb-1">
                                <div class="col-9">
                                    <div class="position-relative">
                                        <input ref="customer" v-model="form_new_selling.customer_name" type="text" class="form-control" placeholder="Ketik nama pembeli'" id="ac-customer">
                                        <div class="form-control-feedback">
                                            <i class="icon-search4 font-size-base"></i>
                                        </div>
                                        <span v-if="error_new_selling.customer_id" class="form-text text-danger">{{error_new_selling.customer_id}}</span>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <button class="btn bg-teal" data-toggle="modal" data-target="#new_customer">Pembeli Baru</button>
                                </div>
                            </div>
                            <div v-if="customer" class="form-group mt-1">
                                <div class="font-weight-semibold">{{customer.name}}</div>
                                <div>{{customer.address}}, {{customer.district}}, {{customer.regency}}</div>
                                <div>{{customer.province}}, {{customer.zip}}</div>
                                <div>+62{{customer.phone}}</div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <legend class="font-weight-semibold"><i class="icon-truck mr-2"></i>PILIH PENGIRIMAN</legend>
                <div class="row">
                    <Loading 
                        :color="'#26a69a'"
                        :loader="'spinner'"
                        :active.sync="isService"
                        :is-full-page="false">
                    </Loading>
                    <div class="col-md-3">
                        <select name="" id="courier">
                            <option value=""></option>
                        </select>
                        <span v-if="error_new_selling.courier_id" class="form-tetxt text-danger">{{error_new_selling.courier_id}}</span>
                    </div>
                    <div class="col-md-3">
                        <select name="" id="service">
                            <option value=""></option>
                        </select>
                        <span v-if="error_new_selling.service_id" class="form-tetxt text-danger">{{error_new_selling.service_id}}</span>
                    </div>
                    <div class="col-md-3">
                        <input ref="booking_code" v-model="form_new_selling.booking_code" @keydown="error_new_selling.booking_code=''" type="text" class="form-control" placeholder="KODE BOOKING">
                        <span v-if="error_new_selling.booking_code" class="form-tetxt text-danger">{{error_new_selling.booking_code}}</span>
                    </div>
                    <div class="col-md-3">
                        <autonumeric 
                            class="form-control text-right" 
                            placeholder="ONGKOS KIRIM"
                            :options="{modifyValueOnWheel: false, decimalPlaces: 0}"
                            v-model="form_new_selling.shipping_cost"
                            v-on:keydown.native="error_new_selling.shipping_cost=''"
                            >
                        </autonumeric>
                        <span v-if="error_new_selling.shipping_cost" class="form-tetxt text-danger">{{error_new_selling.shipping_cost}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-9">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group row mb-0">
                            <div class="col-md-3">
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <span class="input-group-text">AR</span>
                                    </span>
                                    <input ref="sku" v-model="sku" @input="getSKU" type="text" class="form-control" placeholder="Kode Barang" maxlength="4">
                                </div>
                            </div>
                            <div class="col-md-5">
                                <input v-model="barang.nama_barang" type="text" class="form-control" disabled placeholder="Nama Barang">
                            </div>
                            <div class="col-md-2">
                                <autonumeric 
                                    ref="harga"
                                    v-model="new_harga"
                                    class="form-control text-right" 
                                    :placeholder="Intl.NumberFormat('en-US').format(barang.harga_jual)"
                                    :options="{modifyValueOnWheel: false, decimalPlaces: 0}"
                                    @keydown.enter.prevent.native="onEnterHarga"
                                    >
                                </autonumeric>
                            </div>
                            <div class="col-md-2">
                                <autonumeric 
                                    ref="qty"
                                    v-model="qty"
                                    class="form-control text-right" 
                                    :placeholder="'qty'"
                                    :options="{modifyValueOnWheel: false, decimalPlaces: 0}"
                                    @keydown.enter.prevent.native="onEnterQty"
                                    >
                                </autonumeric>
                            </div>
                        </div>
                    </div>
                    <table class="table">
                        <thead class="bg-info">
                            <tr>
                                <td>KODE BARANG</td>
                                <td>NAMA BARANG</td>
                                <td class="text-right">HARGA</td>
                                <td class="text-right">QTY</td>
                                <td class="text-right">TOTAL</td>
                                <td class="text-center">AKSI</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, i) in details.slice().reverse()" :key="i" :class="[i == 0 && fadeIn ? 'animated fadeIn':'']">
                                <td>{{item.kode_barang}}</td>
                                <td>{{item.nama_barang}}</td>
                                <td class="text-right">{{Intl.NumberFormat('en-US').format(item.harga)}}</td>
                                <td class="text-right">{{item.qty}}</td>
                                <td class="text-right">{{Intl.NumberFormat('en-US').format(item.total)}}</td>
                                <td class="text-center">
                                    <a @click.prevent="onDelete(item.index)" href="#"><i class="icon-trash text-danger"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body bg-teal rounded-top">
                        <div class="media">
                            <div class="mr-3 align-self-center">
                                <i class="icon-calculator2 icon-3x opacity-75"></i>
                            </div>
                            <div class="media-body text-right">
                                <h4 class="font-weight-semibold mb-0">{{Intl.NumberFormat('en-US').format(total)}}</h4>
                                <span class="text-uppercase font-size-sm">TOTAL TAGIHAN</span>
                            </div>
                        </div>
                    </div>
                    <div class="btn-group btn-group-justified">
                        <div class="btn-group">
                            <button @click="onProses" type="button" class="btn btn-success rounded-top-0" style="height: 50px;" :disabled="details.length == 0">Proses</button>
                        </div>

                        <div class="btn-group">
                            <button @click="onBatal" type="button" class="btn btn-danger rounded-top-0" style="height: 50px;">Batal</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <form @submit.prevent="onSubmitNewCustomer" class="modal fade" id="new_customer" data-backdrop="static">
            <div class="modal-dialog">
                <div class="modal-content vld-parent">
                    <Loading 
                        :color="'#26a69a'"
                        :loader="'spinner'"
                        :active.sync="isLoading"
                        :is-full-page="false">
                    </Loading>
                    <div class="modal-header">
                        <h5 class="modal-title"> TAMBAH PEMBELI BARU</h5>
                    </div>
                    <div class="modal-body ui-front">
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label for="">Nama Lengkap <span class="text-danger">*</span> :</label>
                                <input ref="customer_name" @keydown.tab="onTabCustomerName" v-model="form_new_customer.name" type="text" class="form-control" placeholder="Nama Lengkap" :class="[error_new_customer.name ? 'border-danger':'']" @keydown="error_new_customer.name=''">
                            </div>
                            <div class="col-md-6">
                                <label for="">Marketplace <span class="text-danger">*</span> :</label>
                                <select name="" id="marketplace">
                                    <option value=""></option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label for="">No HP <span class="text-danger">*</span> :</label>
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <span class="input-group-text" :class="[error_new_customer.phone ? 'border-danger':'']">+62</span>
                                    </span>
                                    <input ref="phone" v-model="form_new_customer.phone" type="text" class="form-control" placeholder="8532412537" :class="[error_new_customer.phone ? 'border-danger':'']" @keydown="error_new_customer.phone=''">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="">Kode Pos <span class="text-danger">*</span> :</label>
                                <input v-model="form_new_customer.zip" type="text" class="form-control" :class="[error_new_customer.zip ? 'border-danger':'']" @keydown="error_new_customer.zip=''">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Alamat <span class="text-danger">*</span> :</label>
                            <input v-model="form_new_customer.address" type="text" class="form-control" placeholder="Perum Surya Regency Blok H26 Rancabango" :class="[error_new_customer.address ? 'border-danger':'']" @keydown="error_new_customer.address=''">
                        </div>
                        <div class="form-group">
                            <label for="">Kecamatan <span class="text-danger">*</span> :</label>
                            <div class="form-group form-group-feedback form-group-feedback-left">
                                <input type="text" class="form-control" placeholder="Ketik nama 'kecamatan" id="ac-district" :class="[error_new_customer.district_id ? 'border-danger':'']" @keydown="error_new_customer.district_id=''">
                                <div class="form-control-feedback">
                                    <i class="icon-search4 font-size-base"></i>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label for="">Kota</label>
                                <input v-model="form_new_customer.regency_name" type="text" class="form-control">
                            </div>
                            <div class="col-md-6">
                                <label for="">Provinsi</label>
                                <input v-model="form_new_customer.province_name" type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">BATAL</button>
                        <button type="submit" class="btn bg-teal" :disabled="isLoading">
                                <i v-if="isLoading" class="icon-spinner4 spinner mr-2"></i> SIMPAN
                            </button>
                    </div>
                </div>
            </div>
        </form>
        <form @submit.prevent="onSubmitNewDsropship" class="modal fade" id="new_dropship" data-backdrop="static" >
            <div class="modal-dialog modal-lg">
                <div class="modal-content vld-parent">
                    <Loading 
                        :color="'#26a69a'"
                        :loader="'spinner'"
                        :active.sync="isLoading"
                        :is-full-page="false">
                    </Loading>
                    <div class="modal-header">
                        <h5 class="modal-title"> TAMBAH DROPSHIPPER BARU</h5>
                    </div>
                    <div class="modal-body ui-front">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="">Nama Dropshiper<span class="text-danger">*</span> :</label>
                                    <input v-model="form_new_dropship.name" type="text" class="form-control" placeholder="Dropshipper name" :class="[error_new_dropship.name ? 'border-danger':'']" @keydown="error_new_dropship.name=''">
                                </div>
                                <div class="form-group">
                                    <label for="">No HP <span class="text-danger">*</span> :</label>
                                    <div class="input-group">
                                        <span class="input-group-prepend">
                                            <span class="input-group-text" :class="[error_new_dropship.phone ? 'border-danger':'']">+62</span>
                                        </span>
                                        <input v-model="form_new_dropship.phone" type="text" class="form-control" placeholder="8532412537" :class="[error_new_dropship.phone ? 'border-danger':'']" @keydown="error_new_dropship.phone=''">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8 border-left">                                
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="">Nama Lengkap <span class="text-danger">*</span> :</label>
                                        <input ref="customer_name" @keydown.tab="onTabCustomer" v-model="form_new_customer.name" type="text" class="form-control" placeholder="Customer Name" :class="[error_new_customer.name ? 'border-danger':'']" @keydown="error_new_customer.name=''">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="">Marketplace <span class="text-danger">*</span> :</label>
                                        <select name="" id="marketplaces">
                                            <option value=""></option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="">No HP <span class="text-danger">*</span> :</label>
                                        <div class="input-group">
                                            <span class="input-group-prepend">
                                                <span class="input-group-text" :class="[error_new_customer.phone ? 'border-danger':'']">+62</span>
                                            </span>
                                            <input ref="phones" v-model="form_new_customer.phone" type="text" class="form-control" placeholder="8532412537" :class="[error_new_customer.phone ? 'border-danger':'']" @keydown="error_new_customer.phone=''">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="">Kode POS <span class="text-danger">*</span> :</label>
                                        <input v-model="form_new_customer.zip" type="text" class="form-control" :class="[error_new_customer.zip ? 'border-danger':'']" @keydown="error_new_customer.zip=''">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="">Alamat <span class="text-danger">*</span> :</label>
                                    <input v-model="form_new_customer.address" type="text" class="form-control" placeholder="Perum Surya Regency Blok H26 Rancabango" :class="[error_new_customer.address ? 'border-danger':'']" @keydown="error_new_customer.address=''">
                                </div>
                                <div class="form-group">
                                    <label for="">Kecamatan <span class="text-danger">*</span> :</label>
                                    <div class="form-group form-group-feedback form-group-feedback-left">
                                        <input type="text" class="form-control" id="ac-districts" placeholder="Ketik Nama Kecamatan" :class="[error_new_customer.district_id ? 'border-danger':'']" @keydown="error_new_customer.district_id=''">
                                        <div class="form-control-feedback">
                                            <i class="icon-search4 font-size-base"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="">Kabupaten</label>
                                        <input v-model="form_new_customer.regency_name" type="text" class="form-control">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="">Provinsi</label>
                                        <input v-model="form_new_customer.province_name" type="text" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">BATAL</button>
                        <button type="submit" class="btn bg-teal" :disabled="isLoading">
                                <i v-if="isLoading" class="icon-spinner4 spinner mr-2"></i> SIMPAN
                            </button>
                    </div>
                </div>
            </div>
        </form>
        <form @submit.prevent="onBayar" class="modal fade" id="bank">
            <div class="modal-dialog modal-sm">
                <div class="modal-content vld-parent">
                    <Loading 
                        :color="'#26a69a'"
                        :loader="'spinner'"
                        :active.sync="isPost"
                        :is-full-page="false">
                    </Loading>
                    <div class="modal-header">
                        <h5 class="modal-title"> PILIH BANK</h5>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <select id="kode_bank">
                                <option value=""></option>
                                <option value="BCA">BCA</option>
                                <option value="BNI">BNI</option>
                                <option value="BRI">BRI</option>
                                <option value="MANDIRI">MANDIRI</option>
                            </select>
                            <span v-if="error_new_selling.bank" class="form-text text-danger">{{error_new_selling.bank}}</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">BATAL</button>
                        <button type="submit" class="btn bg-teal" :disabled="isLoading">
                                SIMPAN
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import $ from 'jquery'
import axios from 'axios'
import swalInit from '../../sweetalert'
import autonumeric from '../../components/AutoNumeric'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    components: {
        autonumeric,
        Loading
    },
    data () {
        return {
            isPost: false,
            isService: false,
            form_new_customer: {
                name: '',
                phone:'',
                address: '',
                province_id: '',
                regency_id: '',
                district_id: '',
                zip: '',
                type: '',
                marketplace_id: '',
                province_name: '',
                regency_name: '',
            },
            error_new_customer: {
                name: '',
                phone:'',
                address: '',
                province_id: '',
                regency_id: '',
                district_id: '',
                zip: '',
                type: '',
                marketplace_id: '',
                province_name: '',
                regency_name: '',
            },
            form_new_dropship: {
                name: '',
                phone: ''
            },
            error_new_dropship: {
                name: '',
                phone: ''
            },
            customer: '',
            dropship: '',
            form_new_selling: {
                customer_name: '',
                dropship_name: '',
                dropship_id: '',
                customer_id: '',
                courier_id: '',
                service_id: '',
                booking_code: '',
                shipping_cost: '',
                bank: '',
            },
            error_new_selling: {
                dropship_id: '',
                customer_id: '',
                courier_id: '',
                service_id: '',
                booking_code: '',
                shipping_cost: '',
                bank: '',
            },
            status: true,
            isLoading: false,
            simpan_mode: '',
            banks: [],
            sku: '',
            new_harga: '',
            qty: '',
            barang: {
                barang_id: '',
                kode_barang: '',
                nama_barang: '',
                harga: '',
                harga_jual: '',
                harga_beli: '',
            },
            fadeIn: false,
            details: [],
        }
    },
    mounted () {
        this.getData()
        let app = this
        $('#kode_bank').select2({
            placeholder: 'Pilih Bank',
            width: '100%'
        })
        $('#bank').on('show.bs.modal',()=> {
            $('#kode_bank').select2({
                placeholder: 'Pilih Bank',
                width: '100%'
            }).val('').trigger('change')
        })
        $('#kode_bank').on('select2:select', () => {
            app.form_new_selling.bank = $('#kode_bank').val();
            app.error_new_selling.bank = ''
        })
        $('#courier').select2({
            placeholder: 'PILIH KURIR',
            width: '100%'
        })
        $('#courier').on('select2:select', () => {
            const id = $('#courier').val()
            app.form_new_selling.courier_id = id
            app.error_new_selling.courier_id = ''
            app.getShipingService(id)
        })
        $('#service').select2({
            placeholder: 'PILIH LAYANAN',
            width: '100%'
        })
        $('#service').on('select2:select', () => {
            const id = $('#service').val()
            app.form_new_selling.service_id = id 
            app.error_new_selling.service_id = ''
            app.$refs.booking_code.focus()
            if(app.customer.marketplace_id == 1 || app.customer.marketplace_id == 5) {
                app.form_new_selling.booking_code = 'NO-BOOKING'
            }
        })
        $('#marketplace').select2({
            placeholder: 'Pilih Marketplace',
            width: '100%'
        })
        $('#marketplaces').select2({
            width: '100%',
            placeholder: 'Select Marketplace'
        })
        $('#new_customer').on('shown.bs.modal', () => {
            app.$refs.customer_name.focus()
        })
        $('#new_customer').on('hide.bs.modal', () => {
            $('#marketplace').select2({
                width: '100%',
                placeholder: 'Selet Marketplace'
            }).val('').trigger('change')
            app.form_new_customer = {
               name: '',
               phone:'',
               address: '',
               province_id: '',
               regency_id: '',
               district_id: '',
               zip: '',
               type: '',
               marketplace_id: '',
               province_name: '',
               regency_name: '',
           }
           app.error_new_customer = {
               name: '',
               phone:'',
               address: '',
               province_id: '',
               regency_id: '',
               district_id: '',
               zip: '',
               type: '',
               marketplace_id: '',
               province_name: '',
               regency_name: '',
           }
        })
        $('#new_dropship').on('hide.bs.modal', () => {
            $('#marketplaces').select2({
                width: '100%',
                placeholder: 'Selet Marketplace'
            }).val('').trigger('change')
            app.form_new_dropship = {
                name: '',
                phone: ''
            }
            app.error_new_dropship = {
                name: '',
                phone: ''
            }
            app.form_new_customer = {
               name: '',
               phone:'',
               address: '',
               province_id: '',
               regency_id: '',
               district_id: '',
               zip: '',
               type: '',
               marketplace_id: '',
               province_name: '',
               regency_name: '',
           }
           app.error_new_customer = {
               name: '',
               phone:'',
               address: '',
               province_id: '',
               regency_id: '',
               district_id: '',
               zip: '',
               type: '',
               marketplace_id: '',
               province_name: '',
               regency_name: '',
           }
        })
        $("#ac-customer").bind("keydown", function(event) {
            if (event.keyCode === $.ui.keyCode.TAB && $(this).autocomplete("instance").menu.active) {
                event.preventDefault();
            }
        })
         .autocomplete({
            minLength: 3,
            source: function(request, response) {
                app.form_new_selling.customer_id =''
                app.customer = ''
                axios.get('/online/customer/'+request.term)
                .then((res) => {
                    response($.map(res.data, function (n) {
                        return n;
                    }))
                })                
                },
                search: function() {
                    $(this).parent().addClass('ui-autocomplete-processing');
                    app.form_new_selling.customer_id = ''
                    app.form_new_selling.address =''
                },
                open: function() {
                    $(this).parent().removeClass('ui-autocomplete-processing');
                },
                response: function (event, ui) {
                    if(ui.content.length < 1) {
                        $(this).parent().removeClass('ui-autocomplete-processing');  
                        $("#ac-customer").val('');   
                        app.form_new_selling.customer_name =''                 
                        $('#new_customer').modal('show')
                    }                   
                },
                focus: function( event, ui ) {
                $("#ac-customer").val(ui.item.name);
                return false;
                },
                select: function( event, ui ) {
                $("#ac-customer").val(ui.item.name);
                app.customer = ui.item
                app.form_new_selling.customer_id = ui.item.id
                app.error_new_selling.customer_id = ''
                $('#courier').select2('open')
                return false;
            }
        })
        .autocomplete("instance")._renderItem = function(ul, item) {
            return $("<li>").append("<div class='font-weight-semibold mb-0 pb-0'>" + item.name + '</div><div class="text-muted mt-0 pt-0">' + item.address + '</div>').appendTo(ul);
        };
        $("#ac-dropship").bind("keydown", function(event) {
            if (event.keyCode === $.ui.keyCode.TAB && $(this).autocomplete("instance").menu.active) {
                event.preventDefault();
            }
        })
         .autocomplete({
            minLength: 3,
            source: function(request, response) {
                app.form_new_selling.dropship_id =''
                app.dropship = ''
                axios.get('/online/dropship/'+request.term)
                .then((res) => {
                    response($.map(res.data, function (n) {
                        return n;
                    }))
                })                
                },
                search: function() {
                    $(this).parent().addClass('ui-autocomplete-processing');
                    app.form_new_selling.dropship_id = ''
                    app.form_new_selling.address =''
                },
                open: function() {
                    $(this).parent().removeClass('ui-autocomplete-processing');
                },
                response: function (event, ui) {
                    if(ui.content.length < 1) {
                        $(this).parent().removeClass('ui-autocomplete-processing');  
                        $("#ac-dropship").val('');   
                        app.form_new_selling.dropship_name =''                 
                        $('#new_dropship').modal('show')
                    }                   
                },
                focus: function( event, ui ) {
                $("#ac-dropship").val(ui.item.name);
                return false;
                },
                select: function( event, ui ) {
                $("#ac-dropship").val(ui.item.name);
                app.dropship = ui.item
                app.form_new_selling.dropship_id = ui.item.id
                app.error_new_selling.dropship_id = ''
                return false;
            }
        })
        .autocomplete("instance")._renderItem = function(ul, item) {
            return $("<li>").append("<div class='font-weight-semibold mb-0 pb-0'>" + item.name + '</div><div class="text-muted mt-0 pt-0">' + item.address + '</div>').appendTo(ul);
        };
        $('#marketplace').on('select2:select', () => {
            app.form_new_customer.marketplace_id = $('#marketplace').val()
            $('#marketplace').select2({
                width: '100%',
                placeholder: 'Select Marketplace',
            })
            app.$refs.phone.focus()
        })
        $('#marketplaces').on('select2:select', () => {
            app.form_new_customer.marketplace_id = $('#marketplaces').val()
            $('#marketplaces').select2({
                width: '100%',
                placeholder: 'Select Marketplace',
            })
            app.$refs.phones.focus()
        })
        $('#ac-district').bind('keydown', function(event){
            if(event.keyCode === $.ui.keyCode.TAB && $(this).autocomplete("instance").menu.active) {
                event.preventDefault();
            }
        }).focus()
        .autocomplete({
            minLength: 3,
            source: function(request, response){
                axios.get('/online/kecamatan/'+request.term)
                .then((res) => {
                    response($.map(res.data, function(n) {
                        return n;
                    }))
                })
                },
            search: function() {
                $(this).parent().addClass('ui-autocomplete-processing');
            },
            open: function() {
                $(this).parent().removeClass('ui-autocomplete-processing');
            },
            focus: function(event, ui){
                var val = ui.item.name+' - '+ui.item.regency
                $('#ac-district').val(val);
                return false;
            },
            select: function(event, ui) {
                var val = ui.item.name+' - '+ui.item.regency
                $('#ac-district').val(val);
                app.form_new_customer.district_id = ui.item.id
                app.form_new_customer.regency_name = ui.item.regency
                app.form_new_customer.regency_id = ui.item.regency_id
                app.form_new_customer.province_name = ui.item.province
                app.form_new_customer.province_id = ui.item.province_id
                return false;
            }
        })
        .autocomplete("instance")._renderItem = function(ul, item) {
            return $("<li>").append("<span class='text-semibold'>" + item.name + " - " +item.regency+ "</span>").appendTo(ul);
        }
        $('#ac-districts').bind('keydown', function(event){
            if(event.keyCode === $.ui.keyCode.TAB && $(this).autocomplete("instance").menu.active) {
                event.preventDefault();
            }
        }).focus()
        .autocomplete({
            minLength: 3,
            source: function(request, response){
                axios.get('/online/kecamatan/'+request.term)
                .then((res) => {
                    response($.map(res.data, function(n) {
                        return n;
                    }))
                })
                },
            search: function() {
                $(this).parent().addClass('ui-autocomplete-processing');
            },
            open: function() {
                $(this).parent().removeClass('ui-autocomplete-processing');
            },
            focus: function(event, ui){
                var val = ui.item.name+' - '+ui.item.regency
                $('#ac-districts').val(val);
                return false;
            },
            select: function(event, ui) {
                var val = ui.item.name+' - '+ui.item.regency
                $('#ac-districts').val(val);
                app.form_new_customer.district_id = ui.item.id
                app.form_new_customer.regency_name = ui.item.regency
                app.form_new_customer.regency_id = ui.item.regency_id
                app.form_new_customer.province_name = ui.item.province
                app.form_new_customer.province_id = ui.item.province_id
                return false;
            }
        })
        .autocomplete("instance")._renderItem = function(ul, item) {
            return $("<li>").append("<span class='text-semibold'>" + item.name + " - " +item.regency+ "</span>").appendTo(ul);
        }
    },
    computed: {
        total () {
            let sum = 0
            this.details.forEach(el => {
                sum += el.total
            })
            return sum
        }
    },
    methods: {
        getData () {
            axios.get('/online/new')
            .then((response) => {
                for (let i = 0; i < response.data.shipping.length; i++) {
                    $('#courier').append('<option value="'+response.data.shipping[i].id+'">'+response.data.shipping[i].name+'</option>')                    
                }
                for (let i = 0; i < response.data.market.length; i++) {
                    $('#marketplace').append('<option value="'+response.data.market[i].id+'">'+response.data.market[i].name+'</option>')                    
                }
                for (let i = 0; i < response.data.market.length; i++) {
                    $('#marketplaces').append('<option value="'+response.data.market[i].id+'">'+response.data.market[i].name+'</option>')                    
                }
            }) 
        },
        getShipingService (id) {
            this.isService = true
            $('#service').empty()
            $('#service').append('<option val=""></option>');
            axios.get('/online/ship_service/'+id)
            .then((response) => {
                this.isService = false
                for (let i = 0; i < response.data.length; i++) {
                    $('#service').append('<option value="'+response.data[i].id+'">'+response.data[i].service+'</option>')                    
                }
                this.$nextTick(() => {
                    $('#service').select2('open')
                })
            })
        },
        getSKU () {
            this.barang = {
                barang_id: '',
                kode_barang: '',
                nama_barang: '',
                harga: '',
                harga_beli: '',
                harga_jual:''
            }
            this.new_harga = ''
            this.qty = ''
            this.fadeIn = false
            if(this.sku.length==4) {
                axios.get('/barang/search/AR'+this.sku)
                .then((response) => {
                    if(response.data.error) {
                        swalInit.fire({
                            title: 'AR'+this.sku,
                            text: response.data.error,
                            type: 'error',
                            confirmButtonClass: 'btn btn-primary',
                            buttonsStyling: false,
                        })
                        .then(()=> {
                            this.$refs.sku.focus()
                        }) 
                    } else {
                        this.barang.barang_id = response.data.id
                        this.barang.nama_barang = response.data.nama_barang
                        this.barang.kode_barang = response.data.kode_barang
                        this.barang.harga = response.data.harga_net
                        this.barang.harga_jual = response.data.harga_jual
                        this.barang.harga_beli = response.data.harga_beli
                        this.$refs.harga.$el.focus()
                    }
                })
            }
        },
        onEnterHarga () {
            if(this.new_harga >= this.barang.harga) {
                this.$refs.qty.$el.focus()
            } 
            if(this.new_harga < this.barang.harga) {
                swalInit.fire({
                    title: 'Harga Rendah',
                    text: 'Harga yang anda masukan terlalu rendah',
                    type: 'warning',
                    confirmButtonClass: 'btn btn-primary',
                    buttonsStyling: false,
                })
            }
        },
        onEnterQty () {
            let app = this
            if(this.new_harga < this.barang.harga) {
                app.$refs.qty.$el.blur()
                swalInit.fire({
                    title: 'Harga Rendah',
                    text: 'Harga yang anda masukan terlalu rendah',
                    type: 'warning',
                    confirmButtonClass: 'btn btn-primary',
                    buttonsStyling: false,
                })
                .then(() => {                    
                    app.$refs.harga.$el.focus()
                })
            }

            if(this.qty <= 0 ) {
                swalInit.fire({
                    title: 'Qty Belum diisi',
                    text: 'Qty Minimum 1',
                    type: 'error',
                    confirmButtonClass: 'btn btn-primary',
                    buttonsStyling: false,
                })
            }

            if(this.qty > 0 && this.new_harga >= this.barang.harga) {
                this.status = false
                this.fadeIn = true
                let i = this.details.length
                this.details.push({
                    index: i+1,
                    barang_id: this.barang.barang_id,
                    kode_barang: this.barang.kode_barang, 
                    nama_barang: this.barang.nama_barang, 
                    harga: this.new_harga,
                    harga_beli: this.barang.harga_beli,
                    harga_net: this.barang.harga,
                    qty: this.qty,
                    total: parseInt(this.new_harga * this.qty)
                })
                this.$nextTick(() => {
                    this.barang = {
                        id: '',
                        kode_barang: '',
                        nama_barang: '',
                        harga: '',
                        harga_beli: '',
                        harga_jual:''
                    }
                    this.sku = ''
                    this.new_harga =''
                    this.qty = ''
                    this.$refs.sku.focus()
                })
            }
        },
        onDelete (index) {
            let i = this.details.map(item => item.index).indexOf(index)
            this.details.splice(i, 1)
        },
        onProses () {
            if(!this.form_new_selling.dropship_id) {
                this.error_new_selling.dropship_id = '*Dropshipper belum dipilih'
                $("html, body").animate({ scrollTop: "0" })
            }
            if(!this.form_new_selling.customer_id) {
                this.error_new_selling.customer_id = '*Pembeli belum dipilih'
                $("html, body").animate({ scrollTop: "0" })
            }
            if(!this.form_new_selling.courier_id) {
                this.error_new_selling.courier_id = '*Kurir Belum dipilih'
                $("html, body").animate({ scrollTop: "0" })
            }
            if(!this.form_new_selling.service_id) {
                this.error_new_selling.service_id = '*Layanan belum dipilih'
                $("html, body").animate({ scrollTop: "0" })
            }
            if(!this.form_new_selling.booking_code) {
                this.error_new_selling.booking_code ='*Booking kode belum disisi'
                $("html, body").animate({ scrollTop: "0" })
            }
            if(!this.form_new_selling.shipping_cost) {
                this.error_new_selling.shipping_cost = '*Ongkir belum diisi'
                $("html, body").animate({ scrollTop: "0" })
            }
            if(this.form_new_selling.customer_id && this.form_new_selling.courier_id && this.form_new_selling.service_id && this.form_new_selling.booking_code && this.form_new_selling.shipping_cost) {
                $('#bank').modal('show')
            }
        },
        onBayar () {
            if(!this.form_new_selling.bank) {
                this.error_new_selling.bank = 'Bank belum dipilih'
            }
            if(this.form_new_selling.bank) {
                this.postSelling()
            }
        },
        postSelling() {
            this.isPost = true
            axios.post('/online/create/dropship',{
                dropship_id: this.form_new_selling.dropship_id,
                customer_id: this.form_new_selling.customer_id,
                courier_id: this.form_new_selling.courier_id,
                service_id: this.form_new_selling.service_id,
                booking_code: this.form_new_selling.booking_code,
                shipping_cost: this.form_new_selling.shipping_cost,
                bank: this.form_new_selling.bank,
                market_id: this.customer.marketplace_id,
                total: this.total,
                details: this.details,
            })
            .then(() => {
                $('#bank').modal('hide')
                this.status = true
                this.isPost = false
                this.$router.push('/online')
            })
        },
        onBatal () {
            this.status = true
            this.$router.push('/online')
        },
        onTabCustomerName () {
            $('#marketplace').select2('open')
        },
        onTabCustomer () {
            $('#marketplaces').select2('open')
        },
        onSubmitNewCustomer () {
            if(!this.form_new_customer.name){
                this.error_new_customer.name = 'This name cannot be empty'
            }
            if(!this.form_new_customer.marketplace_id) {
                this.error_new_customer.marketplace_id = 'This marketplace cannot be empty'
                $('#marketplace').select2({
                    width: '100%',
                    placeholder: 'Select Marketplace',
                })
            }
            if(!this.form_new_customer.phone) {
                this.error_new_customer.phone = 'This phone cannot be empty'
            }
            if(!this.form_new_customer.zip) {
                this.error_new_customer.zip = "this zip cannot be empty"
            }
            if(!this.form_new_customer.address) {
                this.error_new_customer.address = 'This Address cannot be empty'
            }
            if(!this.form_new_customer.district_id) {
                this.error_new_customer.district_id = 'This Dsitrict cannot be empty'
            }
            if(this.form_new_customer.name && this.form_new_customer.marketplace_id && this.form_new_customer.phone && this.form_new_customer.zip && this.form_new_customer.address && this.form_new_customer.district_id) {
                this.postNewCustomer()
            }
        },
        postNewCustomer () {
            this.isLoading = true
            axios.post('/online/new_customer',this.form_new_customer)
            .then((res) => {
                $('#new_customer').modal('hide')
                this.isLoading = false
                this.customer = res.data
                this.form_new_selling.customer_id = res.data.id
            })
        },
        onSubmitNewDsropship () {
            if(!this.form_new_dropship.name){
                this.error_new_dropship.name ='The'
            }
            if(!this.form_new_dropship.phone){
                this.error_new_dropship.phone = 'The'
            }
            if(!this.form_new_customer.name){
                this.error_new_customer.name = 'This name cannot be empty'
            }
            if(!this.form_new_customer.marketplace_id) {
                this.error_new_customer.marketplace_id = 'This marketplace cannot be empty'
                $('#marketplaces').select2({
                    width: '100%',
                    placeholder: 'Select Marketplace',
                })
            }
            if(!this.form_new_customer.phone) {
                this.error_new_customer.phone = 'This phone cannot be empty'
            }
            if(!this.form_new_customer.zip) {
                this.error_new_customer.zip = "this zip cannot be empty"
            }
            if(!this.form_new_customer.address) {
                this.error_new_customer.address = 'This Address cannot be empty'
            }
            if(!this.form_new_customer.district_id) {
                this.error_new_customer.district_id = 'This Dsitrict cannot be empty'
            }
            if(this.form_new_customer.name && this.form_new_customer.marketplace_id && this.form_new_customer.phone && this.form_new_customer.zip && this.form_new_customer.address && this.form_new_customer.district_id) {
                this.postNewDropship()
            }
        },
        postNewDropship () {
            this.isLoading = true
            axios.post('/online/new_dropship',{
                name: this.form_new_customer.name,
                phone: this.form_new_customer.phone,
                address: this.form_new_customer.address,
                province_id: this.form_new_customer.province_id,
                regency_id: this.form_new_customer.regency_id,
                district_id: this.form_new_customer.district_id,
                zip: this.form_new_customer.zip,
                marketplace_id: this.form_new_customer.marketplace_id,
                dropshipper_name: this.form_new_dropship.name,
                dropshipper_phone: this.form_new_dropship.phone 
            })
            .then((response) => {
                $('#new_dropship').modal('hide')
                this.isLoading = false
                this.form_new_selling.customer_id = response.data.customer.id
                this.form_new_selling.dropship_id = response.data.dropship.id
                this.customer = response.data.customer
                this.dropship = response.data.dropship
            })
        },
    },
    beforeRouteLeave(to, from, next) {
        if(this.status) {
            next()
        } else {
            swalInit.fire({
                title: 'DATA BELUM DI SIMPAN',
                text: 'Apakah anda ingin menyipan data ini ?',
                type:'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya Simpan!',
                cancelButtonText: 'Tidak',
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                buttonsStyling: false,
            })
            .then((result)=>{
                if(result.value) {
                    this.onProses()
                } else {
                    this.status = true
                    this.$router.push('/online')
                }
            })
        }
    } 
}
</script>